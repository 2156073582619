import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import { showLoadingSwal } from "../../../components/loadingSwal";
import { startBulkFetchInvoices } from "../../../api/advisor/getAdvisorSyncList";
import withReactContent from "sweetalert2-react-content";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TextField } from "@mui/material";
// Yükleniyor Swal bileşeni
const MySwal = withReactContent(Swal);
export const StartBulkFetchInvoices = ({
  open,
  handleClose,
  setUpdateRow,
}) => {
  const startDateRef=useRef(moment().startOf("month"))
  const endDateRef=useRef(moment())

  useEffect(() => {
   
    if (open) {
      MySwal.fire({
        title: "Tarih Aralığı Seçin",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Devam",
        cancelButtonText: "İptal",
        focusConfirm: false,
        html: (
          <div style={{display:"flex"}}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="tr">
           
            <DatePicker
            label="Başlangıç Tarihi"
              value={moment(startDateRef.current)}
              onChange={(newValue) => {
                startDateRef.current = moment(newValue);
            }}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
            label="Bitiş Tarihi"
              value={moment(endDateRef.current)}
              onChange={(newValue) => {
                endDateRef.current = moment(newValue);
            }}
            sx={{marginLeft:2}}
              renderInput={(params) => <TextField sx={{color:"black"}} {...params} />}
            />
           
          </LocalizationProvider>
          </div>
        
        ),
      }).then((result) => {
        if (result.isConfirmed) {      let startDateFormat = moment(startDateRef.current).format("DD.MM.YYYY");
      let endDateFormat = moment(endDateRef.current).format("DD.MM.YYYY");
      Swal.fire({
        html: `"Faturaları Otomatik Getir" seçeneği aktif olan mükelleflerin <b>${startDateFormat} - ${endDateFormat}</b> tarih aralığındaki faturaları çekilecek.
Devam etmek istiyor musunuz?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Evet, senkronize et!",
        cancelButtonText: "Hayır",
      }).then(async (result) => {
        if (result.isConfirmed) {
          showLoadingSwal();
          startBulkFetchInvoices(startDateRef.current, endDateRef.current)
            .then((resp) => {
              setUpdateRow(true);
              Swal.close();
              Swal.fire({
                html: resp.data.message,
                icon: "success",
                confirmButtonText: "Tamam",
              });
            })
            .catch((err) => {
              Swal.close();
              Swal.fire({
                title: "Bir hata oluştu!",
                text: err.response.data.message,
                icon: "error",
                confirmButtonText: "Tamam",
              });
            });
        }
      }); }
      });



      handleClose();
    }
  }, [open, handleClose,setUpdateRow]);

  return null;
};
