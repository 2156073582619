import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";

import { register } from "../../api/auth/register/index";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import registerImage from "../../assets/images/register.png";
import Swal from "sweetalert2";
import { emailFormatControl } from "../../utils/emailFormatControl";
const Register = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordControl, setPasswordControl] = useState("");
  const [phone, setPhone] = useState("");
  const [vkn, setVkn] = useState("");
  const [accountingProgram, setAccountingProgram] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();
  const navigate = useNavigate();


  
  const [helperEmailText, setHelperEmailText] = useState("");
  const [helperPhoneText, setHelperPhoneText] = useState("");
  const [helperVknText, setHelperVknText] = useState("");
  const [helperPasswordText, setHelperPasswordText] = useState("");
  const [helperText, setHelperText] = useState("");
  const [error, setError] = useState(false);

  const registerButtonRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        registerButtonRef.current.click();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleRegister = (event) => {
    if (
      email.trim() === "" ||
      password.trim() === "" ||
      name.trim() === "" ||
      passwordControl.trim() === "" ||
      phone.trim() === "" ||
      vkn.trim() === ""
    ) {
      setError(true);
      setHelperText("Bu alan boş bırakılamaz");
    } else if (!emailFormatControl(email)) {
      setError(true);
      setHelperEmailText("Geçersiz email adresi");
    } else if (phone.length !== 10 || phone[0] === "0") {
      setError(true);
      setHelperPhoneText("Telefon numarasını doğru giriniz");
    } else if (vkn.length !== 10) {
      setError(true);
      setHelperVknText("Vergi kimlik numarasını doğru giriniz.");
    } else if (password !== passwordControl) {
      setError(true);
      setHelperPasswordText("Şifreler uyuşmuyor");
    } else {
      setError(false);
      register(email, password, phone, vkn, name, accountingProgram)
        .then((resp) => {
          Swal.fire({
            text: resp.data.message,
            icon: "success",
            confirmButtonText: "Tamam",
            customClass: {
              confirmButton: "custom-button",
            },
          }).then((resp) => {navigate('/login');})
        })
        .catch((err) => {
          Swal.fire({ 
            title: err.response.data.message,
            icon: "error",
            confirmButtonText: "Tamam",
            customClass: {
              confirmButton: "custom-button",
            },
          });
        });
    }
  };

  return (
    <Grid
      container
      sx={{
        height: "100vh",
        alignItems: "center",
        justifyContent:  {
          xs: 'center',
          sm: 'space-between', 
        },
      }}
      md={12}
    >
      <Grid
        item
        xs={0}
        sm={8.5}
        sx={{display: {
          xs: 'none',
          sm: 'flex', 
        }, justifyContent: "center" }}
      >
        <Grid
          component="img"
          sx={{
            width: "100vh", // Genişliği %100 yaparak gridin tamamını kaplar
            height: "100vh",
            position: "fixed",
            top: "0%", // Yüksekliği otomatik yaparak orijinal oranları korur
          }}
          alt="Register"
          src={registerImage} // public klasöründen çağırma
        />
      </Grid>

      <Grid
        item
        xs={11}
        sm={3.3}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          alignItems: "center",
          justifyContent: "center",
          marginTop: "24px",
          marginBottom: "80px",
          backgroundColor: "#ffffff",
          padding: "8px",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          borderRadius: "4px",
          marginRight:{
            xs: '0px',
            sm: '10px', 
          },
        }}
      >
        {/* Sağ taraftaki giriş formu */}

        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            pt: 2,
            pb: 2,
            width: "100%",
           
          }}
        >
          <Typography color={"gray"}>
            Sisteme kaydolduğunuzda, mükelleflerinizin bilanço ve işletme
            defterine uygun olarak tüm gelen ve giden faturaları entegratör
            bağımsız bir şekilde otomatik olarak aktarabilirsiniz. Excel
            formatındaki banka ekstrelerini belirli kurallar tanımlayarak
            aktarılır.
          </Typography>
          <Typography variant="h4" color={"gray"}>
            Kayıt Ol
          </Typography>
          <Typography color={"gray"}>
            Lütfen gerekli bilgileri doğru ve eksiksiz doldurunuz.
          </Typography>
        </Grid>
        <Grid item md={12} sx={{ width: "100%" }}>
          <TextField
            className="input-field"
            fullWidth
            placeholder="Ad Soyad"
            label="Ad Soyad"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={name}
            error={error && name.length === 0}
            helperText={error && name.length === 0 ? `${helperText}` : ""}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Grid>
        <Grid item md={12} sx={{ width: "100%" }}>
          <TextField
            className="input-field"
            fullWidth
            placeholder="E-Posta adresi"
            label="E-Posta"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setHelperEmailText("");
            }}
            error={
              (error && email.length === 0) || helperEmailText.length !== 0
            }
            helperText={
              error && email.length === 0
                ? `${helperText}`
                : error && email.length !== 0
                ? `${helperEmailText}`
                : ""
            }
          />
        </Grid>
        <Grid item md={12} sx={{ width: "100%" }}>
          <TextField
            className="input-field"
            fullWidth
            placeholder="(5##) ### ## ##"
            label="Cep Telefon Numarası"
            inputProps={{ maxLength: 10 }}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              setHelperPhoneText("");
            }}
            error={
              (error && phone.length === 0) || helperPhoneText.length !== 0
            }
            helperText={
              error && phone.length === 0
                ? `${helperText}`
                : error && email.length !== 0
                ? `${helperPhoneText}`
                : ""
            }
          />
        </Grid>
        <Grid item md={12} sx={{ width: "100%", pb: 1 }}>
          <TextField
            className="input-field"
            fullWidth
            placeholder="Vergi Kimlik Numarası"
            label="Vergi Kimlik Numarası(TC NO DEĞİL!!!)"
            inputProps={{ maxLength: 10 }}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={vkn}
            onChange={(e) => {
              setVkn(e.target.value);
              setHelperVknText("");
            }}
            error={(error && vkn.length === 0) || helperVknText.length !== 0}
            helperText={
              error && vkn.length === 0
                ? `${helperText}`
                : error && vkn.length !== 0
                ? `${helperVknText}`
                : ""
            }
          />
        </Grid>
        <Grid item md={12} sx={{ width: "100%" }}>
          <TextField
            className="input-field"
            fullWidth
            label="Kullandığınız Genel Muhasebe Program Adı"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={accountingProgram}
            onChange={(e) => {
              setAccountingProgram(e.target.value);
            }}
            error={error && accountingProgram.length === 0}
            helperText={
              error && accountingProgram.length === 0 ? `${helperText}` : ""
            }
          />
        </Grid>

        <Grid item md={12} sx={{ width: "100%" }}>
          <TextField
            fullWidth
            className="input-field" // CSS class'ını ekleyin
            label="Şifre"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setHelperPasswordText("");
            }}
            error={
              (error && password.length === 0) ||
              helperPasswordText.length !== 0
            }
            helperText={
              error && password.length === 0
                ? `${helperText}`
                : error && password.length !== 0
                ? `${helperPasswordText}`
                : ""
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="small"
                  >
                    {showPassword ? (
                      <Visibility
                        fontSize="inherit"
                        style={{ fontSize: "1rem" }}
                      />
                    ) : (
                      <VisibilityOff
                        fontSize="inherit"
                        style={{ fontSize: "1rem" }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item md={12} sx={{ width: "100%" }}>
          <TextField
            fullWidth
            className="input-field" // CSS class'ını ekleyin
            label="Şifreyi Tekrar Giriniz"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            type={showPassword ? "text" : "password"}
            value={passwordControl}
            onChange={(e) => {
              setPasswordControl(e.target.value);
              setHelperPasswordText("");
            }}
            error={
              (error && passwordControl.length === 0) ||
              helperPasswordText.length !== 0
            }
            helperText={
              error && passwordControl.length === 0
                ? `${helperText}`
                : error && passwordControl.length !== 0
                ? `${helperPasswordText}`
                : ""
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="small"
                  >
                    {showPassword ? (
                      <Visibility
                        fontSize="inherit"
                        style={{ fontSize: "1rem" }}
                      />
                    ) : (
                      <VisibilityOff
                        fontSize="inherit"
                        style={{ fontSize: "1rem" }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item md={12} sx={{ width: "100%" }}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            type="submit"
            ref={registerButtonRef}
            className="custom-button"
            onClick={handleRegister}
          >
            Kayıt Ol
          </Button>
        </Grid>
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography variant="body2">Zaten bir hesabınız var mı? </Typography>

          <Button
            variant="body2"
            onClick={() => {
              navigate("/login");
            }}
            className="unframed-button "
            sx={{ color: "#123675", textDecoration: "none" }}
          >
            Giriş yap
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Register;
