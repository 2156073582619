/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

//todo MODAL AÇILMASI GEREKTİREN İŞLEMLER SONU MODAL İLE BİTEN DOSYALARDA(fatura seçilerek yapılan işlemlerde),
//todo DİREKT İSTEKLE HALLEDİLEBİLİR İŞLEMLER 'ProcessesAndProcessMenu' DOSYASINDADIR.

import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

//Components
import Sidebar from "../../../components/sideBar/sideBar.js";
import Navbar from "../../../components/navbar/navbar.js";
import localStorage from "local-storage";
import { getJsonItem } from "../../../utils/localStorageProcess/index.js";
import { setJsonItem } from "../../../utils/localStorageProcess/index.js";
//Icons
import { IoReturnDownForwardOutline } from "react-icons/io5";
import { FaMinus, FaPlus, FaRegTrashCan } from "react-icons/fa6";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
  Divider,
  IconButton,
} from "@mui/material";
//Icons

//Apis
import { getCompanyDetails } from "../../../api/advisor/getCompanyDetails/index.js";
import { FaRegSave, FaRegTrashAlt, FaTrashAlt } from "react-icons/fa";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { RiDragMove2Fill } from "react-icons/ri";
import {
  createRuleStringForBackend,
  parseRuleConditionStringForFrontend,
} from "../../../utils/bankExtractRuleUtils/index.js";
import { saveSettingDetails } from "../../../api/company/invoiceSettings/saveSettingDetails/index.js";
import { getLucaAccounts } from "../../../api/company/invoices/getLucaAccounts/index.js";
import { AddSpecialRule } from "../../../components/addSpecialRule/index.js";
import { showLoadingSwal } from "../../../components/loadingSwal/index.js";
import { getBankExtractDetails } from "../../../api/advisor/getBankExtractDetails/index.js";
import { saveBankExtractSettings } from "../../../api/company/invoiceSettings/saveBankExtractSettings/index.js";
import { setTitle } from "../../../utils/setTitle/index.js";
import { CreateLucaAccountSwal } from "../../../components/createLucaAccountSwal/index.js";
//Apis
const bankList = [
  "Tüm Bankalar",
  "Kuveyt Türk",
  "Ziraat",
  "İş Bankası",
  "Garanti",
  "Yapı Kredi",
  "Halkbank",
  "QNB Finansbank",
  "Vakıfbank",
  "Denizbank",
  "Akbank",
  "TEB",
  "ING",
  "Albaraka Türk",
  "En Para",
  "Ziraat Katılım",
  "Vakıf Katılım",
];
export function BankExtractsSettingsPage() {
  const [rules, setRules] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const directionOptions = [
    { value: "2", label: "Gelen" },
    { value: "1", label: "Giden" },
  ];

  const fieldOptions = [
    { value: "amount", label: "Tutar" },
    { value: "description", label: "Açıklama", selected: true },
    { value: "description.List", label: "Açıklama (Liste)" },
    { value: "date", label: "Tarih" },
  ];

  const processOptions = [
    { value: "includes", label: "İçeriyorsa" },
    { value: "notIncludes", label: "İçermiyorsa" },
    { value: "==", label: "Eşitse" },
    { value: "!=", label: "Eşit değilse" },
    { value: "startsWith", label: "Başlıyorsa" },
    { value: "endsWith", label: "Bitiyorsa" },
    { value: ">", label: "Büyükse" },
    { value: "<", label: "Küçükse" },
    { value: "<=", label: "Küçük veya Eşitse" },
    { value: ">=", label: "Büyük veya Eşitse" },
  ];
  const bankOptions = [
    { value: "Tüm Bankalar", label: "Tüm Bankalar" },
    { value: "Kuveyt Türk", label: "Kuveyt Türk" },
    { value: "Ziraat", label: "Ziraat" },
    { value: "İş Bankası", label: "İş Bankası" },
    { value: "Garanti", label: "Garanti" },
    { value: "Yapı Kredi", label: "Yapı Kredi" },
    { value: "Halkbank", label: "Halkbank" },
    { value: "QNB Finansbank", label: "QNB Finansbank" },
    { value: "Vakıfbank", label: "Vakıfbank" },
    { value: "Denizbank", label: "Denizbank" },
    { value: "Akbank", label: "Akbank" },
    { value: "TEB", label: "TEB" },
    { value: "ING", label: "ING" },
    { value: "Albaraka Türk", label: "Albaraka Türk" },
    { value: "En Para", label: "En Para" },
    { value: "Ziraat Katılım", label: "Ziraat Katılım" },
    { value: "Vakıf Katılım", label: "Vakıf Katılım" },
  ];
  const handleAddRule = (process) => {
    if (process === "end") {
      setRules([
        ...rules,
        {
          index: rules.length,
          bankName: bankOptions[0].value,
          accountCode: lucaAccountCodesRef.current[0].hesap_kodu,
          direction: directionOptions[1].value,
          field: fieldOptions[1].value,
          process: processOptions[0].value,
          input: "",
          subRules: [],
        },
      ]);
    } else {
      setRules([
        {
          index: rules.length,
          bankName: bankOptions[0].value,
          accountCode: lucaAccountCodesRef.current[0].hesap_kodu,
          direction: directionOptions[1].value,
          field: fieldOptions[1].value,
          process: processOptions[0].value,
          input: "",
          subRules: [],
        },
        ...rules,
      ]);
    }
  };

  const handleAddSubRule = (ruleIndex) => {
    const newRules = rules.map((rule) => {
      if (rule.index === ruleIndex) {
        return {
          ...rule,
          subRules: [
            ...rule.subRules,
            {
              index: ruleIndex,
              subIndex: rule.subRules.length,
              field: fieldOptions[1].value,
              process: processOptions[0].value,
              input: "",
            },
          ],
        };
      }
      return rule;
    });
    setRules(newRules);
  };

  const handleRemoveRule = (index, subIndex = null, returnIndex = null) => {
    if (subIndex === null) {
      // Ana kuralı silme
      const newRules = rules.filter((rule) => rule.index !== index);
      setRules(newRules);
    } else {
      // Alt kuralı silme
      const newRules = rules.map((rule) => {
        if (rule.index === index) {
          return {
            ...rule,
            subRules: rule.subRules.filter(
              (subRule) => subRule.subIndex !== subIndex
            ),
          };
        }
        return rule;
      });
      setRules(newRules);
    }
  };

  const handleRuleChange = (
    index,
    field,
    value,
    subIndex = null,
    subField = null,
    subValue = null
  ) => {
    const newRules = rules.map((rule) => {
      if (rule.index === index) {
        if (subIndex !== null && subField !== null) {
          if (typeof subField === "object") {
            const updatedSubRules = rule.subRules.map((subRule) => {
              if (subRule.subIndex === subIndex) {
                return { ...subRule, ...subField };
              }
              return subRule;
            });
            return { ...rule, subRules: updatedSubRules };
          } else {
            // `subField` bir nesne değilse
            const updatedSubRules = rule.subRules.map((subRule) => {
              if (subRule.subIndex === subIndex) {
                return { ...subRule, [subField]: subValue };
              }
              return subRule;
            });
            return { ...rule, subRules: updatedSubRules };
          }
        } else if (typeof field === "object") {
          return { ...rule, ...field };
        } else {
          return { ...rule, [field]: value };
        }
      }
      return rule;
    });
    setRules(newRules);
  };

  const handleRuleReorder = (result) => {
    if (!result.destination) return;
    const newRules = Array.from(rules);
    const [reorderedItem] = newRules.splice(result.source.index, 1);
    newRules.splice(result.destination.index, 0, reorderedItem);
    setRules(newRules.map((rule, index) => ({ ...rule, index })));
  };

  const [isOpen, setIsOpen] = useState(false);

  const [companyDetails, setCompanyDetails] = useState();
  const companyDetailsRef = useRef(companyDetails);

  const [loading, setLoading] = useState(true);
  const [firstLoading, setFirstLoading] = useState(true); //SAYFA İLK AÇTIĞINDA getListInvoices ve companies'e İSTEK ATMAMASI İÇİN FLAG

  const location = useLocation();
  const navigate = useNavigate();

  const lucaAccountCodesRef = useRef();
  
  useEffect(() => {
    const sideBarOpen = localStorage.get("sidebar");
    if (sideBarOpen === "false") {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
    let locationSplit = location.pathname.split("/");

    let companyDetailsControl = getJsonItem(
      `#${locationSplit[2]}/${locationSplit[3]}`
    );
    setCompanyDetails(companyDetailsControl);
    companyDetailsRef.current = companyDetailsControl;
    setTitle(companyDetailsControl?.name,locationSplit[4])
    if (
      locationSplit[2] !== String(companyDetailsControl?.companyId) ||
      locationSplit[3] !== String(companyDetailsControl?.periodId)
    ) {
      getCompanyDetails(locationSplit[2], locationSplit[3])
        .then(async (resp) => {
          await setJsonItem(
            `#${locationSplit[2]}/${locationSplit[3]}`,
            resp.data
          );
        })
        .catch((err) => {
          navigate("/dashboard", { replace: true });
        });
    }

    Promise.all([
      getBankExtractDetails(locationSplit[2], locationSplit[3]),
      getLucaAccounts(locationSplit[2], locationSplit[3]),
    ])
      .then(([bankExtractResp, lucaAccountResp]) => {
        const updateData=lucaAccountResp.data.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.id === item.id)
        );
        lucaAccountCodesRef.current = updateData

        let ruleList = [];
        let subRuleList = [];
        ruleList = [];
        bankExtractResp.data.settings.bankExtractRules.map((rule, index) => {
          const direction = rule.condition
            .substring(
              rule.condition.indexOf("value.direction ==") + 18,
              rule.condition.indexOf("&&")
            )
            .trim();
          let bankName = rule.condition
            .substring(
              rule.condition.indexOf("&& bankName") + 14,
              rule.condition.indexOf(
                "&&",
                rule.condition.indexOf("&& bankName") + 14 + 1
              )
            )
            .trim()
            .replace(/"/g, "")
            .trim();
          if (!bankList.includes(bankName)) {
            bankName = rule.condition.includes("Tüm Bankalar")
              ? "Tüm Bankalar"
              : undefined;
          }
          const { field, process, input } = parseRuleConditionStringForFrontend(
            rule.condition.split("&&")[2]
          );
          let accountCode = rule.return;
          subRuleList = rule.condition
            .split("&&")
            .slice(3)
            .map((subRule, subIndex) => {
              const { field, process, input } =
                parseRuleConditionStringForFrontend(subRule);
              return {
                index,
                subIndex,
                field,
                process,
                input,
              };
            });

          ruleList.push({
            index,
            bankName,
            direction,
            field,
            process,
            input,
            accountCode,
            subRules: subRuleList,
          });
        });
        setRules(ruleList);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleSaveButtonClick = async () => {
    let hasEmptyFields = false;

    console.log(rules,'ASdasd')
    let controlledRules = rules.map((rule) => {
      if (
        !rule.direction ||
        !rule.field ||
        !rule.bankName ||
        !rule.process ||
        !rule.input ||
        !rule.accountCode
      ) {
        hasEmptyFields = true;
      }
      for (const subRule of rule.subRules) {
        if (!subRule.field || !subRule.process || !subRule.input) {
          hasEmptyFields = true;
        }
      }
    });
    if (hasEmptyFields) {
      setRules(controlledRules);
      setIsEmpty(true);
      Swal.fire({
        title: "Hata",
        text: "Lütfen boş alanları doldurun.",
        icon: "error",
        confirmButtonText: "Tamam",
      });
    } else {
      Swal.fire({
        title: "Uyarı",
        html: '<p>Ayarları kaydetmek istediğinizden emin misiniz?<br/><b style="color:red"> Bu işlem geri alınamaz.</b></p>',
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "Vazgeç",
        confirmButtonText: "Evet, Kaydet",
        focusDeny: true,
      }).then(async(result) => {
        if (result.isConfirmed) {
          showLoadingSwal("Ayarlar kaydediliyor...");
          let invoiceRules = [];

          for await (const item of rules) {
            invoiceRules.push(
              await createRuleStringForBackend(
                item.direction,
                item.bankName,
                item.process,
                item.field,
                item.input,
                item.accountCode,
                item.subRules
              )
            );

          }

          let settings = {
            bankExtractRules: invoiceRules ? invoiceRules : [],
          };

          saveBankExtractSettings(
            companyDetails?.companyId,
            companyDetails?.periodId,
            settings
          )
            .then((resp) => {
              Swal.close();
              Swal.fire({
                icon: "success",
                title: "Ayarlar kaydedildi!",
                confirmButtonText: "Tamam",
              });
              getCompanyDetails(
                companyDetails?.companyId,
                companyDetails?.periodId
              ).then(async (resp) => {
                await setJsonItem(
                  `#${companyDetails?.companyId}/${companyDetails?.periodId}`,
                  resp.data
                );
              });
            })
            .catch((err) => {
              Swal.close();
              Swal.fire({
                icon: "error",
                title: "Ayarlar kaydedilemedi!",
                text: err.response.data.message
                  ? err.response.data.message
                  : "",
              });
            });
          setIsEmpty(false);
        }
      });
    }
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Grid container sx={{ justifyContent: "space-between" }}>
      <Grid
        item
        sx={{
          flexBasis: isOpen ? "275px" : "95px",
          flexShrink: 0,
          transition: "flex-basis 0.3s ease",
        }}
        zIndex={1}
      >
        <Sidebar
          status={isOpen}
          toggleSidebar={toggleSidebar}
          companyClass={companyDetails?.class}
        />
      </Grid>
      <Grid
        item
        zIndex={0}
        className="invoice-settings-specific-styles"
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          gap: 1,
          pr: "12px",
        }}
      >
        {loading && (
          <div
            style={{
              display: "flex",
              position: "absolute",
              top: "50%",
              left: "50%",
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              transform: "translate(-50%, -50%)",
              backgroundColor: "rgba(255, 255, 255, 0.8)", // Hafif beyaz arka plan
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              zIndex: 1000, // Yüksek z-index ile üstte görünsün
            }}
          >
            <p style={{ margin: 0 }}>Yükleniyor...</p>
          </div>
        )}
        <Grid item xs={12}>
          <Navbar
            setLoading={setLoading}
            setFirstLoading={setFirstLoading}
            firstLoading={firstLoading}
          />{" "}
        </Grid>
        <Grid container md={12} className="grid-area">
          <Grid
            item
            sx={{
              mb: 5,
              display: "flex",
              justifyContent: "space-between",
            }}
            xs={12}
          >
            <Grid item xs={2}>
              <Typography variant="h6" color={"#030e22b2"} fontWeight={"550"}>
                Banka Ekstre Ayarları
              </Typography>
            </Grid>
            <Grid item xs={2} md={1}>
              {" "}
              <Button
                onClick={() => {
                  handleSaveButtonClick();
                }}
                fullWidth
                sx={{ width: "100%",textTransform: "none" }}
                variant="contained"
                color="primary"
                className={loading ? "unframed-button" : "custom-button"}
                disabled={loading}
                startIcon={<FaRegSave size={15} color="white" />}
              >
                Kaydet
              </Button>
            </Grid>
          </Grid>
          <Grid container xs={12} sx={{ mt: 1, gap: 2 }}>
            <Grid item xs={12}>
              <Divider />
              <Typography
                variant="h6"
                color={"#030e22b2"}
                fontWeight={"450"}
                sx={{ ml: 2, mt: 1, mb: 1, mr: 2 }}
              >
                Banka Ekstre Kuralları
              </Typography>
              <Divider variant="middle" />
            </Grid>
            <Grid item xs={12} sx={{ pl: 2, pt: 1, pb: 1, pr: 2 }}>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  startIcon={<FaPlus size={14} />}
                  variant="outlined"
                  onClick={() => handleAddRule()}
                  sx={{
                    textTransform: "none",
                    color: "#35ca78", // Metin rengi
                    borderColor: "#35ca78", // Kenarlık rengi
                    backgroundColor: loading ? undefined : "#ddf6e8",
                    "&:hover": {
                      backgroundColor: "#a5fcb4a4",
                      borderColor: "#35ca78",
                    },
                  }}
                  disabled={loading}
                >
                  Kural Ekle
                </Button>
              </Grid>
              <DragDropContext onDragEnd={handleRuleReorder}>
                <Droppable droppableId="rules">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {rules.map((rule, index) => (
                        <Draggable
                          key={rule?.index}
                          draggableId={`rule-${rule?.index}`}
                          index={index}
                        >
                          {(provided) => (
                            <Grid
                              container
                              alignItems="center"
                              spacing={2}
                              sx={{
                                mt: 2,
                                alignItems: "flex-end",
                                marginTop: "auto",
                              }}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              {/* Diğer grid öğeleri buraya */}
                              <Grid
                                container
                                sx={{
                                  width: "100%",
                                  display: index < 1 ? "none" : "",
                                  justifyContent: "center",
                                  mt: index < 1 ? 0 : 1,
                                }}
                              >
                                <Divider
                                  variant="middle"
                                  sx={{
                                    width: "100%",
                                  }}
                                />
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                sm={0.4}
                                {...provided.dragHandleProps}
                                sx={{
                                  marginBottom: "5px",
                                }}
                              >
                                <IconButton>
                                  <RiDragMove2Fill size={17} />
                                </IconButton>
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  sx={{
                                    mb: 0,
                                  }}
                                >
                                  Banka Seçiniz
                                </Typography>
                                <Autocomplete
                                  fullWidth
                                  disableClearable
                                  size="small"
                                  clearIcon={null}
                                  value={bankOptions.find(
                                    (option) => option.value === rule.bankName
                                  )}
                                  onChange={(event, newValue) => {
                                    handleRuleChange(
                                      rule.index,
                                      "bankName",
                                      newValue.value
                                    );
                                  }}
                                  options={bankOptions}
                                  renderInput={(params) => (
                                    <TextField
                                      size="small"
                                      sx={{
                                        backgroundColor:
                                          isEmpty &&
                                          (rule.bankName === "" ||
                                            rule.bankName === null ||
                                            rule.bankName === undefined)
                                            ? "#ff000031"
                                            : undefined,
                                      }}
                                      {...params}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={1.3}>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  sx={{
                                    mb: 0,
                                  }}
                                >
                                  Yön Seçiniz
                                </Typography>
                                <Autocomplete
                                  fullWidth
                                  disableClearable
                                  size="small"
                                  clearIcon={null}
                                  value={directionOptions.find(
                                    (option) => option.value === rule?.direction
                                  )}
                                  onChange={(event, newValue) => {
                                    handleRuleChange(
                                      rule.index,
                                      "direction",
                                      newValue.value
                                    );
                                  }}
                                  options={directionOptions}
                                  renderInput={(params) => (
                                    <TextField
                                      size="small"
                                      sx={{
                                        backgroundColor:
                                          isEmpty &&
                                          (rule.direction === "" ||
                                            rule.direction === null ||
                                            rule.direction === undefined)
                                            ? "#ff000031"
                                            : undefined,
                                      }}
                                      {...params}
                                    />
                                  )}
                                />
                              </Grid>

                              <Grid item xs={12} sm={2}>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  sx={{
                                    mb: 0,
                                  }}
                                >
                                  Alan Seçiniz
                                </Typography>
                                <Autocomplete
                                  fullWidth
                                  disableClearable
                                  size="small"
                                  clearIcon={null}
                                  value={fieldOptions.find(
                                    (option) => option.value === rule.field
                                  )}
                                  onChange={(event, newValue) => {
                                    handleRuleChange(rule.index, {
                                      field: newValue?.value,
                                      input: "",
                                      process:
                                        newValue?.value === "date" ||
                                        newValue?.value === "amount"
                                          ? "=="
                                          : "includes",
                                    });
                                  }}
                                  options={fieldOptions}
                                  renderInput={(params) => (
                                    <TextField
                                      size="small"
                                      sx={{
                                        backgroundColor:
                                          isEmpty &&
                                          (rule.field === "" ||
                                            rule.field === null ||
                                            rule.field === undefined)
                                            ? "#ff000031"
                                            : undefined,
                                      }}
                                      {...params}
                                    />
                                  )}
                                />
                              </Grid>

                              <Grid item xs={12} sm={2}>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  sx={{
                                    mb: 0,
                                  }}
                                >
                                  {"Metin / Değer Giriniz"}
                                </Typography>

                                <TextField
                                  fullWidth
                                  size="small"
                                  value={rule.input}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    handleRuleChange(
                                      rule.index,
                                      "input",
                                      value
                                    );
                                  }}
                                  sx={{
                                    backgroundColor:
                                      isEmpty &&
                                      (rule.input === "" ||
                                        rule.input === null ||
                                        rule.input === undefined)
                                        ? "#ff000031"
                                        : undefined,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={1.5}>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  sx={{
                                    mb: 0,
                                  }}
                                >
                                  İşlem Seçiniz
                                </Typography>
                                <Autocomplete
                                  disableClearable
                                  clearIcon={null}
                                  fullWidth
                                  size="small"
                                  value={processOptions.find(
                                    (option) => option.value === rule.process
                                  )}
                                  onChange={(event, newValue) =>
                                    handleRuleChange(
                                      rule.index,
                                      "process",
                                      newValue?.value
                                    )
                                  }
                                  options={processOptions.filter((option) => {
                                    if (
                                      rule.field === "amount" ||
                                      rule.field === "date"
                                    ) {
                                      return (
                                        option.value !== "endsWith" &&
                                        option.value !== "startsWith" &&
                                        option.value !== "notIncludes" &&
                                        option.value !== "includes"
                                      );
                                    } else {
                                      return (
                                        option.value !== ">" &&
                                        option.value !== "<" &&
                                        option.value !== ">=" &&
                                        option.value !== "<="
                                      );
                                    }
                                  })}
                                  renderInput={(params) => (
                                    <TextField
                                      size="small"
                                      sx={{
                                        backgroundColor:
                                          isEmpty &&
                                          (rule.process === "" ||
                                            rule.process === null ||
                                            rule.process === undefined)
                                            ? "#ff000031"
                                            : undefined,
                                      }}
                                      {...params}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  sx={{
                                    mb: 0,
                                  }}
                                >
                                  Hesap Kodu Seçiniz
                                </Typography>

                                <Autocomplete
                                  clearIcon={null}
                                  disableClearable
                                  fullWidth
                                  size="small"
                                  value={lucaAccountCodesRef.current.find(
                                    (option) =>
                                      option.hesap_kodu === rule.accountCode
                                  )}
                                  onChange={async (event, newValue) => {
                                    if (newValue?.value === "add-new") {
                                      CreateLucaAccountSwal(companyDetails?.companyId,companyDetails?.periodId)
                                    } else {
                                      handleRuleChange(
                                        rule.index,
                                        "accountCode",
                                        newValue?.hesap_kodu
                                      );
                                    }
                                    
                                  }}
                                  filterOptions={(options, params) => {
                                    const filtered = options.filter((option) => {
                                      let label = `${option.hesap_kodu} - ${option.hesap_adi}`;
                                      return label
                                        .toLocaleLowerCase("tr")
                                        .includes(params?.inputValue?.toLocaleLowerCase("tr"));
                                    });
                                    // Girilen metin, mevcut seçenekler arasında yoksa özel bir seçenek ekle
                                    if (filtered?.length === 0) {
                                      filtered.push({
                                        label: "Yeni Hesap Kodu Eklemek İçin Tıklayın",
                                        value: "add-new", // Özel seçenek için belirleyici bir value
                                      });
                                    }
                      
                                    return filtered;
                                  }}
                                  options={lucaAccountCodesRef.current}
                                  getOptionLabel={(option) => {
                                    return option?.label?option?.label: `${option.hesap_kodu} - ${option.hesap_adi}`;
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      size="small"
                                      sx={{
                                        backgroundColor:
                                          isEmpty &&
                                          (rule.accountCode === "" ||
                                            rule.accountCode === null ||
                                            rule.accountCode === undefined)
                                            ? "#ff000031"
                                            : undefined,
                                      }}
                                      {...params}
                                    />
                                  )}
                                />
                              </Grid>

                              {/* Silme ve sürükleme düğmeleri */}
                              <Grid
                                item
                                xs={12}
                                sm={0.3}
                                sx={{
                                  marginBottom: "5px",
                                }}
                              >
                                <IconButton
                                  onClick={() => handleRemoveRule(rule.index)}
                                  color="error"
                                >
                                  <FaRegTrashAlt size={15} />
                                </IconButton>
                              </Grid>

                              {/* Alt kurallar için "Ve" düğmesi */}

                              {/* Alt kuralları render et */}
                              {rule.subRules.map((subRule, subIndex) => (
                                <Grid
                                  container
                                  xs={12}
                                  key={`${rule.index}-${subRule.subIndex}`}
                                  sx={{
                                    pl: 10,
                                    mt: 3,
                                    gap: 2,
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <Grid
                                    item
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      pb: 1,
                                      height: "100%",
                                    }}
                                  >
                                    <IoReturnDownForwardOutline />
                                  </Grid>
                                  <Grid item xs={12} sm={1.9}>
                                    <Typography
                                      variant="body2"
                                      color={"#1e1f20"}
                                      gutterBottom
                                      sx={{
                                        mb: 0,
                                      }}
                                    >
                                      Alan Seçiniz
                                    </Typography>
                                    <Autocomplete
                                      clearIcon={null}
                                      disableClearable
                                      fullWidth
                                      size="small"
                                      value={fieldOptions.find(
                                        (option) =>
                                          option.value === subRule.field
                                      )}
                                      onChange={(event, newValue) => {
                                        handleRuleChange(
                                          rule.index,
                                          null,
                                          null,
                                          subIndex,
                                          {
                                            field: newValue?.value,
                                            input: "",
                                            process:
                                              newValue?.value === "date" ||
                                              newValue?.value === "amount"
                                                ? "=="
                                                : "includes",
                                          }
                                        );
                                      }}
                                      options={fieldOptions}
                                      renderInput={(params) => (
                                        <TextField
                                          size="small"
                                          sx={{
                                            backgroundColor:
                                              isEmpty &&
                                              (subRule.field === "" ||
                                                subRule.field === null ||
                                                subRule.field === undefined)
                                                ? "#ff000031"
                                                : undefined,
                                          }}
                                          {...params}
                                        />
                                      )}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={1.9}>
                                    <Typography
                                      variant="body2"
                                      gutterBottom
                                      sx={{
                                        mb: 0,
                                      }}
                                    >
                                      {"Metin / Değer Giriniz"}
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      size="small"
                                      value={subRule.input}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        handleRuleChange(
                                          rule.index,
                                          null,
                                          null,
                                          subRule.subIndex,
                                          "input",
                                          value
                                        );
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={1.4}>
                                    <Typography
                                      variant="body2"
                                      gutterBottom
                                      sx={{
                                        mb: 0,
                                      }}
                                    >
                                      İşlem Seçiniz
                                    </Typography>
                                    <Autocomplete
                                      fullWidth
                                      disableClearable
                                      clearIcon={null}
                                      size="small"
                                      value={processOptions.find(
                                        (option) =>
                                          option.value === subRule.process
                                      )}
                                      onChange={(event, newValue) =>
                                        handleRuleChange(
                                          rule.index,
                                          null,
                                          null,
                                          subRule.subIndex,
                                          "process",
                                          newValue?.value
                                        )
                                      }
                                      options={processOptions.filter(
                                        (option) => {
                                          if (
                                            subRule.field ===
                                              "invoice.receiver_tax" ||
                                            subRule.field ===
                                              "invoice.sender_tax"
                                          ) {
                                            return (
                                              option.value !== "endsWith" &&
                                              option.value !== "startsWith" &&
                                              option.value !== "notIncludes" &&
                                              option.value !== "includes" &&
                                              option.value !== ">" &&
                                              option.value !== "<" &&
                                              option.value !== ">=" &&
                                              option.value !== "<="
                                            );
                                          } else if (
                                            subRule.field ===
                                              "invoice.receiver_tax.List" ||
                                            subRule.field ===
                                              "invoice.sender_tax.List" ||
                                            subRule.field === "line?.name.List"
                                          ) {
                                            return (
                                              option.value !== "endsWith" &&
                                              option.value !== "startsWith" &&
                                              option.value !== "!=" &&
                                              option.value !== "==" &&
                                              option.value !== ">" &&
                                              option.value !== "<" &&
                                              option.value !== ">=" &&
                                              option.value !== "<="
                                            );
                                          } else {
                                            return (
                                              option.value !== ">" &&
                                              option.value !== "<" &&
                                              option.value !== ">=" &&
                                              option.value !== "<="
                                            );
                                          }
                                        }
                                      )}
                                      renderInput={(params) => (
                                        <TextField
                                          size="small"
                                          sx={{
                                            backgroundColor:
                                              isEmpty &&
                                              (subRule.process === "" ||
                                                subRule.process === null ||
                                                subRule.process === undefined)
                                                ? "#ff000031"
                                                : undefined,
                                          }}
                                          {...params}
                                        />
                                      )}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    sm={0.5}
                                    sx={{
                                      marginBottom: "5px",
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <IconButton
                                      onClick={() =>
                                        handleRemoveRule(
                                          rule.index,
                                          subRule.subIndex
                                        )
                                      }
                                      color="error"
                                    >
                                      <FaRegTrashAlt size={15} />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              ))}
                              <Grid
                                item
                                xs={12}
                                sm={8}
                                sx={{
                                  height: "auto",
                                  ml: rule.subRules.length > 0 ? "8%" : "5%",
                                  mt: 1,
                                  mb: 1,
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  onClick={() => handleAddSubRule(rule.index)}
                                  size="small"
                                  sx={{
                                    textTransform: "none",
                                    color: "#ffa34c",
                                    borderColor: "#fff0e1",
                                    backgroundColor: "#fff0e1",
                                    "&:hover": {
                                      backgroundColor: "#eedbc7c0",
                                      borderColor: "#fff0e1",
                                    },
                                  }}
                                >
                                  Ve
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  startIcon={<FaPlus size={14} />}
                  variant="outlined"
                  onClick={() => handleAddRule("end")}
                  sx={{
                    textTransform: "none",
                    color: "#35ca78", // Metin rengi
                    borderColor: "#35ca78", // Kenarlık rengi
                    backgroundColor: loading ? undefined : "#ddf6e8",
                    "&:hover": {
                      backgroundColor: "#a5fcb4a4",
                      borderColor: "#35ca78",
                    },
                  }}
                  disabled={loading}
                >
                  Kural Ekle
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
