//TODO  BU SAYFADA MASTER GRİD(SUBDETAILS) AYARLARI VE COLDEFS AYARLARI BULUNUR.
//TODO  ANA GRİDİN column ayarları ulunur

import moment from "moment";
import sideBar from "../../components/GridSideBar/gridSideBar.js";
import localeTextTr from "../../locale.tr.js";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { FaCircleXmark } from "react-icons/fa6";
import { TbCopyPlus } from "react-icons/tb";
import { FaCircleCheck } from "react-icons/fa6";
import { createTurkishFilterParams } from "../../utils/agGridTextFilterTr/agGridFilterUtils.js";

const StatusRenderer = (props) => {
  const { value } = props;

  if (value === 100) {
    return null;
  } else if (value === 102) {
    return (
      <span
        title="Hesap Kodu Bulunamadı"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <FaCircleXmark style={{ color: "#ea5455" }} size={24} />
      </span>
    );
  } else if (value === 103) {
    return (
      <span
        title="Çoklu Hesap Bulundu"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <TbCopyPlus
          style={{
            color: "#ffffff",
            backgroundColor: "#00cfe8",
            padding: 5,
            borderRadius: "50%",
          }}
          size={14}
        />
      </span>
    );
  } else if (value === 104) {
    return (
      <span
        title="Aktarılabilir"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <FaCircleCheck style={{ color: "#28c76f" }} size={24} />
      </span>
    );
  } else {
    return value;
  }
};

export const createColumnDefs =(companyClass)=> [
  {
    headerName: "",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerClass: "checkbox",
    pinned: "left",
    width: 48,
    field: "checkboxBtn",
    resizable: false,
    lockPosition: "left",
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    suppressMenu: true,
    editable: false,
    filter: false,
  },
  ...(companyClass === 1 ? [{
    field: "master",
    headerName: "",
    cellRenderer: "agGroupCellRenderer",
    editable: false,
    filter: false,
    suppressMenu: true,
    flex:0,
    width: 48,
    resizable:false
  },] : []),
  {
    field: "ficheNumber",
    headerName: "Fiş No",
    filter: "agTextColumnFilter",
    suppressMenu: false,
  },
  {
    field: "status",
    headerName: "Aktarım Durumu",
    width: 150,
    flex: 0,
    filter: "agSetColumnFilter",
    cellStyle: { textAlign: "center" },
    filterParams: {
      valueFormatter: function (params) {
        if (params.value === 102) {
          return "Hesap Kodu Bulunamadı";
        } else if (params.value === 103) {
          return "Çoklu Hesap Bulundu";
        } else if (params.value === 104) {
          return "Aktarılabilir";
        }
      },
    },
    editable: false,
    cellRenderer: StatusRenderer,
  },
  {
    field: "direction",
    headerName: "Yön",
    width: 80,
    flex: 0,
    filter: "agSetColumnFilter",
    filterParams: {
      valueFormatter: function (params) {
        if (params.value === 2) {
          return "Gelen";
        } else if (params.value === 1) {
          return "Giden";
        } else {
          return params.value;
        }
      },
    },
    valueFormatter: function (params) {
      if (params.value === 2) {
        return "Gelen";
      } else if (params.value === 1) {
        return "Giden";
      } else {
        return params.value;
      }
    },
  },
  {
    field: "date",
    headerName: "Tarih",
    editable: false,
    valueFormatter: function (params) {
      return moment.utc(params.value).format("DD/MM/YYYY");
    },
    filter: false,
  },
  {
    field: "description",
    headerName: "Açıklama",
    filter: "agTextColumnFilter",
  },
  {
    field: "taxableAmount",
    headerName: "Matrah Tutarı",
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => {
      if (params.value !== null && params.value !== undefined) {
        // Number to locale string conversion
        return new Intl.NumberFormat("tr-TR", {
          currency: "TRY",
        }).format(params.value);
      } else {
        return "";
      }
    },
  },
  {
    field: "taxPercent",
    headerName: "KDV Oranı",
    filter: "agTextColumnFilter",
    suppressMenu: false,
  },
  {
    field: "taxAmount",
    headerName: "KDV Tutarı",
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => {
      if (params.value !== null && params.value !== undefined) {
        // Number to locale string conversion
        return new Intl.NumberFormat("tr-TR", {
          currency: "TRY",
        }).format(params.value);
      } else {
        return "";
      }
    },
  },
  {
    field: "payableAmount",
    headerName: "Toplam Tutar",
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => {
      if (params.value !== null && params.value !== undefined) {
        // Number to locale string conversion
        return new Intl.NumberFormat("tr-TR", {
          currency: "TRY",
        }).format(params.value);
      } else {
        return "";
      }
    },
  },
  {
    field: "senderName",
    headerName: "Gönderici Ünvan",
    filter: "agTextColumnFilter",
  },
  {
    field: "senderTax",
    headerName: "Gönderici VKN",
    filter: "agTextColumnFilter",
  },

  {
    field: "lucaAccountCode",
    headerName: "Luca Hesap Kodu(Kasa,Kredi K. - vs...)",
    filter: "agTextColumnFilter",
    editable: false,
    // cellRenderer: (params) => params.value,
    // cellStyle: (params) => {
    //   return params?.data?.lucaCurrentCode
    //     ? { backgroundColor: "transparent" }
    //     : params?.data?.possibleLucaAccounts.length > 0
    //     ? { backgroundColor: "#17a2b8" }
    //     : { backgroundColor: "#ea5455" };
    // },
    // cellEditor:ItemSelectorCellEditor,
    // valueSetter: (params) => params.data.lucaCurrentCode = params.newValue,
    // suppressKeyboardEvent: (params) => {
    //   // Eğer Enter tuşuna basılmışsa
    //   if (params.event.key === 'Enter') {
    //     return true; // Olayı bastır (düzenleyiciyi kapatma)
    //   }
    //   return false; // Diğer tuşlar normal çalışsın
    // },
  },
  // {
  //   field: "lucaCurrentName",
  //   headerName: "Luca Hesap Adı",
  //   filter: "agTextColumnFilter",
  //   width: 200,
  // },
];

const createDetailsColumnDefs = [
  {
    field: "hesap_kodu",
    headerName: "Hesap Kodu",
    filter: "agTextColumnFilter",
    width: 400,
  },
  {
    field: "borc",
    headerName: "Borç",
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => {
      return new Intl.NumberFormat("tr-TR", {
        style: "currency",
        currency: "TRY",
      }).format(params.value);
    },
  },
  {
    field: "alacak",
    headerName: "Alacak",
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => {
      return new Intl.NumberFormat("tr-TR", {
        style: "currency",
        currency: "TRY",
      }).format(params.value);
    },
  },
];
export const detailsGridOptions = {
  columnDefs: createDetailsColumnDefs,
  defaultColDef: {
    resizable: true,
    floatingFilter: true,
    filterParams:createTurkishFilterParams(),
  },
  sideBar: sideBar.detailsSideBar,
  localeText: localeTextTr,
  rowDragManaged: true,
  animateRows: true,
  rowSelection: "multiple",
  singleClickEdit: true,
  rowMultiSelectWithClick: true,
};
