import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";

import withReactContent from "sweetalert2-react-content";
import { Autocomplete, Button, TextField } from "@mui/material";
import { copyRuleToCompanies } from "../../../api/advisor/copyRuleToCompanies";
import {showLoadingSwal} from "../../../components/loadingSwal"
import { copyAdminRuleToCompanies } from "../../../api/adminApis/copyAdminRuleToCompanies";
const MySwal = withReactContent(Swal);
// Yükleniyor Swal bileşeni
export const CopyRuleToSelectedCompaniesSwal = ({
  open,
  handleClose,
  selectedCompanies,
  companiesList,
  setUpdateRow,
}) => {
  const [companies, setCompanies] = useState(
    companiesList ? companiesList : []
  );
  const [company, setCompany] = useState();
  const [loading, setLoading] = useState(true);
  const baseCompany = useRef("");
  useEffect(() => {
    if (open) {
      baseCompany.current=""
      if (selectedCompanies.length === 0) {
        Swal.fire({
          title: "Uyarı",
          text: "Lütfen en az 1 firma seçiniz.",
          icon: "error",
          confirmButtonText: "Tamam",
        });
        handleClose();
        return;
      }
      MySwal.fire({
        title: "Referans Firmayı Seçiniz...",
        icon: "warning",
        showConfirmButton: false,
        showCancelButton: false,
        html: (
          <div style={{ width: "100%", padding: "10px 0" }}>
            {" "}
            <Autocomplete
              options={companies}
              value={company}
              onChange={(event, newValue) => {
                baseCompany.current = {
                  id: newValue.id,
                  label: `${newValue.name} - ${newValue.companyId}-${
                    newValue.periodId
                  }-${newValue.year}${
                    newValue.class === 1
                      ? "-BİLANÇO"
                      : newValue.class === 2
                      ? "-İŞLETME"
                      : ""
                  }`,
                };
              }}
              noOptionsText={loading ? "Yükleniyor..." : "Seçenek bulunamadı"}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                  const highlightedOption = event.target.querySelector(
                    'li[data-focus="true"]'
                  );
                  if (highlightedOption) {
                    highlightedOption.click();
                  }
                }
              }}
              autoHighlight
              selectOnFocus
              blurOnSelect
              openOnFocus
              disablePortal
              ListboxProps={{ className: "specialListBox" }}
              getOptionLabel={(option) =>
                `${option.name} - ${option.companyId}-${option.periodId}-${
                  option.year
                }${
                  option.class === 1
                    ? "-BİLANÇO"
                    : option.class === 2
                    ? "-İŞLETME"
                    : ""
                }`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Firma seçiniz..."
                  variant="outlined"
                  size="small"
                />
              )}
              style={{ width: "100%" }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
                gap: 10,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor:
                    "#15356d" /* Onay butonunun rengini mavi yapar */,
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#112d5e",
                  },
                }}
                onClick={() => MySwal.clickConfirm()}
                className="swal2-confirm swal2-styled"
              >
                Devam
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#d33",
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#a12929",
                  },
                }}
                onClick={() => MySwal.clickCancel()}
                className="swal2-cancel swal2-styled"
              >
                İptal
              </Button>
            </div>
          </div>
        ),
        customClass: {
          popup: "swal2-custom-autocomplete",
        },
        preConfirm: () => {
          if (!baseCompany.current) {
            MySwal.showValidationMessage("Lütfen bir firma seçiniz.");
            return false; // Bu, Swal penceresini açık tutar ve form onayını engeller
          } else if (
            selectedCompanies.find((item) => item === baseCompany.current.id)
          ) {
            MySwal.showValidationMessage(
              "Referans firma seçilen firmalar listesinde olamaz !"
            );
            return false;
          }
          return baseCompany.current;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const value = result.value.id;
          const label = result.value.label;
          Swal.fire({
            html: `<p>${label} firmasından seçilen firmalara kural kopyalanacak.Devam etmek istiyor musunuz ?</p>
            <p >NOT:Kuralı kopyalanacak ve seçilen firmaların <span style="color:red"> aynı sınıfta (İŞLETME,BİLANÇO) </span> olduğundan emin olunuz.<br>   </p>
            `,
            confirmButtonText: "Devam Et",
            cancelButtonText: "İptal",
            icon: "warning",
            showCancelButton: true,
          }).then((subRes) => {
            if (subRes.isConfirmed) {
              let data = {
                companyIds: selectedCompanies,
                baseCompanyId: value,
              };
              showLoadingSwal();
              copyRuleToCompanies(data)
                .then((resp) => {
                  Swal.close()
                  Swal.fire({
                    title: "Başarılı",
                    text: resp.data.message,
                    icon: "success",
                    confirmButtonText: "Tamam",
                  });

                })
                .catch((err) => {
                  Swal.close()
                  Swal.fire({
                    title: err.response.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                    denyButtonText: "Hata alan firmaları göster",
                    showDenyButton: true,
                  }).then((result) => {
                    if (result.isDenied) {
                      (() => {
                        const failedCompaniesListHTML = `
                            <html>
                            <head>
                              <title>Hata Alan Firmalar</title>
                              <meta charset="UTF-8">
                              <style>
                                table {
                                  border-collapse: collapse;
                                  width: 100%;
                                }
                                th, td {
                                  border: 1px solid #dddddd;
                                  text-align: left;
                                  padding: 8px;
                                }
                                th {
                                  background-color: #f2f2f2;
                                }
                              </style>
                            </head>
                            <body>
                              <h2>Hata Alan Firmalar</h2>
                              <p>Seçilen Firmalar ile Referans firmanın sınıfı aynı olmalıdır.Örneğin,Referans firmanın sınıfı BİLANÇO ise,seçilen firmanın sınıfı BİLANÇO olmalıdır.</p>
                             <p style="color:red" >Eğer firmada hata olmadığını düşünüyorsanız firmanın sınıfını güncellemek için firmaya giriş yapmanız yeterlidir.</p>
                              <table>
                                <thead>
                                  <tr>
                                    <th>Sıra</th>
                                    <th>Firma Adı</th>
                                    <th>Luca Firma Id</th>
                                    <th>Luca Period ID</th>
                                    <th>Dönem</th>
                                    <th>Sınıfı</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  ${err.response.data.erroredCompanies
                                    .map(
                                      (company, index) => `
                                    <tr>
                                      <td>${index + 1}</td>
                                      <td>${company.name}</td>
                                      <td>${company.companyId}</td>
                                      <td>${company.periodId}</td>
                                      <td>${company.year}</td>
                                      <td>${company.class}</td>
                                    </tr>
                                  `
                                    )
                                    .join("")}
                                </tbody>
                              </table>
                            </body>
                          </html>
                            `;

                        const failedFilesCountBlob = new Blob(
                          [failedCompaniesListHTML],
                          { type: "text/html" }
                        );
                        const failedFilesCountUrl =
                          URL.createObjectURL(failedFilesCountBlob);

                        window.open(failedFilesCountUrl, "_blank");
                      })();
                    }
                  });
                });
            }
          });

          }
      });

      handleClose();
    }
  }, [open, handleClose, selectedCompanies]);

  return null;
};
