import React, { useEffect, useRef, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IoCloudOutline } from "react-icons/io5";
import { BsArchive, BsBraces } from "react-icons/bs";
import { PiFileX, PiListChecksLight } from "react-icons/pi";
import { showLoadingSwal } from "../../components/loadingSwal";
import { TbPlaylistAdd, TbPlaylistX, TbList, TbTrash } from "react-icons/tb";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { getJsonItem } from "../../utils/localStorageProcess";
import Swal from "sweetalert2";
import moment from "moment";
import { fetchInvoices } from "../../api/company/invoices/fetchInvoices";
import {
  checkRahatProxyVersion,
  getCompanyDetailsForEArchiveInvoices,
  getLucaClientAndDomain,
  fetchIncomingEarchivesForSelectedCompany,
  isRunningCompany,
} from "../../api/company/invoices/EArchiveInvoicesApis";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    keepMounted
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
      // Typography için eklenen yeni stiller
      "& .MuiTypography-root:not([data-exempt='true'])": {
        fontSize: "14px",
        fontWeight: 400,
        color: "#605f66",
      },
    },
  },
}));

export default function ProcessMenu({
  startDate,
  endDate,
  setModalId,
  handleModalOpen,
  setUpdateRow,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();
  const [companyDetails, setCompanyDetails] = useState();
  const companyDetailsRef = useRef(companyDetails);
  useEffect(() => {
    let locationSplit = location.pathname.split("/");
    const details = getJsonItem(`#${locationSplit[2]}/${locationSplit[3]}`);
    setCompanyDetails(details);
    companyDetailsRef.current = details; // referansı güncelle
  }, [location.pathname]);
  let startDateFormat = moment(startDate).format("DD.MM.YYYY");
  let endDateFormat = moment(endDate).format("DD.MM.YYYY");

  async function downloadRahatProxy() {
    try {
      window.location.href = "/downloads/RahatProxy.rar";
    } catch (downloadError) {
      Swal.fire({
        icon: "error",
        title: "Dosya İndirilemedi!",
        text: "Dosya indirme işlemi sırasında bir hata oluştu.",
        confirmButtonText: "Tamam",
      });
    }
  }

  //TODO ENTEGRETÖRDEN GETİR
  const handleFetchInvoices = () => {
    let html;
    let locationSplit = location.pathname.split("/");
    companyDetailsRef.current.integrators &&
    companyDetailsRef.current.integrators.length > 0
      ? (html = `<b>${startDateFormat} - ${endDateFormat}</b> tarihleri arasındaki faturalar
      <strong> ${companyDetails.integrators
        .map((int) => int.integrator)
        .join(", ")} </strong>
      ${
        companyDetailsRef.current.integrators.length > 1
          ? "entegratörlerinden"
          : "entegratöründen"
      } getirilecek. Devam etmek istiyor musunuz?`)
      : (html = `Mükellefin entegratör bilgileri yok. <b>${startDateFormat} - ${endDateFormat}</b> tarih aralığındaki faturalar GİB portal'dan alınacak. Devam etmek istiyor musunuz?`);
    Swal.fire({
      html,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Evet, senkronize et!",
      cancelButtonText: "Hayır",
    }).then(async (result) => {
      if (result.isConfirmed) {
        showLoadingSwal();
        fetchInvoices(locationSplit[2], locationSplit[3], startDate, endDate)
          .then((resp) => {
            Swal.close();
            Swal.fire({
              html: resp.data.message,
              icon: "success",
              confirmButtonText: "Tamam",
            });
          })
          .catch((err) => {
            Swal.close();
            Swal.fire({
              title: "Bir hata oluştu!",
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: "Tamam",
            });
          });
      }
    });
  };
  //ENTEGRETÖRDEN GETİR////----------

  //TODO GELEN E-ARŞİV FATURALARINI GETİR
  const handleGetEArchiveInvoices = () => {
    let locationSplit = location.pathname.split("/");
    let html;
    html = `<b>${startDateFormat} - ${endDateFormat}</b> tarih aralığındaki gelen <br/> e-arşiv faturalar DVD'den alınacak. Devam etmek istiyor musunuz?`;
    Swal.fire({
      html,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Evet, senkronize et!",
      cancelButtonText: "Hayır",
    }).then(async (result) => {
      if (result.isConfirmed) {
        checkRahatProxyVersion()
          .then((resp) => {
            if (
              resp.data.version !== process.env.REACT_APP_RAHAT_PROXY_VERSION
            ) {
              Swal.fire({
                icon: "error",
                title: "Rahat Proxy Güncellemesi Mevcut!",
                text: "Lütfen Rahat Proxy uygulamasını güncelleyin.",
                confirmButtonText: "Uygulamayı İndir",
                showDenyButton: true,
                denyButtonText: "Tamam",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                focusConfirm: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  downloadRahatProxy();
                }
              });
            } else {
              Swal.fire({
                title: "Gelen e-arşiv faturaları çekiliyor...",
                html: `
                  
                  <p style="color: red;">İşlemin yarıda kalmaması için lütfen sayfayı kapatmayınız ve yenilemeyiniz</p>
                  <p class="text-info">Yeni sekme açarak diğer işlemlerinize devam edebilirsiniz</p>
                  <p>Faturalar çekiliyor...</p>
                `,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                  popup: "swal2-content-centered",
                },
                willOpen: () => {
                  Swal.showLoading();
                },
              });

              getCompanyDetailsForEArchiveInvoices(
                locationSplit[2],
                locationSplit[3]
              )
                .then((resp) => {
                  let company = resp.data.company;

                  getLucaClientAndDomain()
                    .then((resp) => {
                      fetchIncomingEarchivesForSelectedCompany(
                        company,
                        resp.data,
                        startDate,
                        endDate
                      )
                        .then(async (resp) => {
                          let flag = true;
                          while (flag) {
                            isRunningCompany(company, startDate, endDate)
                              // eslint-disable-next-line no-loop-func
                              .then((resp) => {
                                if (resp.data?.isActive !== true) {
                                  flag = false;
                                }
                                if (resp.data?.isActive !== true) {
                                  Swal.fire({
                                    icon: resp.data.message.includes(
                                      "text-danger"
                                    )
                                      ? "error"
                                      : "success",
                                    title: resp.data.message.includes(
                                      "text-danger"
                                    )
                                      ? "E-arşiv Faturaları Çekme İşlemi Sırasında Hata Oluştu"
                                      : "E-arşiv Faturaları Çekme İşlemi Tamamlandı",
                                    html: `<p>${resp.data.message}</p>`,
                                    confirmButtonText: "Tamam",
                                  });

                                  setUpdateRow(true);
                                }
                              })
                              // eslint-disable-next-line no-loop-func
                              .catch((err) => {
                                if (err.resp.data?.isActive !== true) {
                                  flag = false;
                                }
                                if (err.code === "ERR_NETWORK") {
                                  Swal.fire({
                                    icon: "error",
                                    title:
                                      "Rahat Proxy ile Bağlantı Kurulamadı!",
                                    text: "Lütfen Rahat Proxy uygulamasını indirin veya uygulamayı başlatın.",
                                    confirmButtonText: "Uygulamayı İndir",
                                    showDenyButton: true,
                                    denyButtonText: "Tamam",
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      downloadRahatProxy();
                                    }
                                  });
                                } else {
                                  Swal.fire({
                                    icon: "error",
                                    title:
                                      "E-arşiv Faturaların Çekme İşlemi Başarısız!",
                                    text: err.response.data.message,
                                    confirmButtonText: "Tamam",
                                  });
                                }
                              });
                            await new Promise((resolve) =>
                              setTimeout(resolve, 10000)
                            );
                          }

                          Swal.close();
                        })
                        .catch((err) => {
                          if (err.code === "ERR_NETWORK") {
                            Swal.fire({
                              icon: "error",
                              title: "Rahat Proxy ile Bağlantı Kurulamadı!",
                              text: "Lütfen Rahat Proxy uygulamasını indirin veya uygulamayı başlatın.",
                              confirmButtonText: "Uygulamayı İndir",
                              showDenyButton: true,
                              denyButtonText: "Tamam",
                              allowOutsideClick: false,
                              allowEscapeKey: false,
                              allowEnterKey: false,
                              focusConfirm: true,
                            }).then((result) => {
                              if (result.isConfirmed) {
                                downloadRahatProxy();
                              }
                            });
                          } else {
                            // Handle other Ajax errors as needed
                            Swal.fire({
                              icon: "error",
                              title:
                                "E-arşiv Faturaların Çekme İşlemi Başarısız!",
                              text: err.response.data.errorMessage,
                              confirmButtonText: "Tamam",
                            });
                          }
                        });
                    })
                    .catch((err) => {
                      Swal.fire({
                        text: "Hata Oluştu",
                        icon: "warning",
                        confirmButtonText: "Tamam",
                      });
                      return;
                    });
                })
                .catch((err) => {
                  Swal.fire({
                    title: "Mükellef bilgileri alınamadı!",
                    text: "Mükellef bilgileri alınırken hata oluştu!",
                    icon: "warning",
                    confirmButtonText: "Tamam",
                  });
                  return;
                });
            }
          })
          .catch((err) => {
            if (err.code === "ERR_NETWORK") {
              Swal.fire({
                icon: "error",
                title: "Rahat Proxy ile Bağlantı Kurulamadı!",
                text: "Lütfen Rahat Proxy uygulamasını indirin veya uygulamayı başlatın.",
                confirmButtonText: "Uygulamayı İndir",
                showDenyButton: true,
                denyButtonText: "Tamam",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                focusConfirm: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  downloadRahatProxy();
                }
              });
            }
          });
      }
    });
  };
  //GELEN E-ARŞİV FATURALARINI GETİR////-------------

  return (
    <Grid>
      <Button
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        sx={{
          textTransform: "none",
          backgroundColor: "#dbf0fd",
          color: "#2ea8f3",
          "&:hover": {
            backgroundColor: "#cfe6f5",
          },
        }}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        İşlemler
      </Button>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
      >
        <MenuItem disabled>
          <Typography
            data-exempt="true"
            variant="subtitle2"
            fontSize={12}
            color={"black"}
            fontWeight={600}
          >
            FATURALARI GETİR
          </Typography>
        </MenuItem>

        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            handleClose();
            handleFetchInvoices();
          }}
        >
          <IoCloudOutline />
          <Typography>Entegratörden Getir</Typography>
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            handleClose();
            handleGetEArchiveInvoices();
          }}
        >
          <BsArchive />
          <Typography>Gelen E-arşiv Faturaları Getir</Typography>
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            setModalId("#getFromXMLFile");
            handleClose();
            handleModalOpen();
          }}
        >
          <PiFileX />
          <Typography>XML Dosyasından Getir</Typography>
        </MenuItem>
        <MenuItem disabled>
          <Typography
            data-exempt="true"
            variant="subtitle2"
            fontSize={12}
            color={"black"}
            fontWeight={600}
          >
            EK İŞLEMLER
          </Typography>
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            setModalId("#viewSyncs");
            handleClose();
            handleModalOpen();
          }}
        >
          <PiListChecksLight />
          <Typography>Senkronizasyonları Görüntüle</Typography>
        </MenuItem>
        {companyDetailsRef.current?.class === 1 && (
          <MenuItem
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
            onClick={() => {
              setModalId("#separateSelectedInvoices");
              handleClose();
              handleModalOpen();
            }}
          >
            <BsBraces />
            <Typography>Seçili Faturaları Parçala</Typography>
          </MenuItem>
        )}

        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            setModalId("#addBlackList");
            handleClose();
            handleModalOpen();
          }}
        >
          <TbPlaylistAdd />
          <Typography>Seçili Faturaları Kara Listeye Ekle</Typography>
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            setModalId("#removeBlackList");
            handleClose();
            handleModalOpen();
          }}
        >
          <TbPlaylistX />
          <Typography>Seçili Faturaları Kara Listeden Kaldır</Typography>
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            setModalId("#viewSelectedInvoices");
            handleClose();
            handleModalOpen();
          }}
        >
          <TbList />
          <Typography>Seçilen Faturaları Görüntüle</Typography>
        </MenuItem>

        <MenuItem disabled>
          <Typography
            data-exempt="true"
            variant="subtitle2"
            fontSize={12}
            color={"black"}
            fontWeight={600}
          >
            SİLME
          </Typography>
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            setModalId("#deleteSelectedInvoices");
            handleClose();
            handleModalOpen();
          }}
        >
          <TbTrash color="red" />
          <Typography>Seçili Faturaları Sil</Typography>
        </MenuItem>
      </StyledMenu>
    </Grid>
  );
}
