/** @format */

import React, { useEffect, useState } from "react";
import { TextField, Button, styled } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "moment/locale/tr";
import moment from "moment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiInputBase-root": {
        height: "auto", // Ekran boyutuna göre dinamik yükseklik
        fontSize: "0.9rem", // Yazı boyutunu dinamik yapabiliriz
        [theme.breakpoints.down("sm")]: {
            fontSize: "0.75rem", // Küçük ekranlarda yazı boyutunu küçültme
        },
    },
    "& .MuiInputBase-input": {
        paddingRight: "0.75rem", // Padding değerini dinamik yapabiliriz
        [theme.breakpoints.down("sm")]: {
            padding: "0.5rem", // Küçük ekranlarda padding değerini küçültme
        },
    },
}));

const DateRangePicker = ({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    isLimitedRangeForMonth,
}) => {
    function CustomActionBar(props) {
        const { onSetToday, className } = props;

        return (
            <Button
                className={className}
                sx={{
                    textTransform: "none",
                    width: "50%",
                    marginLeft: "auto",
                    color: "black",
                }}
                onClick={() => {
                    onSetToday();
                }}
                startIcon={<CalendarTodayIcon />}
            >
                Bugün
            </Button>
        );
    }

    const isToday = endDate ? moment(endDate).isSame(moment(), "day") : false;

    const handleDefaultTime = (date, defaultTime) => {
        return date ? moment(date).set(defaultTime) : moment().set(defaultTime);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="tr">
            <StyledDatePicker
                renderInput={(props) => (
                    <TextField {...props} label="Başlangıç Tarihi" />
                )}
                views={["month", "year", "day"]}
                format="YYYY-MM-DD HH:mm"
                value={
                    isLimitedRangeForMonth
                        ? startDate > endDate ||
                          (startDate <
                              moment(moment(endDate)).subtract(30, "day") &&
                              !isLimitedRangeForMonth &&
                              startDate !== null)
                            ? setStartDate(null)
                            : moment(startDate)
                        : undefined
                }
                onChange={(newValue) =>
                    setStartDate(
                        handleDefaultTime(newValue, { hour: 0, minute: 0 })
                    )
                }
                label="Başlangıç Tarihi"
                slots={{
                    actionBar: isToday ? CustomActionBar : null,
                }}
                slotProps={{
                    actionBar: { actions: isToday ? ["today"] : null },
                    textField: {
                        size: "small",
                    },
                }}
            />
            <StyledDatePicker
                renderInput={(props) => (
                    <TextField {...props} label="Bitiş Tarihi" />
                )}
                views={["month", "year", "day"]}
                format="YYYY-MM-DD HH:mm"
                orientation="portrait"
                value={isLimitedRangeForMonth ? moment(endDate) : undefined}
                onChange={(newValue) =>
                    setEndDate(
                        handleDefaultTime(newValue, { hour: 23, minute: 59 })
                    )
                }
                sx={{ marginLeft: "1vw" }}
                label="Bitiş Tarihi"
                slots={{
                    actionBar: CustomActionBar,
                }}
                slotProps={{
                    actionBar: { actions: ["today"] },
                    textField: { size: "small" },
                }}
            />
        </LocalizationProvider>
    );
};

export default DateRangePicker;
