import React, { useEffect, useRef, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { PiInvoice, PiMicrosoftExcelLogo } from "react-icons/pi";
import { showLoadingSwal } from "../../components/loadingSwal";
import { createTaxReport } from "../../api/company/invoices/createTaxReport";
import { createCheckSeriesReport } from "../../api/company/invoices/createCheckSeriesReport";
import { HiOutlineReceiptTax } from "react-icons/hi";

import {
  TbReport,
  TbNumbers,
} from "react-icons/tb";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, TextField, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { getJsonItem } from "../../utils/localStorageProcess";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

import { RiFileSearchLine } from "react-icons/ri";
const MySwal = withReactContent(Swal);
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    keepMounted
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
      // Typography için eklenen yeni stiller
      "& .MuiTypography-root:not([data-exempt='true'])": {
        fontSize: "14px",
        fontWeight: 400,
        color: "#605f66",
      },
    },
  },
}));

export default function ProcessMenu({
  startDate,
  endDate,
  setModalId,
  handleModalOpen,
  setUpdateRow,
}) {
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);

  const location = useLocation();
  const [companyDetails, setCompanyDetails] = useState();
  const companyDetailsRef = useRef(companyDetails);
  useEffect(() => {
    let locationSplit = location.pathname.split("/");
    const details = getJsonItem(`#${locationSplit[2]}/${locationSplit[3]}`);
    setCompanyDetails(details);
    companyDetailsRef.current = details; // referansı güncelle
  }, [location.pathname]);

  //TODO Kdv Raporu Oluştur
  const createLastMonthTaxReport = () => {
    let locationSplit = location.pathname.split("/");
    Swal.fire({
      title: "PDF Oluşturuluyor...",
      text: "Lütfen bekleyiniz",
      icon: "info",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        popup: "swal2-content-centered",
      },
      willOpen: () => {
        Swal.showLoading();
      },
    });
    createTaxReport(locationSplit[2], locationSplit[3])
      .then((resp) => {
        setUpdateRow(true)
        Swal.fire({
          title: "Başarılı!",
          html: resp.data.message,
          icon: "success",
          confirmButtonText: "Tamam",
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Bir hata oluştu!",
          text: err.response.data.message,
          icon: "error",
          confirmButtonText: "Tamam",
        });
      });
  };
  //Kdv Raporu Oluştur////----------
  //TODO Fatura Seri Kontrol
  const createCheckSeriesRep = () => {
    let locationSplit = location.pathname.split("/");
    let startDate = moment()
        .subtract(1, "month")
        .startOf("month")
      let endDate = moment()
        .subtract(1, "month")
        .endOf("month")
    MySwal.fire({
      title: "Tarih Aralığı Seçin",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Devam",
      cancelButtonText: "İptal",
      focusConfirm: false,
      html: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 5,
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="tr"
            >
              <DatePicker
                label="Başlangıç Tarihi"
                value={moment(startDate)}
                onChange={(newValue) => {
                  startDate = moment(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    className="input-field"
                    size="small"
                    {...params}
                  />
                )}
              />
              <DatePicker
                label="Bitiş Tarihi"
                value={moment(endDate)}
                onChange={(newValue) => {
                  endDate = moment(newValue);
                }}
                sx={{ marginLeft: 2 }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    className="input-field"
                    sx={{ color: "black" }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </div>

        </div>
      ),
      preConfirm: () => {
        if (!startDate||!endDate) {
          MySwal.showValidationMessage("Lütfen tüm alanları doldurun");
          return false; // Bu, Swal penceresini açık tutar ve form onayını engeller
        }
        return { startDate, endDate};
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        showLoadingSwal();
        createCheckSeriesReport(
          locationSplit[2],
          locationSplit[3],
          startDate,
          endDate
        )
          .then((resp) => {
            Swal.close();
            setUpdateRow(true)
            Swal.fire({
              html: resp.data.message,
              icon: "success",
              confirmButtonText: "Tamam",
            });
          })
          .catch((err) => {
            Swal.close();
            Swal.fire({
              title: "Bir hata oluştu!",
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: "Tamam",
            });
          });
      }
    });
  };
  //Fatura Seri Kontrol Raporu Oluştur////----------
  return (
    <Grid sx={{display:"flex", gap:1}}> 
      <Button
        aria-haspopup="true"
        aria-expanded={open1 ? "true" : undefined}
        variant="contained"
        disableElevation
        sx={{
          textTransform: "none",
          backgroundColor: "#dbf0fd",
          color: "#2ea8f3",
          "&:hover": {
            backgroundColor: "#cfe6f5",
          },
        }}
        onClick={handleClick1}
        endIcon={<KeyboardArrowDownIcon />}
      >
        İşlemler
      </Button>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl1}
        open={open1}
        onClose={handleClose1}
        disableScrollLock
      >
        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            handleClose1();
            createLastMonthTaxReport();
          }}
        >
          <TbReport />
          <Typography>Aylık KDV Raporu Oluştur</Typography>
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            handleClose1();
            createCheckSeriesRep();
          }}
        >
          <TbNumbers />
          <Typography>Fatura Seri Kontrol Raporu Oluştur</Typography>
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            setModalId("#exportInvoicestoExcelRowBased");
            handleClose1();
            handleModalOpen();
          }}
        >
          <PiInvoice />

          <Typography>Faturaları Satır Bazlı Excele Aktar</Typography>
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            setModalId("#createInvoiceControlReport");
            handleClose1();
            handleModalOpen();
          }}
        >
          <RiFileSearchLine/>
          <Typography>Fatura Kontrol Raporu Oluştur</Typography>
        </MenuItem>
        
      </StyledMenu>

      <Button
        aria-haspopup="true"
        aria-expanded={open2 ? "true" : undefined}
        variant="contained"
        disableElevation
        sx={{
          textTransform: "none",
          backgroundColor: "#e7fddb",
          color: "#187e21",
          "&:hover": {
            backgroundColor: "#cadfbf",
          },
        }}
        onClick={handleClick2}
        endIcon={<KeyboardArrowDownIcon />}
      >
        KDV İade İşlemleri
      </Button>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl2}
        open={open2}
        onClose={handleClose2}
        disableScrollLock
      >
        
        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            setModalId("#createGibVATListToExclude");
            handleClose2();
            handleModalOpen();
          }}
        >
          <HiOutlineReceiptTax />
          <Typography>İndirilecek KDV Listesi Oluştur</Typography>
        </MenuItem>
        <MenuItem
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          onClick={() => {
            setModalId("#createGibSalesInvoiceList");
            handleClose2();
            handleModalOpen();
          }}
        >
          <PiMicrosoftExcelLogo />
          <Typography>Satış Faturaları Listesi (301/308) Oluştur</Typography>
        </MenuItem>
        
      </StyledMenu>
    </Grid>
  );
}
