import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Autocomplete, Button, FormControl, ListItemText, TextField } from "@mui/material";
import { updateBankExtracts } from "../../api/company/bankExtracts/updateBankExtract";
import { getLucaAccounts } from "../../api/company/invoices/getLucaAccounts";
import { CreateLucaAccountSwal } from "../../components/createLucaAccountSwal";
const MySwal = withReactContent(Swal);

// Yükleniyor Swal bileşeni
export const ChangeAccountCodeSelectedExtractsSwal = ({
  open,
  handleClose,
  companyId,
  periodId,
  accountCodeList,
  selectedExtractIds,
  setUpdateRow,
}) => {
  const [accountCodes, setAccountCodes] = useState(
    accountCodeList ? accountCodeList : []
  );
  const [loading, setLoading] = useState(true);
  const accountBankCode = useRef("");
  useEffect(() => {
    accountBankCode.current=""
    if (!accountCodeList) {
      getLucaAccounts(companyId, periodId)
        .then((resp) => {
          setAccountCodes(resp.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    setAccountCodes(accountCodeList);
  }, [accountCodeList]);
  let accountCode
  useEffect(() => {
    if (open) {
      if (selectedExtractIds.length === 0) {
        Swal.fire({
          title: "Uyarı",
          text: "Lütfen en az 1 ekstre seçin.",
          icon: "error",
          confirmButtonText: "Tamam",
        });
        handleClose();
        return;
      }
      MySwal.fire({
        title: "Luca Hesabı Seçiniz",
        icon: "warning",
        showConfirmButton: false,
        showCancelButton: false,
        html: (
          <div style={{ width: "100%", padding: "10px 0" }}>
            {" "}
            <Autocomplete
              options={
                accountCodes
              }
              filterOptions={(options, params) => {
                 
                const filtered = options.filter((option) => {
                  let label = `${option.hesap_kodu} - ${option.hesap_adi}`;
                  return label
                    .toLocaleLowerCase("tr")
                    .includes(params?.inputValue?.toLocaleLowerCase("tr"));
                });
                // Girilen metin, mevcut seçenekler arasında yoksa özel bir seçenek ekle
                if (filtered?.length === 0) {
                  filtered.push({
                    label: "Yeni Hesap Kodu Eklemek İçin Tıklayın",
                    value: "add-new", // Özel seçenek için belirleyici bir value
                  });
                }
  
                return filtered;
              }}
              onChange={(event, newValue) => {
                if (newValue?.value === "add-new") {
                  CreateLucaAccountSwal(companyId,periodId)
                } else {
                  accountBankCode.current=newValue ?? null
                }
              }}
              noOptionsText={"Seçenek bulunamadı"}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                  const highlightedOption = event.target.querySelector(
                    'li[data-focus="true"]'
                  );
                  if (highlightedOption) {
                    highlightedOption.click();
                  }
                }
              }}
              autoHighlight
              selectOnFocus
              blurOnSelect={false}
              openOnFocus
              disablePortal
              ListboxProps={{ className: "specialListBox" }}
              getOptionLabel={(option) =>`${option.hesap_kodu} - ${option.hesap_adi}`

              }
              renderOption={(props, item) => (
                <li
                  {...props}
                  key={
                    item.key || item.value || item.hesap_kodu || item.hesap_adi
                  }
                >
                  <ListItemText>
                    {item.label || `${item.hesap_kodu} - ${item.hesap_adi}`}
                  </ListItemText>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Seçiniz"
                  variant="outlined"
                  size="small"
                />
              )}
              style={{ width: "100%" }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
                gap: 10,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor:
                    "#15356d" /* Onay butonunun rengini mavi yapar */,
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#112d5e",
                  },
                }}
                onClick={() => {
                  MySwal.clickConfirm();
                }}
                className="swal2-confirm swal2-styled"
              >
                Devam
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#d33",
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#a12929",
                  },
                }}
                onClick={() => MySwal.clickCancel()}
                className="swal2-cancel swal2-styled"
              >
                İptal
              </Button>
            </div>
          </div>
        ),
        customClass: {
          popup: "swal2-custom-autocomplete",
        },
        preConfirm: () => {
          if (!accountBankCode.current) {
            MySwal.showValidationMessage("Lütfen bir Luca hesap kodu seçiniz.");
            return false; // Bu, Swal penceresini açık tutar ve form onayını engeller
          }
          return accountBankCode.current;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            html: `<b>${selectedExtractIds.length}</b> adet ekstrenin Luca Hesap Kodu<br/> <b>${accountBankCode.current.hesap_kodu}-${accountBankCode.current.hesap_adi}</b> <br/> olarak güncellenecek.<br/> Emin misiniz?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Evet, güncelle!",
            cancelButtonText: "Hayır",
            focusCancel: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: "Ekstreler Güncelleniyor...",
                text: "Lütfen bekleyiniz",
                icon: "info",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                customClass: {
                  popup: "swal2-content-centered",
                },
                willOpen: () => {
                  Swal.showLoading();
                },
                didOpen: () => {
                  const swalPopup = document.querySelector(".swal2-container");
                  if (swalPopup) {
                    swalPopup.style.zIndex = "9999";
                  }
                },
              });
              let extractData = {
                lucaCurrentCode: accountBankCode.current.hesap_kodu,
                lucaCurrentName: accountBankCode.current.hesap_adi,
                status: 104,
              };
              updateBankExtracts(
                companyId,
                periodId,
                selectedExtractIds,
                extractData
              )
                .then((resp) => {
                  Swal.close();
                  setUpdateRow(true);
                  Swal.fire({
                    title: "Ekstreler güncellendi!",
                    icon: "success",
                    confirmButtonText: "Tamam",
                  });
                })
                .catch((err) => {
                  Swal.close();
                  Swal.fire({
                    html: `Luca Hesabı güncellenirken bir hata oluştu !<br>Lütfen tekrar deneyiniz.<br>`,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                });
            }
          });
        }
      });

      handleClose();
    }
  }, [open, handleClose, selectedExtractIds]);

  return null;
};
