import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Login from "./pages/loginPage/index.js";
import ForgotPassword from "./pages/forgotPasswordPage/index.js";
import Register from "./pages/registerPage/index.js";
import DashBoard from "./pages/dashboardPage/index.js";
import Invoices from "./pages/invoices/index.js";
import Fiches from "./pages/fiches/index.js";
import IncomeExpenseFiches from "./pages/incomeExpenseFiches/index.js";
import Extracts from "./pages/extracts/index.js";
import ProfilePage from "./pages/profilePage/userProfile.js";
import AdminPage from "./pages/adminPage/adminPage.js";
import AdminAdvisors from "./pages/adminPage/adminAdvisors/index.js";
import "./App.css";
import { jwtDecode } from "jwt-decode";

import { cookies } from "./utils/cookie";
import InvoiceSettingsPage from "./pages/SettingsPage/invoiceSettings/index.js";
import { BankExtractsSettingsPage } from "./pages/SettingsPage/bankExtractsSettings/index.js";
import { MaintenancePage } from "./pages/maintenancePage/index.js";
import { NotFound } from "./pages/notFoundPage/index.js";
import ForbiddenPage from "./pages/forbiddenPage/index.js";
import LandingPage from "./pages/landingPage/index.js";
import Reports from "./pages/reports/index.js";
import { IncomeExpenseFicheSettings } from "./pages/SettingsPage/incomeExpenseFicheSettings/index.js";

function AppRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const checkAuth = async () => {
      const jwtToken = cookies.get("token");
      if (
        window.location.hostname === process.env.REACT_APP_LANDING_PAGE_DOMAIN
      ) {
        navigate("/");
      } else {
        if (jwtToken) {
          const decodedToken = jwtDecode(jwtToken);
          const userRole = decodedToken.role;
          if (
            userRole === "superAdmin" &&
            !location.pathname.startsWith("/admin") &&
            !["/register", "/forgot-password", "/login"].includes(
              location.pathname
            )
          ) {
            navigate("/forbidden");
          } else if (
            userRole === "user" &&
            location.pathname.startsWith("/admin")
          ) {
            navigate("/forbidden");
          } else if (
            userRole === "superAdmin" &&
            ["/register", "/forgot-password", "/login"].includes(
              location.pathname
            )
          ) {
            navigate("/admin");
          } else if (
            userRole === "user" &&
            (["/register", "/forgot-password", "/login"].includes(
              location.pathname
            ) ||
              location.pathname === "/")
          ) {
            navigate("/dashboard");
          }
        } else if (!jwtToken) {
          if (
            ![
              "/register",
              "/forgot-password",
              "/login",
              "/maintenance",
            ].includes(location.pathname)
          ) {
            navigate("/login");
          }
        }
      }
      setIsLoading(false);
    };

    checkAuth();
  }, [navigate, location]);

  if (isLoading) {
    return <div>...</div>; // veya bir yükleme spinner'ı
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          window.location.hostname ===
          process.env.REACT_APP_LANDING_PAGE_DOMAIN ? (
            <LandingPage />
          ) : (
            <Navigate to="/dashboard" replace />
          )
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/register" element={<Register />} />

      <Route path="/user/profile" element={<ProfilePage />} />
      <Route
        path="/company/:companyId/:periodId/invoices"
        element={<Invoices />}
      />
      <Route path="/company/:companyId/:periodId/fiches" element={<Fiches />} />
      <Route
        path="/company/:companyId/:periodId/extracts"
        element={<Extracts />}
      />
      <Route
        path="/company/:companyId/:periodId/reports"
        element={<Reports />}
      />
      <Route
        path="/company/:companyId/:periodId/income-expense-fiches"
        element={<IncomeExpenseFiches />}
      />
      <Route
        path="/company/:companyId/:periodId/invoice-settings"
        element={<InvoiceSettingsPage />}
      />
      <Route
        path="/company/:companyId/:periodId/bank-extract-settings"
        element={<BankExtractsSettingsPage />}
      />
      <Route
        path="/company/:companyId/:periodId/income-expense-fiche-settings"
        element={<IncomeExpenseFicheSettings />}
      />

      <Route path="/dashboard" element={<DashBoard />} />

      <Route path="/admin" element={<AdminPage />} />
      <Route path="/admin/advisors" element={<AdminAdvisors />} />
      <Route path="/maintenance" element={<MaintenancePage />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/forbidden" element={<ForbiddenPage />} />
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default App;
