/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

//todo MODAL AÇILMASI GEREKTİREN İŞLEMLER SONU MODAL İLE BİTEN DOSYALARDA(fatura seçilerek yapılan işlemlerde),
//todo DİREKT İSTEKLE HALLEDİLEBİLİR İŞLEMLER 'ProcessesAndProcessMenu' DOSYASINDADIR.
import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { createFilterOptions } from "@mui/material/Autocomplete";
// Grids---------
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import gridSideBar from "../../../components/GridSideBar/gridSideBar.js";

import { createColumnDefs } from "./gridOptionsAndcolumnDefs.js";
import localeTextTr from "../../../locale.tr.js";

//Components
import Sidebar from "../../../components/sideBar/sideBar.js";
import Navbar from "../../../components/navbar/navbar.js";
import localStorage from "local-storage";
import { getJsonItem } from "../../../utils/localStorageProcess/index.js";
import { setJsonItem } from "../../../utils/localStorageProcess/index.js";
//Icons
import { IoReturnDownForwardOutline } from "react-icons/io5";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { TbArrowsExchange2, TbTrash } from "react-icons/tb";
import {
  Grid,
  Typography,
  Button,
  Box,
  TextField,
  Autocomplete,
  Checkbox,
  Divider,
  IconButton,
  ListItemText,
} from "@mui/material";
//Icons

//Apis
import { getCompanyDetails } from "../../../api/advisor/getCompanyDetails/index.js";
import { FaRegSave, FaRegTrashAlt } from "react-icons/fa";
import { createBlackListColumnDefs } from "./blackListGridOptionsAndcolumnDefs.js";

import { createRuleTableColumnDefs } from "./ruleTableGridOptionsAndcolumnDefs.js";
import { RemoveBlackListSwal } from "./removeBlackListSwal.js";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { RiDragMove2Fill } from "react-icons/ri";
import { getValueFromServer } from "../../../api/company/invoices/getValueFromServer/index.js";
import {
  createBalanceRuleStringForBackend,
  createBusinessRuleStringForBackend,
  parseBalanceRuleConditionStringForFrontend,
  parseBusinessRuleConditionStringForFrontend,
} from "../../../utils/ruleUtils/index.js";
import { saveSettingDetails } from "../../../api/company/invoiceSettings/saveSettingDetails/index.js";
import { getLucaAccounts } from "../../../api/company/invoices/getLucaAccounts/index.js";
import { CopyRuleToCompanySwal } from "./copyRuleToCompanySwal.js";
import { AddSpecialRule } from "../../../components/addSpecialRule/index.js";
import { showLoadingSwal } from "../../../components/loadingSwal/index.js";
import { removeBlackList } from "../../../api/company/invoices/blackList/index.js";
import { setTitle } from "../../../utils/setTitle/index.js";
import { CreateLucaAccountSwal } from "../../../components/createLucaAccountSwal/index.js";
import { createTurkishFilterParams } from "../../../utils/agGridTextFilterTr/agGridFilterUtils.js";
//Apis

function InvoiceSettingsPage() {
  const [rules, setRules] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);

  const options = (data) => {
    const existingOption = lucaAccountCodesRef.current.find(
      (option) => option.hesap_kodu === data
    );

    if (existingOption) {
      // Eğer `data` mevcutsa, mevcut listeyi döndür
      return lucaAccountCodesRef.current;
    } else {
      // Eğer `data` mevcut değilse, yeni öğeyi ekle ve güncellenmiş listeyi döndür
      return [
        ...lucaAccountCodesRef.current,
        {
          hesap_kodu: data,
          hesap_adi: data,
        },
      ];
    }
  };
  const getOptionLabel = (option) => {
    if (option.hesap_kodu === "add-new") {
      return `${option.hesap_adi}`;
    } else if (option.id || option?.hesap_kodu === "special") {
      return `${option.hesap_kodu} - ${option.hesap_adi}`;
    } else {
      let flag = true;
      option.hesap_kodu.split(".").forEach((item) => {
        item.split("").forEach((i) => {
          if (![0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(Number(i))) {
            flag = false;
          }
        });
      });
      return flag
        ? ""
        : option.hesap_kodu.startsWith('"')
        ? `${option.hesap_kodu}`
        : `"${option.hesap_kodu}"`;
    }
  };

  const handleAddRule = (process) => {
    if (process === "end") {
      if (companyDetailsRef.current.class === 1) {
        setRules([
          ...rules,
          {
            index: rules.length,
            direction: directionOptions1[1].value,
            field: fieldBalanceOptions[0].value,
            process: processOptions[2].value,
            input: "",
            return: [
              {
                hesap_kodu: lucaAccountCodesRef.current[0].hesap_kodu,
                borc: debtAndReceivableOptions[0].value,
                alacak: debtAndReceivableOptions[0].value,
              },
            ],
            note: "",
            subRules: [],
          },
        ]);
      } else {
        setRules([
          ...rules,
          {
            index: rules.length,
            direction: directionOptions[1].value,
            field: fieldBusinessOptions[0].value,
            process: processOptions[2].value,
            input: "",
            return: outgoingOptions[0].value,
            note: "",
            subRules: [],
          },
        ]);
      }
    } else {
      if (companyDetailsRef.current.class === 1) {
        setRules([
          {
            index: rules.length,
            direction: directionOptions1[1].value,
            field: fieldBalanceOptions[0].value,
            process: processOptions[2].value,
            input: "",
            return: [
              {
                hesap_kodu: lucaAccountCodesRef.current[0].hesap_kodu,
                borc: debtAndReceivableOptions[0].value,
                alacak: debtAndReceivableOptions[0].value,
              },
            ],
            note: "",
            subRules: [],
          },
          ...rules,
        ]);
      } else {
        setRules([
          {
            index: rules.length,
            direction: directionOptions[1].value,
            field: fieldBusinessOptions[0].value,
            process: processOptions[2].value,
            input: "",
            return: outgoingOptions[0].value,
            note: "",
            subRules: [],
          },
          ...rules,
        ]);
      }
    }
  };

  const handleAddSubRule = (ruleIndex, returnSubIndex = null) => {
    if (returnSubIndex !== null) {
      const newRules = rules.map((rule) => {
        if (rule.index === ruleIndex) {
          return {
            ...rule,
            return: [
              ...rule.return,
              {
                subIndex: returnSubIndex,
                hesap_kodu: lucaAccountCodesRef.current[0].hesap_kodu,
                borc: "0",
                alacak: "0",
              },
            ],
          };
        }
        return rule;
      });

      setRules(newRules);
    } else {
      const newRules = rules.map((rule) => {
        if (rule.index === ruleIndex) {
          return {
            ...rule,
            subRules: [
              ...rule.subRules,
              {
                index: ruleIndex,
                subIndex: rule.subRules.length,
                field:
                  companyDetailsRef.current.class === 1
                    ? fieldBalanceOptions[0].value
                    : fieldBusinessOptions[0].value,
                process: processOptions[2].value,
                input: "",
              },
            ],
          };
        }
        return rule;
      });
      setRules(newRules);
    }
  };

  const handleRemoveRule = (index, subIndex = null, returnIndex = null) => {
    if (returnIndex !== null) {
      // Belirli bir return öğesini silme
      const newRules = rules.map((rule) => {
        if (rule.index === index) {
          return {
            ...rule,
            return: rule.return.filter((ret) => ret.subIndex !== returnIndex),
          };
        }
        return rule;
      });
      setRules(newRules);
    } else if (subIndex === null) {
      // Ana kuralı silme
      const newRules = rules.filter((rule) => rule.index !== index);
      setRules(newRules);
    } else {
      // Alt kuralı silme
      const newRules = rules.map((rule) => {
        if (rule.index === index) {
          return {
            ...rule,
            subRules: rule.subRules.filter(
              (subRule) => subRule.subIndex !== subIndex
            ),
          };
        }
        return rule;
      });
      setRules(newRules);
    }
  };

  const handleRuleChange = (
    index,
    field,
    value,
    subIndex = null,
    subField = null,
    subValue = null,
    returnField = null,
    returnValue = null
  ) => {
    const newRules = rules.map((rule) => {
      if (rule.index === index) {
        if (returnField !== null) {
          const updatedReturn = rule.return.map((ret, retIndex) => {
            if (retIndex === subIndex) {
              return { ...ret, [returnField]: returnValue };
            }

            return ret;
          });
          return { ...rule, return: updatedReturn };
        } else if (subIndex !== null && subField !== null) {
          if (typeof subField === "object") {
            const updatedSubRules = rule.subRules.map((subRule) => {
              if (subRule.subIndex === subIndex) {
                return { ...subRule, ...subField };
              }
              return subRule;
            });
            return { ...rule, subRules: updatedSubRules };
          } else {
            // `subField` bir nesne değilse
            const updatedSubRules = rule.subRules.map((subRule) => {
              if (subRule.subIndex === subIndex) {
                return { ...subRule, [subField]: subValue };
              }
              return subRule;
            });
            return { ...rule, subRules: updatedSubRules };
          }
        } else if (typeof field === "object") {
          return { ...rule, ...field };
        } else {
          return { ...rule, [field]: value };
        }
      }
      return rule;
    });
    setRules(newRules);
  };

  const handleRuleReorder = (result) => {
    if (!result.destination) return;
    const newRules = Array.from(rules);
    const [reorderedItem] = newRules.splice(result.source.index, 1);
    newRules.splice(result.destination.index, 0, reorderedItem);
    setRules(newRules.map((rule, index) => ({ ...rule, index })));
  };

  const directionOptions = [
    { value: "2", label: "Gelen" },
    { value: "1", label: "Giden" },
  ];
  const directionOptions1 = [
    { value: "in", label: "Gelen" },
    { value: "out", label: "Giden" },
  ];
  const invoiceProfiles = [
    { value: "TICARIFATURA", label: "Ticari Fatura" },
    { value: "TEMELFATURA", label: "Temel Fatura" },
    { value: "EARSIVFATURA", label: "E-arşiv Fatura" },
    { value: "KAMU", label: "Kamu Fatura" },
    { value: "IHRACAT", label: "İhracat Fatura" },
  ];
  const invoiceTypes = [
    { value: "SATIS", label: "Satış Faturası", selected: true },
    { value: "IADE", label: "İade Faturası" },
    { value: "ISTISNA", label: "İstisna Faturası" },
    { value: "TEVKIFAT", label: "Tevkifatlı Fatura" },
    { value: "TEVKIFATIADE", label: "Tevkifatlı İade Fatura" },
    { value: "SGK", label: "SGK Faturası" },
    { value: "OZELMATRAH", label: "Özel Matrah" },
    { value: "IHRACKAYITLI", label: "İhraç Kayıtlı" },
  ];
  const systemLineTypes = [
    { value: "payable", label: "Ödenecek" },
    { value: "taxable", label: "Matrah" },
    { value: "tax", label: "Vergi Tutarı" },
  ];

  const processOptions = [
    { value: "includes", label: "İçeriyorsa" },
    { value: "notIncludes", label: "İçermiyorsa" },
    { value: "==", label: "Eşitse" },
    { value: "!=", label: "Eşit değilse" },
    { value: "startsWith", label: "Başlıyorsa" },
    { value: "endsWith", label: "Bitiyorsa" },
  ];

  const fieldBalanceOptions = [
    {
      value: "invoice.sender_object.vkn_tckn",
      label: "Gönderici VKN/TCKN",
    },
    { value: "invoice.receiver_object.vkn_tckn", label: "Alıcı VKN/TCKN" },
    { value: "invoice.profile_id", label: "Fatura Profili" },
    { value: "invoice.type_code", label: "Fatura Tipi" },
    { value: "invoice.payable_amount", label: "Fatura Tutar" },
    { value: "invoice.number", label: "Fatura Numarası" },
    { value: "line?.name", label: "Satır Mal/Hizmet Adı" },
    { value: "line.code", label: "Satır Vergi Kodu" },
    { value: "line.type", label: "Sistem Satır Tipi" },
    { value: "line.amount", label: "Satır Tutarı" },
    { value: "line.percent", label: "Satır Vergi Oranı" },
    { value: "line.code.List", label: "Satır Vergi Kodu (Liste)" },
    { value: "invoice.notes", label: "Fatura Notu (Liste)" },
    {
      value: "invoice.sender_object.vkn_tckn.List",
      label: "Gönderici VKN/TCKN (Liste)",
    },
    {
      value: "invoice.receiver_object.vkn_tckn.List",
      label: "Alıcı VKN/TCKN (Liste)",
    },
    { value: "line?.name.List", label: "Satır Mal/Hizmet Adı (Liste)" },
    {
      value: "invoice.accounting_lines.code.List",
      label: "Faturadaki Vergi Kodları (Liste)",
    },
  ];
  const fieldBusinessOptions = [
    {
      value: "invoice.sender_tax",
      label: "Gönderici VKN/TCKN",
    },
    { value: "invoice.receiver_tax", label: "Alıcı VKN/TCKN" },
    { value: "line?.name", label: "Satır Mal/Hizmet Adı" },
    {
      value: "invoice.sender_tax.List",
      label: "Gönderici VKN/TCKN (Liste)",
    },
    { value: "invoice.receiver_tax.List", label: "Alıcı VKN/TCKN (Liste)" },
    { value: "line?.name.List", label: "Satır Mal/Hizmet Adı (Liste)" },
  ];

  const [debtAndReceivableOptions, setDebtAndReceivableOptions] = useState([
    { value: "0", label: "0" },
    { value: "line.amount", label: "Satır Tutarı" },
    { value: "special", label: "Özel Kural" },
  ]);

  const [values, setValues] = useState({
    newAccountOutgoing: "",
    outgoingAccountType: "",
    newAccountIncoming: "",
    incomingAccountType: "",
    completeAlacakHesap: "",
    completeBorcHesap: "",
    completeFarkHesap: "",
    ficheSplitDay: "",
    ficheTopLines: "",
    applyFrontendRules: false,
    automaticallyCreateFiches: false,
    fetchAutomaticallyInvoices: false,
    automaticallySendFichesToLuca: false,
  });
  const [completeBorcHesap, setCompleteBorcHesap] = useState("");
  const [completeAlacakHesap, setCompleteAlacakHesap] = useState("");

  const handleChange = (field) => (event) => {
    setValues({
      ...values,
      [field]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const accountOptions = [
    { label: "Ana Hesabını Takip Et", value: "follow" },
    { label: "Hesabına At", value: "direct" },
  ];
  const debitCreditOptions = [
    { label: "Matraha İşle", value: "taxable" },
    { label: "Hesabına At", value: "account" },
  ];

  const [isOpen, setIsOpen] = useState(false);

  //modals açılma durumlar
  const [modalId, setModalId] = useState("1");
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  //--------

  const [companyDetails, setCompanyDetails] = useState();
  const companyDetailsRef = useRef(companyDetails);

  const [loading, setLoading] = useState(true);
  const [firstLoading, setFirstLoading] = useState(true); //SAYFA İLK AÇTIĞINDA getListInvoices ve companies'e İSTEK ATMAMASI İÇİN FLAG

  const location = useLocation();
  const navigate = useNavigate();

  const [rowData, setRowData] = useState();
  const gridRef = useRef(null);
  const lucaAccountCodesRef = useRef();
  const [blackListData, setBlackListData] = useState();
  const blackListDataGridRef = useRef(null);
  const [ruleTableData, setRuleTableData] = useState();
  const [originalInvoiceRules, setOriginalInvoiceRules] = useState();
  const ruleTableDataGridRef = useRef(null);

  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [incomingOptions, setIncomingOptions] = useState([]);
  const [outgoingOptions, setOutgoingOptions] = useState([]);

  useEffect(() => {
    const sideBarOpen = localStorage.get("sidebar");
    if (sideBarOpen === "false") {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
    let locationSplit = location.pathname.split("/");
    let companyDetailsControl = getJsonItem(
      `#${locationSplit[2]}/${locationSplit[3]}`
    );
    setCompanyDetails(companyDetailsControl);

    companyDetailsRef.current = companyDetailsControl;

    if (!companyDetailsControl?.companyId) {
      getCompanyDetails(locationSplit[2], locationSplit[3])
        .then(async (resp) => {
          if (resp.data.companyId) {
            await setJsonItem(
              `#${locationSplit[2]}/${locationSplit[3]}`,
              resp.data
            );
            window.location.reload();
          } else {
            navigate("/dashboard", { replace: true });
          }
        })
        .catch((err) => {
          navigate("/dashboard", { replace: true });
        });
    } else {
      setTitle(companyDetailsControl?.name, locationSplit[4]);
      if (companyDetailsControl.class === 1) {
        Promise.all([
          getCompanyDetails(locationSplit[2], locationSplit[3]),
          getLucaAccounts(locationSplit[2], locationSplit[3]),
        ])
          .then(([companyResp, lucaAccountResp]) => {
            const updateData = lucaAccountResp.data.filter(
              (item, index, self) =>
                index === self.findIndex((t) => t.id === item.id)
            );
            updateData.push({
              hesap_adi: "Özel Kural Ekle",
              hesap_kodu: "special",
            });
            lucaAccountCodesRef.current = updateData;

            // getCompanyDetails işlemi

            setValues((prevValues) => ({
              ...prevValues,
              newAccountOutgoing: companyResp.data.newAccountOutgoing || "",
              outgoingAccountType: companyResp.data.outgoingAccountType || "",
              newAccountIncoming: companyResp.data.newAccountIncoming || "",
              incomingAccountType: companyResp.data.incomingAccountType || "",
              completeAlacakHesap: companyResp.data.completeAlacakHesap || "",
              completeBorcHesap: companyResp.data.completeBorcHesap || "",
              completeFarkHesap: companyResp.data.completeFarkHesap || "",
              ficheSplitDay: companyResp.data.ficheSplitDay || "",
              ficheTopLines: companyResp.data.ficheTopLines || "",
              applyFrontendRules:
                companyResp.data.preferences.applyFrontendRules || false,
              automaticallyCreateFiches:
                companyResp.data.preferences.automaticallyCreateFiches || false,
              fetchAutomaticallyInvoices:
                companyResp.data.preferences.fetchAutomaticallyInvoices ||
                false,
              automaticallySendFichesToLuca:
                companyResp.data.preferences.automaticallySendFichesToLuca ||
                false,
            }));

            let ruleList = [];
            let subRuleList = [];
            setCompleteBorcHesap(
              companyResp.data.completeBorcHesap === "taxable"
                ? ""
                : companyResp.data.completeBorcHesap
            );
            setCompleteAlacakHesap(
              companyResp.data.completeAlacakHesap === "taxable"
                ? ""
                : companyResp.data.completeBorcHesap
            );
            ruleList = [];
            companyResp.data.invoiceRules.map((rule, index) => {
              if (rule.type !== "tableRules") {
                const direction = rule.condition
                  .substring(
                    rule.condition.indexOf("invoice.direction ==") + 20,
                    rule.condition.indexOf("&&")
                  )
                  ?.replace(/\"/g, "")
                  .trim();
                const { field, process, input } =
                  parseBalanceRuleConditionStringForFrontend(
                    rule.condition.split("&&")[1]
                  );
                let accountCode = rule.return[0].hesap_kodu;
                if (
                  !accountCode?.split(".").find((item) => Number(item) === NaN)
                ) {
                  accountCode = accountCode?.replace(/\"/g, "");
                }

                let mainReturn = [
                  {
                    hesap_kodu: accountCode,
                    borc: rule.return[0].borc,
                    alacak: rule.return[0].alacak,
                  },
                ];

                subRuleList = rule.condition
                  .split("&&")
                  .slice(2)
                  .map((subRule, subIndex) => {
                    const { field, process, input } =
                      parseBalanceRuleConditionStringForFrontend(subRule);
                    let accountCode = rule.return?.[subIndex + 1]?.hesap_kodu;
                    if (
                      !accountCode
                        ?.split(".")
                        .find((item) => Number(item) === NaN)
                    ) {
                      accountCode = accountCode?.replace(/\"/g, "");
                    }
                    if (
                      accountCode ||
                      rule.return?.[subIndex + 1]?.borc ||
                      rule.return?.[subIndex + 1]?.alacak
                    ) {
                      let subReturn = {
                        subIndex,
                        hesap_kodu: accountCode,
                        borc: rule.return?.[subIndex + 1]?.borc,
                        alacak: rule.return?.[subIndex + 1]?.alacak,
                      };
                      mainReturn.push(subReturn);
                    }

                    return {
                      index,
                      subIndex,
                      field,
                      process,
                      input,
                    };
                  });

                ruleList.push({
                  index,
                  direction,
                  field,
                  process,
                  input,
                  return: mainReturn,
                  note: rule?.note,
                  subRules: subRuleList,
                });
              }
            });

            setRules(ruleList);
            let tableRules = companyResp.data.invoiceRules.filter(
              (rule) => rule.type == "tableRules"
            );
            tableRules = tableRules.map((rule) => {
              return {
                id: rule.id,
                note: rule.note,
                hesap_kodu: rule.return[0].hesap_kodu?.substring(
                  1,
                  rule.return[0].hesap_kodu.length - 1
                ),
                isDefaultRule: rule.isDefaultRule,
                condition: rule.condition,
                direction: rule?.direction,
                vkn_tckn: rule?.condition.includes("&& [")
                  ? `${rule?.condition
                      .substring(
                        rule?.condition.indexOf("&& [") + 4,
                        rule?.condition.indexOf("].find")
                      )
                      .trim()
                      .split(",")
                      .map((item) => item.substring(1, item.length - 1))}`
                  : undefined,
              };
            });
            setOriginalInvoiceRules(companyResp.data.invoiceRules);
            setRuleTableData(tableRules.sort((a, b) => b.id - a.id));
            setRowData(companyResp.data.integrators);
            setBlackListData(companyResp.data.blackList);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      } else if (companyDetailsControl.class === 2) {
        Promise.all([
          getValueFromServer(locationSplit[2], locationSplit[3]),
          getCompanyDetails(locationSplit[2], locationSplit[3]),
        ])
          .then(([valueResp, companyResp]) => {
            // getValueFromServer işlemi
            let incomingOptionss = [];
            let outgoingOptionss = [];
            valueResp.forEach((subRecord) => {
              if (subRecord.direction === 2) {
                incomingOptionss.push({
                  value: `${subRecord.giderKayitTuruKodu}-${subRecord.value}`,
                  label: subRecord.label,
                });
              }
              if (subRecord.direction === 1) {
                outgoingOptionss.push({
                  value: `${subRecord.gelirKayitTuruKodu}-${subRecord.value}`,
                  label: subRecord.label,
                });
              }
            });

            setOutgoingOptions(outgoingOptionss);
            setIncomingOptions(incomingOptionss);

            // getCompanyDetails işlemi
            setValues((prevValues) => ({
              ...prevValues,
              newAccountOutgoing: companyResp.data.newAccountOutgoing || "",
              outgoingAccountType: companyResp.data.outgoingAccountType || "",
              newAccountIncoming: companyResp.data.newAccountIncoming || "",
              incomingAccountType: companyResp.data.incomingAccountType || "",
              completeAlacakHesap: companyResp.data.completeAlacakHesap || "",
              completeBorcHesap: companyResp.data.completeBorcHesap || "",
              completeFarkHesap: companyResp.data.completeFarkHesap || "",
              ficheSplitDay: companyResp.data.ficheSplitDay || "",
              ficheTopLines: companyResp.data.ficheTopLines || "",
              applyFrontendRules:
                companyResp.data.preferences.applyFrontendRules || false,
              automaticallyCreateFiches:
                companyResp.data.preferences.automaticallyCreateFiches || false,
              fetchAutomaticallyInvoices:
                companyResp.data.preferences.fetchAutomaticallyInvoices ||
                false,
              automaticallySendFichesToLuca:
                companyResp.data.preferences.automaticallySendFichesToLuca ||
                false,
            }));

            let ruleList = [];
            let subRuleList = [];

            ruleList = companyResp.data.invoiceRules.map((rule, index) => {
              const direction = rule.condition
                .substring(
                  rule.condition.indexOf("invoice.direction ==") + 20,
                  rule.condition.indexOf("&&")
                )
                ?.trim();
              let note = rule.note;
              const { field, process, input } =
                parseBusinessRuleConditionStringForFrontend(
                  rule.condition.split("&&")[1]
                );

              subRuleList = rule.condition
                .split("&&")
                .slice(2)
                .map((subRule, subIndex) => {
                  const { field, process, input } =
                    parseBusinessRuleConditionStringForFrontend(subRule);
                  return {
                    index,
                    subIndex,
                    field,
                    process,
                    input,
                  };
                });

              return {
                index,
                direction,
                field,
                process,
                input,
                return: rule.return,
                note,
                subRules: subRuleList,
              };
            });
            setRules(ruleList);
            setRowData(companyResp.data.integrators);
            setBlackListData(companyResp.data.blackList);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    }
  }, []);

  const onSelectionChangedBlackList = () => {
    const selectedRows = blackListDataGridRef.current.api.getSelectedRows();
    setSelectedInvoices(selectedRows);
  };

  const handleDeleteBlackListButtonClick = () => {
    const selectedRows = blackListDataGridRef.current.api.getSelectedRows();
    if (selectedRows.length === 0) {
      Swal.fire({
        title: "Uyarı",
        text: "Lütfen en az 1 fatura seçin.",
        icon: "error",
        confirmButtonText: "Tamam",
      });
      return;
    }

    Swal.fire({
      title: "Firma Silme",
      html: `Seçili firmaları kara listeden silmek istediğinize emin misiniz?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Evet, ekle!",
      cancelButtonText: "Hayır",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Faturalar kara listeden kaldırılıyor...",
          text: "Lütfen bekleyiniz...",
          icon: "info",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          customClass: {
            popup: "swal2-content-centered",
          },
          willOpen: () => {
            Swal.showLoading();
          },
        });

        removeBlackList(
          companyDetailsRef?.current?.companyId,
          companyDetailsRef?.current?.periodId,
          selectedInvoices
        )
          .then((resp) => {
            blackListDataGridRef.current.api.applyTransaction({
              remove: selectedRows,
            });
            Swal.fire({
              title: resp.data.message,
              icon: "success",
            });
          })
          .catch((err) => {
            Swal.fire({
              title: "Bir hata oluştu!",
              text: err.response.data.message,
              icon: "error",
              confirmButtonText: "Tamam",
            });
          });
      }
    });
  };
  const handleDeleteIntegratorButtonClick = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    if (selectedRows?.length === 0) {
      Swal.fire({
        title: "Lütfen bir satır seçiniz!",
        icon: "info",
        confirmButtonText: "Tamam",
      });
      return;
    }
    const integrators = selectedRows
      .map((x) => x.integrator.toUpperCase())
      .join(", ");
    Swal.fire({
      title: "Entegratör Silme",
      text: `${integrators} entegratörlerini silmek istediğinize emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet, Sil!",
      cancelButtonText: "Vazgeç",
    }).then((result) => {
      if (result.isConfirmed) {
        gridRef.current.api.applyTransaction({ remove: selectedRows });
        Swal.fire(`Kaydetmeyi unutmayın!`, "", "warning");
      }
    });
  };
  const handleDeleteRuleButtonClick = () => {
    const selectedRows = ruleTableDataGridRef.current.api.getSelectedRows();
    if (selectedRows?.length === 0) {
      Swal.fire({
        title: "Lütfen bir satır seçiniz!",
        icon: "info",
        confirmButtonText: "Tamam",
      });
      return;
    }
    Swal.fire({
      title: "Kural Silme",
      text: `Seçilen kuralları silmek istediğinize emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Evet, Sil!",
      cancelButtonText: "Vazgeç",
    }).then((result) => {
      if (result.isConfirmed) {
        ruleTableDataGridRef.current.api.applyTransaction({
          remove: selectedRows,
        });
        Swal.fire({
          title: "Kaydetmeyi unutmayın!",
          icon: "warning",
          confirmButtonText: "Tamam",
        });
      }
    });
  };
  const getRowId = useCallback((params) => {
    return params.data.id;
  }, []);

  const ItemSelectorCellEditor = (props) => {
    let groupedOptions = [
      {
        group: "Luca Hesapları",
        options: lucaAccountCodesRef.current,
      },
    ];

    const options = groupedOptions
      .flatMap((group) => group.options)
      .filter((item) => item);

    const updateData = useCallback(
      async (newDataAccountCode) => {
        let id = await props.data?.id;
        if (id) {
          const rowNode = props.api.getRowNode(id);
          if (rowNode) {
            await rowNode.setDataValue("hesap_kodu", newDataAccountCode || "");
          }
        }
      },
      [props.api, props.data]
    );

    const handleChange = (event, newValue) => {
      if (newValue?.hesap_kodu === "add-new") {
        CreateLucaAccountSwal(
          companyDetails?.companyId,
          companyDetails?.periodId
        );
        return;
      }
      updateData(newValue?.hesap_kodu);
      props.stopEditing();
    };
    const [open, setOpen] = useState(true);

    return (
      <Autocomplete
        style={{ height: "20px" }}
        value={
          options.find((option) => option?.hesap_kodu === props?.value) || null
        }
        onChange={handleChange}
        options={options}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        noOptionsText={"Seçenek bulunamadı"}
        groupBy={(option) => {
          const group = groupedOptions.find((g) => g.options.includes(option));
          return group ? group.group : "";
        }}
        autoHighlight
        selectOnFocus
        blurOnSelect
        openOnFocus
        filterOptions={(options, params) => {
          const filtered = options.filter((option) => {
            let label = `${option.hesap_kodu} ${option.hesap_adi}`;
            return label
              .toLocaleLowerCase("tr")
              .includes(params?.inputValue?.toLocaleLowerCase("tr"));
          });
          // Girilen metin, mevcut seçenekler arasında yoksa özel bir seçenek ekle
          if (filtered?.length === 0) {
            filtered.push({
              hesap_adi: "Yeni Hesap Kodu Eklemek İçin Tıklayın",
              hesap_kodu: "add-new", // Özel seçenek için belirleyici bir value
            });
          }

          return filtered;
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
            const highlightedOption = event.target.querySelector(
              'li[data-focus="true"]'
            );
            if (highlightedOption) {
              highlightedOption.click();
            }
          }
        }}
        getOptionLabel={(option) => {
          if (option?.hesap_kodu === "add-new") {
            return `${option.adi || ""}`;
          }
          return option
            ? `${option.hesap_kodu || ""} - ${
                option.hesap_adi || ""
              } - Bakiye: ${option.bakiye || ""}`
            : "";
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            variant="outlined"
            autoFocus={true}
          />
        )}
        renderGroup={(params) => (
          <Box
            key={params.key}
            sx={{
              height: "200px",
              display: "flex",
            }}
          >
            <li key={params.key} sx={{ display: "inline" }}>
              <Typography
                variant="body1"
                style={{
                  fontWeight: "bold",
                  backgroundColor: "#f0f0f0",
                  padding: "5px",
                }}
              >
                {params.group}
              </Typography>
              <ul
                style={{
                  padding: 0,
                  margin: 0,
                  listStyle: "none",
                  whiteSpace: "nowrap",
                  flexWrap: "nowrap",
                }}
              >
                {params.children}
              </ul>
            </li>
          </Box>
        )}
        renderOption={(props, option) => (
          <Box
            component="li"
            {...props}
            key={option?.hesap_kodu || ""}
            style={{ paddingLeft: "7px", maxWidth: "100%" }}
          >
            <Typography>
              {option.hesap_kodu === "add-new" ? (
                <>{option.hesap_adi}</>
              ) : (
                <>
                  <strong>{option.hesap_kodu}</strong> - {option.hesap_adi} -
                  Bakiye: {option.bakiye}
                </>
              )}
            </Typography>
          </Box>
        )}
      />
    );
  };

  const handleSaveButtonClick = async () => {
    let hasEmptyFields = false;
    let invoiceRules = [];
    showLoadingSwal("Ayarlar kaydediliyor...");
    let controlledRules = rules.map((rule) => {
      if (companyDetailsRef?.current?.class === 1) {
        if (!rule.input) {
          hasEmptyFields = true;
        }
        let controlledReturns = rule.return.map((returnItem) => {
          let isAccount = lucaAccountCodesRef.current.find(
            (option) => option.hesap_kodu === returnItem.hesap_kodu
          );

          if (!isAccount) {
            let flag = true;
            returnItem.hesap_kodu.split(".").forEach((item) => {
              item.split("").forEach((i) => {
                if (![0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(Number(i))) {
                  flag = false;
                }
              });
            });

            if (flag) {
              hasEmptyFields = true;
              return {
                ...returnItem,
                hesap_kodu: "",
              };
            }

            return returnItem;
          } else {
            return returnItem;
          }
        });
        for (const subRule of rule.subRules) {
          if (!subRule.field || !subRule.process || !subRule.input) {
            hasEmptyFields = true;
          }
        }
        return { ...rule, return: controlledReturns };
      } else {
        if (
          !rule.direction ||
          !rule.field ||
          !rule.process ||
          !rule.input ||
          !rule.return ||
          !rule.note
        ) {
          hasEmptyFields = true;
        }
      }
      for (const subRule of rule.subRules) {
        if (!subRule.field || !subRule.process || !subRule.input) {
          hasEmptyFields = true;
        }
      }
    });
    if (hasEmptyFields) {
      setRules(controlledRules);
      setIsEmpty(true);
      Swal.fire({
        title: "Hata",
        text: "Lütfen boş alanları doldurun.",
        icon: "error",
        confirmButtonText: "Tamam",
      });
    } else {
      if (companyDetailsRef?.current?.class === 1) {
        for await (const item of rules) {
          const updatedReturn = item.return.map((subItem) => {
            let updatedAccountCode = subItem.hesap_kodu;
            let flag = true;

            subItem.hesap_kodu?.split(".").forEach((item) => {
              item.split("").forEach((item) => {
                if (![0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(Number(item))) {
                  flag = false;
                }
              });
            });

            if (flag) {
              updatedAccountCode = `"${subItem.hesap_kodu}"`;
            } else {
              updatedAccountCode = subItem.hesap_kodu;
            }

            let updatedDebt =
              subItem.borc &&
              subItem.borc !== "0" &&
              subItem.borc !== "line.amount"
                ? subItem.borc.substring(0, subItem.borc.length)
                : subItem.borc;
            let updatedReceivable =
              subItem.alacak &&
              subItem.alacak !== "0" &&
              subItem.alacak !== "line.amount"
                ? subItem.alacak.substring(0, subItem.alacak.length)
                : subItem.alacak;

            return {
              borc: updatedDebt,
              hesap_kodu: updatedAccountCode,
              alacak: updatedReceivable,
            };
          });

          let formatData = await createBalanceRuleStringForBackend(
            item.direction,
            item.process,
            item.field,
            item.input,
            item.subRules,
            item.note ? item.note : ""
          );
          const updatedFormatData = {
            ...formatData, // Mevcut objenin tüm özelliklerini korur
            return: updatedReturn, // Güncellenmiş return dizisini ekler
          };
          invoiceRules.push(updatedFormatData);
        }

        let tableRules = originalInvoiceRules.filter(
          (item) => item.type === "tableRules"
        );
        let updatedTableRules = [];
        let tableRulesFromGrid = [];
        ruleTableDataGridRef.current.api.forEachNode((node) =>
          tableRulesFromGrid.push({
            id: node.data.id,
            note: node.data.note,
            hesap_kodu: node.data.hesap_kodu,
            direction: node.data.direction,
            vkn_tckn: node.data.vkn_tckn || "",
            condition: node.data.condition,
          })
        );

        tableRulesFromGrid.forEach((item, index) => {
          const originalDataIndex = tableRules.findIndex(
            (i) => i.id === item.id
          );
          const originalData =
            originalDataIndex !== -1
              ? tableRules[originalDataIndex]
              : undefined;

          if (originalDataIndex !== -1) {
            if (
              originalData.condition.includes("find") &&
              originalData.condition.includes("[")
            ) {
              updatedTableRules.push({
                ...originalData,
                condition:
                  item.direction == "in"
                    ? `invoice.direction == "in" && [${item?.vkn_tckn
                        ?.split(",")
                        .filter((item2) => item2.trim() !== "")
                        .map(
                          (item3) => `"${item3.trim()}"`
                        )}].find(item=>String(item.trim())?.toLocaleLowerCase("tr") === String(invoice.sender_object.vkn_tckn)?.toLocaleLowerCase("tr")) && line.type == "taxable" && line.code == "0015"`
                    : item.direction == "out"
                    ? `invoice.direction == "out" && [${item?.vkn_tckn
                        ?.split(",")
                        .filter((item2) => item2.trim() !== "")
                        .map(
                          (item3) => `"${item3.trim()}"`
                        )}].find(item=>String(item.trim())?.toLocaleLowerCase("tr") === String(invoice.receiver_object.vkn_tckn)?.toLocaleLowerCase("tr")) && line.type == "taxable" && line.code == "0015"`
                    : originalData.condition.replace(
                        /\[.*?\]/,
                        `[${item?.vkn_tckn
                          ?.split(",")
                          .filter((item2) => item2.trim() !== "")
                          .map((item3) => `"${item3.trim()}"`)}]`
                      ),
                note: item.note,
                return: [
                  {
                    borc: originalData.return[0].borc,
                    alacak: originalData.return[0].alacak,
                    hesap_kodu: `"${item.hesap_kodu}"`,
                  },
                ],
              });
            } else {
              updatedTableRules.push({
                ...originalData,
                note: item.note,
                return: [
                  {
                    borc: originalData.return[0].borc,
                    alacak: originalData.return[0].alacak,
                    hesap_kodu: `"${item.hesap_kodu}"`,
                  },
                ],
              });
            }
          } else {
            const newRule = {
              id: item.id,
              note: item.note,
              condition:
                item.direction == "in"
                  ? `invoice.direction == "in" && [${item?.vkn_tckn
                      ?.split(",")
                      .filter((item2) => item2.trim() !== "")
                      .map(
                        (item3) => `"${item3.trim()}"`
                      )}].find(item=>String(item.trim())?.toLocaleLowerCase("tr") === String(invoice.sender_object.vkn_tckn)?.toLocaleLowerCase("tr")) && line.type == "taxable" && line.code == "0015"`
                  : `invoice.direction == "out" && [${item?.vkn_tckn
                      ?.split(",")
                      .filter((item2) => item2.trim() !== "")
                      .map(
                        (item3) => `"${item3.trim()}"`
                      )}].find(item=>String(item.trim())?.toLocaleLowerCase("tr") === String(invoice.receiver_object.vkn_tckn)?.toLocaleLowerCase("tr")) && line.type == "taxable" && line.code == "0015"`,
              return:
                item.direction === "in"
                  ? [
                      {
                        borc: "line.amount",
                        alacak: "0",
                        hesap_kodu: `"${item.hesap_kodu}"`,
                      },
                    ]
                  : [
                      {
                        alacak: "line.amount",
                        borc: "0",
                        hesap_kodu: `"${item.hesap_kodu}"`,
                      },
                    ],
              isDefaultRule: false,
              type: "tableRules",
            };

            updatedTableRules.push(newRule);
          }
        });
        updatedTableRules = updatedTableRules.filter(
          (item) => item !== undefined && item !== null
        );
        invoiceRules.push(...updatedTableRules.sort((a, b) => b.id - a.id));
      } else {
        for await (const item of rules) {
          invoiceRules.push(
            await createBusinessRuleStringForBackend(
              item.direction,
              item.note,
              item.process,
              item.field,
              item.input,
              item.return,
              item.subRules
            )
          );
        }
      }

      const updatedBlackListData = [];
      const allIntegratorsLines = [];
      gridRef.current.api.forEachNode((node) =>
        allIntegratorsLines.push({
          integrator: node.data.integrator,
          username: node.data.username,
          password: node.data.password,
          corporateCode: node.data.corporateCode,
          isWillFetchInbox:
            node.data.isWillFetchInbox === "true" ||
            node.data.isWillFetchInbox === true,
          isWillFetchOutbox:
            node.data.isWillFetchOutbox === "true" ||
            node.data.isWillFetchOutbox === true,
        })
      );
      blackListDataGridRef.current.api.forEachNode((node) =>
        updatedBlackListData.push(node.data)
      );

      let settings = {
        blackList: updatedBlackListData,
        completeAlacakHesap:
          companyDetails?.companyClass === 1
            ? values.completeAlacakHesap
              ? values.completeAlacakHesap
              : ""
            : undefined,
        completeBorcHesap:
          companyDetails?.companyClass === 1
            ? values.completeBorcHesap
              ? values.completeBorcHesap
              : ""
            : undefined,
        completeFarkHesap:
          companyDetails?.companyClass === 1
            ? values.completeFarkHesap
              ? values.completeFarkHesap
              : ""
            : undefined,
        ficheSplitDay:
          companyDetails?.companyClass === 1
            ? values.ficheSplitDay
              ? values.ficheSplitDay
              : ""
            : undefined,
        ficheTopLines:
          companyDetails?.companyClass === 1
            ? values.ficheTopLines
              ? values.ficheTopLines
              : ""
            : undefined,
        incomingAccountType:
          companyDetails?.companyClass === 1
            ? values.incomingAccountType
              ? values.incomingAccountType
              : ""
            : undefined,
        integrators: allIntegratorsLines,
        invoiceRules: invoiceRules ? invoiceRules : [],
        newAccountIncoming:
          companyDetails?.companyClass === 1
            ? values.newAccountIncoming
              ? values.newAccountIncoming
              : ""
            : undefined,
        newAccountOutgoing:
          companyDetails?.companyClass === 1
            ? values.newAccountOutgoing
              ? values.newAccountOutgoing
              : ""
            : undefined,
        outgoingAccountType:
          companyDetails?.companyClass === 1
            ? values.outgoingAccountType
              ? values.outgoingAccountType
              : ""
            : undefined,
        preferences: {
          applyFrontendRules: values.applyFrontendRules,
          automaticallyCreateFiches: values.automaticallyCreateFiches
            ? values.automaticallyCreateFiches
            : false,
          fetchAutomaticallyInvoices: values.fetchAutomaticallyInvoices,
          automaticallySendFichesToLuca: values.automaticallySendFichesToLuca,
        },
      };

      saveSettingDetails(
        companyDetails?.companyId,
        companyDetails?.periodId,
        settings
      )
        .then((resp) => {
          Swal.close();
          if (resp.data.erroredIntegrators.length > 0) {
            Swal.fire({
              icon: "warning",
              title: "Ayarlar kaydedildi!",
              html: `Ayarlar kaydedildi fakat <b style="color: red;">${resp.data.erroredIntegrators
                .map((x) => x.integrator.toUpperCase())
                .join(", ")}</b> ${
                resp.data.erroredIntegrators.length > 1
                  ? "entegratörleri"
                  : "entegratörü"
              } hatalı.`,
              confirmButtonText: "Tamam",
            });
            getCompanyDetails(
              companyDetails?.companyId,
              companyDetails?.periodId
            ).then(async (resp) => {
              await setJsonItem(
                `#${companyDetails?.companyId}/${companyDetails?.periodId}`,
                resp.data
              );
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Ayarlar kaydedildi!",
              confirmButtonText: "Tamam",
            });
            getCompanyDetails(
              companyDetails?.companyId,
              companyDetails?.periodId
            ).then(async (resp) => {
              await setJsonItem(
                `#${companyDetails?.companyId}/${companyDetails?.periodId}`,
                resp.data
              );
            });
          }
        })
        .catch((err) => {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Ayarlar kaydedilemedi!",
            text: err.response.data.message ? err.response.data.message : "",
          });
        });
      setIsEmpty(false);
    }
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (incomingOptions.length > 0 && outgoingOptions.length > 0) {
      setRules((prevRules) =>
        prevRules.map((rule) => ({
          ...rule,
          return:
            rule.direction === "2"
              ? incomingOptions.find((option) => option.value === rule.return)
                  ?.value
              : outgoingOptions.find((option) => option.value === rule.return)
                  ?.value,
        }))
      );
    }
  }, [incomingOptions, outgoingOptions]);

  const defaultColDef = useMemo(
    () => ({
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: false,
      editable: true,
      enableCellChangeFlash: true,
      filterParams:createTurkishFilterParams(),
    }),
    []
  );

  const columnDefs = useMemo(
    () => createColumnDefs,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyDetails?.class]
  );

  const blackListColumnDefs = useMemo(
    () => createBlackListColumnDefs,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyDetails?.class]
  );
  const ruleTableColumnDefs = useMemo(
    () => createRuleTableColumnDefs(ItemSelectorCellEditor),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyDetails?.class]
  );

  const getMainMenuItems = (params) => {
    const allMenuItems = params.defaultItems;
    const itemsToRemove = ["toolPanelButton"]; // 'choose columns' seçeneği

    return allMenuItems.filter((item) => !itemsToRemove.includes(item));
  };

  return (
    <Grid container>
      <Grid
        item
        sx={{
          flexBasis: isOpen ? "275px" : "95px",
          flexShrink: 0,
          transition: "flex-basis 0.3s ease",
        }}
        zIndex={1}
      >
        <Sidebar
          status={isOpen}
          toggleSidebar={toggleSidebar}
          companyClass={companyDetails?.class}
        />
      </Grid>
      <Grid
        item
        zIndex={0}
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          gap: 1,
          pr: "12px",
        }}
      >
        <Grid item xs={12}>
          <Navbar
            setLoading={setLoading}
            setFirstLoading={setFirstLoading}
            firstLoading={firstLoading}
          />{" "}
        </Grid>
        <Grid container sx={12} className="grid-area">
          <Grid
            item
            sx={{
              mb: 5,
              display: "flex",
              justifyContent: "space-between",
            }}
            xs={12}
          >
            <Grid item xs={3} md={3}>
              <Typography variant="h6" color={"#030e22b2"} fontWeight={"550"}>
                Fatura Ayarları
              </Typography>
            </Grid>
            <Grid item xs={2} md={1}>
              {" "}
              <Button
                onClick={() => {
                  handleSaveButtonClick();
                }}
                fullWidth
                sx={{ width: "100%",textTransform: "none" }}
                variant="contained"
                color="primary"
                className={loading ? "unframed-button" : "custom-button"}
                disabled={loading}
                startIcon={<FaRegSave size={15} color="white" />}
              >
                Kaydet
              </Button>
            </Grid>
          </Grid>

          <Grid container xs={12} sx={{ justifyContent: "space-between" }}>
            <Grid
              item
              xs={12}
              md={5.9}
              className="ag-theme-quartz"
              style={{
                height: "500px",
                width: "100%",
                borderRadius: "10%",
                marginBottom: "80px",
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  mb: 1,
                  pl: 2,
                  gap: 1,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                xs={12}
              >
                <Typography variant="h6" color={"#030e22b2"} fontWeight={"450"}>
                  Entegratörler
                </Typography>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 1,
                  }}
                >
                  <Button
                    startIcon={<TbTrash />}
                    variant="outlined"
                    onClick={() => {
                      handleDeleteIntegratorButtonClick();
                    }}
                    sx={{
                      textTransform: "none",
                      color: "error.main",
                      borderColor: "error.main",
                      "&:hover": {
                        borderColor: "error.main",
                        color: "error.main",
                        backgroundColor: "rgba(232, 0, 0, 0.1)",
                      },
                    }}
                    disabled={loading}
                  >
                    Seçili Entegratörleri Sil
                  </Button>
                  <Button
                    startIcon={<FaPlus />}
                    variant="outlined"
                    onClick={async () => {
                      await gridRef.current.api.applyTransaction({
                        add: [
                          {
                            integrator: "",
                            username: "",
                            password: "",
                            isWillFetchInbox: true,
                            isWillFetchOutbox: true,
                          },
                        ],
                      });
                    }}
                    disabled={loading}
                    sx={{
                      textTransform: "none",
                      color: "#35ca78", // Metin rengi
                      borderColor: "#35ca78", // Kenarlık rengi
                      backgroundColor: "#ddf6e8",
                      "&:hover": {
                        backgroundColor: "#a5fcb4a4",
                        borderColor: "#35ca78",
                      },
                    }}
                  >
                    Entegratör Ekle
                  </Button>
                </Grid>
              </Grid>
              <AgGridReact
                ref={gridRef}
                localeText={localeTextTr}
                rowSelection="multiple"
                loading={loading}
                animateRows={true}
                rowDragManaged={true}
                rowMultiSelectWithClick={true}
                singleClickEdit={true}
                enableGroupEdit={true}
                stopEditingWhenCellsLoseFocus={true}
                rowData={rowData}
                columnDefs={columnDefs}
                enableRangeSelection={true}
                sideBar={gridSideBar.gridSideBar}
                defaultColDef={defaultColDef}
                getMainMenuItems={getMainMenuItems}
              />{" "}
            </Grid>{" "}
            <Grid
              item
              xs={12}
              md={5.9}
              sx={{
                display: companyDetails?.class === 1 ? "flex" : "none",
                flexDirection: "column",
              }}
            >
              <Grid container>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Typography variant="body2" gutterBottom>
                    120'li Cari Hesap Bulunamazsa
                  </Typography>
                  <Grid
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "stretch",
                      width: "100%",
                    }}
                  >
                    <TextField
                      fullWidth
                      value={values.newAccountOutgoing}
                      size="small"
                      onChange={handleChange("newAccountOutgoing")}
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                        },
                      }}
                    />
                    <Autocomplete
                      fullWidth
                      size="small"
                      value={
                        accountOptions.find(
                          (option) =>
                            option.value === values.outgoingAccountType
                        ) || null
                      }
                      disableClearable
                      onChange={(event, newValue) => {
                        setValues((prevValues) => ({
                          ...prevValues,
                          outgoingAccountType: newValue.value,
                        }));
                      }}
                      options={accountOptions}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderTopRightRadius: "5px",
                              borderBottomRightRadius: "5px",
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Typography variant="body2" gutterBottom>
                    320'li Cari Hesap Bulunamazsa
                  </Typography>
                  <Grid
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "stretch",
                      width: "100%",
                    }}
                  >
                    <TextField
                      fullWidth
                      value={values.newAccountIncoming}
                      size="small"
                      onChange={handleChange("newAccountIncoming")}
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                        },
                      }}
                    />
                    <Autocomplete
                      fullWidth
                      size="small"
                      value={
                        accountOptions.find(
                          (option) =>
                            option.value === values.incomingAccountType
                        ) || null
                      }
                      disableClearable
                      onChange={(event, newValue) => {
                        setValues((prevValues) => ({
                          ...prevValues,
                          incomingAccountType: newValue.value,
                        }));
                      }}
                      options={accountOptions}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderTopRightRadius: "5px",
                              borderBottomRightRadius: "5px",
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Typography variant="body2" gutterBottom sx={{ mb: 0 }}>
                    Alacak Az Olduğunda
                  </Typography>
                  <Grid
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "stretch",
                      width: "100%",
                    }}
                  >
                    <TextField
                      fullWidth
                      value={values.completeAlacakHesap}
                      size="small"
                      onChange={handleChange("completeAlacakHesap")}
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                        },
                        mb: 2,
                        display:
                          values.completeAlacakHesap === "taxable"
                            ? "none"
                            : "flex",
                      }}
                    />
                    <Autocomplete
                      fullWidth
                      size="small"
                      value={debitCreditOptions.find(
                        (option) =>
                          option.value ===
                          (values.completeAlacakHesap === "taxable"
                            ? "taxable"
                            : "account")
                      )}
                      disableClearable
                      onChange={(event, newValue) => {
                        setValues((prevValues) => ({
                          ...prevValues,
                          completeAlacakHesap:
                            newValue.value === "taxable"
                              ? "taxable"
                              : completeAlacakHesap,
                        }));
                      }}
                      options={debitCreditOptions}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderTopRightRadius: "5px",
                              borderBottomRightRadius: "5px",
                              borderTopLeftRadius:
                                values.completeAlacakHesap === "taxable"
                                  ? "5px"
                                  : "0px",
                              borderBottomLeftRadius:
                                values.completeAlacakHesap === "taxable"
                                  ? "5px"
                                  : "0px",
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Typography variant="body2" gutterBottom>
                    Borç Az Olduğunda
                  </Typography>
                  <Grid
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "stretch",
                      width: "100%",
                    }}
                  >
                    <TextField
                      fullWidth
                      value={values.completeBorcHesap}
                      size="small"
                      onChange={handleChange("completeBorcHesap")}
                      variant="outlined"
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                        },
                        mb: 2,
                        display:
                          values.completeBorcHesap === "taxable"
                            ? "none"
                            : "flex",
                      }}
                    />
                    <Autocomplete
                      fullWidth
                      size="small"
                      value={debitCreditOptions.find(
                        (option) =>
                          option.value ===
                          (values.completeBorcHesap === "taxable"
                            ? "taxable"
                            : "account")
                      )}
                      disableClearable
                      onChange={(event, newValue) => {
                        setValues((prevValues) => ({
                          ...prevValues,
                          completeBorcHesap:
                            newValue.value === "taxable"
                              ? "taxable"
                              : completeBorcHesap,
                        }));
                      }}
                      options={debitCreditOptions}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              borderTopRightRadius: "5px",
                              borderBottomRightRadius: "5px",
                              borderTopLeftRadius:
                                values.completeBorcHesap === "taxable"
                                  ? "5px"
                                  : "0px",
                              borderBottomLeftRadius:
                                values.completeBorcHesap === "taxable"
                                  ? "5px"
                                  : "0px",
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mb: 0 }}>
                  <Typography variant="body2" gutterBottom>
                    Fark şu tutardan az olursa ek satır eklensin
                  </Typography>
                  <TextField
                    fullWidth
                    value={values.completeFarkHesap}
                    size="small"
                    onChange={handleChange("completeFarkHesap")}
                    variant="outlined"
                    sx={{ mb: 2 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" gutterBottom sx={{ mb: 0 }}>
                    Fişler Kaçar Günlük Aktarılsın
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    value={values.ficheSplitDay}
                    onChange={handleChange("ficheSplitDay")}
                    variant="outlined"
                    sx={{ mb: 2 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" gutterBottom sx={{ mb: 0 }}>
                    Fişlerin Maksimum Satır Sayısı
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    value={values.ficheTopLines}
                    onChange={handleChange("ficheTopLines")}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              md={5.9}
              className="ag-theme-quartz"
              style={{
                mt: 20,
                height: "450px",
                width: "100%",
                borderRadius: "10%",
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  mb: 1,
                  pl: 2,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                xs={12}
              >
                <Grid xs={2}>
                  <Typography
                    variant="h6"
                    color={"#030e22b2"}
                    fontWeight={"450"}
                  >
                    Kara Liste
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 1,
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      gap: 1,
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      startIcon={<TbTrash />}
                      variant="outlined"
                      onClick={() => {
                        handleDeleteBlackListButtonClick();
                      }}
                      sx={{
                        textTransform: "none",
                        color: "error.main",
                        borderColor: "error.main",
                        "&:hover": {
                          borderColor: "error.main",
                          color: "error.main",
                          backgroundColor: "rgba(232, 0, 0, 0.1)",
                        },
                      }}
                      disabled={loading}
                    >
                      Seçili Firmaları Sil
                    </Button>
                    <Button
                      startIcon={<FaPlus />}
                      variant="outlined"
                      onClick={() => {
                        blackListDataGridRef.current.api.applyTransaction({
                          add: [
                            {
                              direction: "Gelen",
                              vkn_tckn: "",
                              address: "",
                            },
                          ],
                        });
                      }}
                      sx={{
                        textTransform: "none",
                        color: "#35ca78", // Metin rengi
                        borderColor: "#35ca78", // Kenarlık rengi
                        backgroundColor: "#ddf6e8",
                        "&:hover": {
                          backgroundColor: "#a5fcb4a4",
                          borderColor: "#35ca78",
                        },
                      }}
                      disabled={loading}
                    >
                      Firma Ekle
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <AgGridReact
                ref={blackListDataGridRef}
                onSelectionChanged={onSelectionChangedBlackList}
                localeText={localeTextTr}
                rowSelection="multiple"
                loading={loading}
                animateRows={true}
                rowDragManaged={true}
                rowMultiSelectWithClick={true}
                singleClickEdit={true}
                stopEditingWhenCellsLoseFocus={true}
                rowData={blackListData}
                columnDefs={blackListColumnDefs}
                enableRangeSelection={true}
                sideBar={gridSideBar.gridSideBar}
                copyHeadersToClipboard={false}
                defaultColDef={defaultColDef}
                getMainMenuItems={getMainMenuItems}
              />{" "}
            </Grid>{" "}
            <Grid
              xs={12}
              md={5.9}
              sx={{
                display: "flex",
                flexDirection: "column",
                height: { md: "550px", lg: "520px" },
              }}
            >
              <Grid sx={{ mt: 5 }}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    textAlign: "center",
                    mt: 1,
                    gap: 1,
                  }}
                >
                  <Checkbox
                    checked={values.fetchAutomaticallyInvoices}
                    onChange={handleChange("fetchAutomaticallyInvoices")}
                    sx={{
                      padding: "0",

                      "&.Mui-checked": {
                        color: "#041737",
                      },
                      "&.Mui-checked:hover": {
                        color: "#041737",
                      },
                      "&:hover": {
                        color: "#041737",
                      },
                    }}
                  />
                  <Typography color={"#2a292b"}>
                    Faturaları Otomatik Getir
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    textAlign: "center",
                    mt: 1,
                    gap: 1,
                  }}
                >
                  <Checkbox
                    checked={values.automaticallyCreateFiches}
                    onChange={handleChange("automaticallyCreateFiches")}
                    sx={{
                      padding: "0",

                      "&.Mui-checked": {
                        color: "#041737",
                      },
                      "&.Mui-checked:hover": {
                        color: "#041737",
                      },
                      "&:hover": {
                        color: "#041737",
                      },
                    }}
                  />
                  <Typography color={"#2a292b"}>
                    Fişleri Otomatik Oluştur
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    textAlign: "center",
                    mt: 1,
                    gap: 1,
                  }}
                >
                  <Checkbox
                    checked={values.automaticallySendFichesToLuca}
                    onChange={handleChange("automaticallySendFichesToLuca")}
                    sx={{
                      padding: "0",

                      "&.Mui-checked": {
                        color: "#041737",
                      },
                      "&.Mui-checked:hover": {
                        color: "#041737",
                      },
                      "&:hover": {
                        color: "#041737",
                      },
                    }}
                  />
                  <Typography color={"#2a292b"}>
                    Fişleri Luca'ya Otomatik Gönder
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {companyDetailsRef?.current?.class === 1 && (
              <Grid
                item
                xs={12}
                className="ag-theme-quartz"
                style={{
                  height: "500px",
                  width: "100%",
                  borderRadius: "10%",
                  marginBottom: "50px",
                }}
              >
                <Divider />
                <Grid
                  item
                  sx={{
                    display: companyDetails?.class === 1 ? "flex" : "none",
                    mt: 1,
                    mb: 1,
                    pl: 2,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  xs={12}
                >
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      justifyContent: "start",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={"#030e22b2"}
                      fontWeight={"450"}
                    >
                      Temel Kurallar
                    </Typography>
                    <Button
                      startIcon={<TbArrowsExchange2 />}
                      variant="outlined"
                      onClick={() => {
                        setModalId("#copyRuleToCompanySwal");
                        handleModalOpen();
                      }}
                      sx={{
                        textTransform: "none",
                        color: "#ffac5e",
                        borderColor: "#ffac5e",
                        backgroundColor: "#fff0e1",
                        "&:hover": {
                          borderColor: "#ffac5e",
                          color: "#ffac5e",
                          backgroundColor: "#dd934a5c",
                        },
                      }}
                      disabled={loading}
                    >
                      Hazır Kural Getir
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 1,
                    }}
                  >
                    <Button
                      startIcon={<TbTrash />}
                      variant="outlined"
                      onClick={() => {
                        handleDeleteRuleButtonClick();
                      }}
                      sx={{
                        textTransform: "none",
                        color: "error.main",
                        borderColor: "error.main",
                        "&:hover": {
                          borderColor: "error.main",
                          color: "error.main",
                          backgroundColor: "rgba(232, 0, 0, 0.1)",
                        },
                      }}
                      disabled={loading}
                    >
                      Seçili Kuralları Sil
                    </Button>
                    <Button
                      startIcon={<FaPlus />}
                      variant="outlined"
                      onClick={async () => {
                        const allRowsData = [];
                        await ruleTableDataGridRef.current.api.forEachNode(
                          (node) => {
                            allRowsData.push(node.data);
                          }
                        );

                        // En yüksek id değerini bul
                        const maxId =
                          (await allRowsData.sort((a, b) => b.id - a.id)[0]
                            ?.id) || 0;

                        // Yeni satırı ekle
                        await ruleTableDataGridRef.current.api.applyTransaction(
                          {
                            addIndex: 0,
                            add: [
                              {
                                id: maxId + 10,
                                direction: "in",
                                note: "... Matrahı",
                                hesap_kodu: "",
                                isDefaultRule: false,
                                vkn_tckn: "",
                                condition: "",
                              },
                            ],
                          }
                        );
                      }}
                      sx={{
                        textTransform: "none",
                        color: "#35ca78", // Metin rengi
                        borderColor: "#35ca78", // Kenarlık rengi
                        backgroundColor: "#ddf6e8",
                        "&:hover": {
                          backgroundColor: "#a5fcb4a4",
                          borderColor: "#35ca78",
                        },
                      }}
                      disabled={loading}
                    >
                      Kural Ekle
                    </Button>
                  </Grid>
                </Grid>
                <AgGridReact
                  ref={ruleTableDataGridRef}
                  getRowId={getRowId}
                  localeText={localeTextTr}
                  rowSelection="multiple"
                  loading={loading}
                  animateRows={true}
                  rowDragManaged={true}
                  rowMultiSelectWithClick={false}
                  singleClickEdit={true}
                  stopEditingWhenCellsLoseFocus={true}
                  suppressRowClickSelection={true}
                  rowData={ruleTableData}
                  columnDefs={ruleTableColumnDefs}
                  enableRangeSelection={true}
                  sideBar={gridSideBar.gridSideBar}
                  copyHeadersToClipboard={false}
                  defaultColDef={defaultColDef}
                  getMainMenuItems={getMainMenuItems}
                />{" "}
              </Grid>
            )}
            <Grid container xs={12} sx={{ mt: 6, gap: 2 }}>
              <Grid item xs={12}>
                <Divider />
                <Typography
                  variant="h6"
                  color={"#030e22b2"}
                  fontWeight={"450"}
                  sx={{ ml: 2, mt: 1, mb: 1, mr: 2 }}
                >
                  {companyDetailsRef.current?.class === 1
                    ? "Özel Kurallar"
                    : "Kurallar"}
                </Typography>
                <Divider variant="middle" />
              </Grid>
              <Grid item xs={12} sx={{ pl: 2, pt: 1, pb: 1, pr: 2 }}>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    startIcon={<FaPlus size={14} />}
                    variant="outlined"
                    onClick={() => handleAddRule()}
                    sx={{
                      textTransform: "none",
                      color: "#35ca78", // Metin rengi
                      borderColor: "#35ca78", // Kenarlık rengi
                      backgroundColor: loading ? undefined : "#ddf6e8",
                      "&:hover": {
                        backgroundColor: "#a5fcb4a4",
                        borderColor: "#35ca78",
                      },
                    }}
                    disabled={loading}
                  >
                    {companyDetailsRef.current?.class === 1
                      ? "Özel Kural Ekle"
                      : "Kural Ekle"}
                  </Button>
                </Grid>
                <DragDropContext onDragEnd={handleRuleReorder}>
                  <Droppable droppableId="rules">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {rules.map((rule, index) => (
                          <Draggable
                            key={rule?.index}
                            draggableId={`rule-${rule?.index}`}
                            index={index}
                          >
                            {(provided) => (
                              <Grid
                                container
                                alignItems="center"
                                spacing={2}
                                sx={{
                                  mt: 2,
                                  alignItems: "flex-end",
                                  marginTop: "auto",
                                  ...provided.draggableProps.style,
                                }}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {/* Diğer grid öğeleri buraya */}
                                <Grid
                                  container
                                  sx={{
                                    width: "100%",
                                    display: index < 1 ? "none" : "",
                                    justifyContent: "center",
                                    mt: index < 1 ? 0 : 1,
                                  }}
                                >
                                  <Divider
                                    variant="middle"
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                </Grid>
                                {companyDetailsRef.current.class === 1 && (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    sx={{
                                      marginLeft: 8,
                                      marginRight: 14,
                                      marginBottom: 0,
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      gutterBottom
                                      sx={{
                                        mb: 0,
                                      }}
                                    >
                                      Not Alanı(Opsiyonel)
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      size="small"
                                      value={rule?.note}
                                      onChange={(e) =>
                                        handleRuleChange(
                                          rule.index,
                                          "note",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Grid>
                                )}
                                <Grid
                                  item
                                  xs={12}
                                  sm={0.4}
                                  {...provided.dragHandleProps}
                                  sx={{
                                    marginBottom: "5px",
                                  }}
                                >
                                  <IconButton>
                                    <RiDragMove2Fill size={17} />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={12} sm={1.3}>
                                  <Typography
                                    variant="body2"
                                    gutterBottom
                                    sx={{
                                      mb: 0,
                                    }}
                                  >
                                    Yön Seçiniz
                                  </Typography>
                                  <Autocomplete
                                    fullWidth
                                    disableClearable
                                    size="small"
                                    clearIcon={null}
                                    onKeyDown={(event) => {
                                      if (event.key === "Enter") {
                                        // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                                        const highlightedOption =
                                          event.target.querySelector(
                                            'li[data-focus="true"]'
                                          );
                                        if (highlightedOption) {
                                          highlightedOption.click();
                                        }
                                      }
                                    }}
                                    value={(companyDetailsRef.current.class ===
                                    1
                                      ? directionOptions1
                                      : directionOptions
                                    ).find(
                                      (option) =>
                                        option.value === rule?.direction
                                    )}
                                    onChange={(event, newValue) => {
                                      if (
                                        companyDetailsRef.current.class === 1
                                      ) {
                                        handleRuleChange(rule.index, {
                                          direction: newValue?.value,
                                        });
                                      } else {
                                        handleRuleChange(rule.index, {
                                          direction: newValue?.value,
                                          return: "",
                                        });
                                      }
                                    }}
                                    options={
                                      companyDetailsRef.current.class === 1
                                        ? directionOptions1
                                        : directionOptions
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        size="small"
                                        sx={{
                                          backgroundColor:
                                            isEmpty &&
                                            (rule.direction === "" ||
                                              rule.direction === null ||
                                              rule.direction === undefined)
                                              ? "#ff000031"
                                              : undefined,
                                        }}
                                        {...params}
                                      />
                                    )}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  sm={
                                    companyDetailsRef.current.class === 1
                                      ? 1.5
                                      : 2
                                  }
                                >
                                  <Typography
                                    variant="body2"
                                    gutterBottom
                                    sx={{
                                      mb: 0,
                                    }}
                                  >
                                    Alan Seçiniz
                                  </Typography>
                                  <Autocomplete
                                    fullWidth
                                    disableClearable
                                    size="small"
                                    clearIcon={null}
                                    value={(companyDetailsRef.current.class ===
                                    1
                                      ? fieldBalanceOptions
                                      : fieldBusinessOptions
                                    ).find(
                                      (option) => option.value === rule.field
                                    )}
                                    onChange={(event, newValue) => {
                                      if (
                                        companyDetailsRef.current.class === 1
                                      ) {
                                        handleRuleChange(rule.index, {
                                          field: newValue?.value,
                                          input:
                                            newValue?.value === "line.type"
                                              ? "payable"
                                              : newValue?.value ===
                                                "invoice.type_code"
                                              ? "SATIS"
                                              : newValue?.value ===
                                                "invoice.profile_id"
                                              ? "TICARIFATURA"
                                              : "",
                                          process:
                                            newValue?.label.includes(
                                              "(Liste)"
                                            ) ||
                                            newValue?.value ===
                                              "invoice.payable_amount" ||
                                            newValue?.value === "line.amount"
                                              ? "includes"
                                              : "==",
                                        });
                                      } else {
                                        handleRuleChange(rule.index, {
                                          field: newValue?.value,
                                          input: "",
                                          process:
                                            newValue?.value ===
                                              "invoice.receiver_tax" ||
                                            newValue?.value ===
                                              "invoice.sender_tax"
                                              ? "=="
                                              : "includes",
                                        });
                                      }
                                    }}
                                    options={
                                      companyDetailsRef.current.class === 1
                                        ? fieldBalanceOptions
                                        : fieldBusinessOptions
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        size="small"
                                        sx={{
                                          backgroundColor:
                                            isEmpty &&
                                            (rule.field === "" ||
                                              rule.field === null ||
                                              rule.field === undefined)
                                              ? "#ff000031"
                                              : undefined,
                                        }}
                                        {...params}
                                      />
                                    )}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={2}>
                                  <Typography
                                    variant="body2"
                                    gutterBottom
                                    sx={{
                                      mb: 0,
                                    }}
                                  >
                                    {rule.field === "line.type" ||
                                    rule.field === "invoice.type_code" ||
                                    rule.field === "invoice.profile_id"
                                      ? "Seçiniz"
                                      : "Metin / Değer Giriniz"}
                                  </Typography>
                                  {!(
                                    rule.field === "line.type" ||
                                    rule.field === "invoice.type_code" ||
                                    rule.field === "invoice.profile_id"
                                  ) && (
                                    <TextField
                                      fullWidth
                                      size="small"
                                      value={rule.input}
                                      placeholder={
                                        rule.field === "invoice.receiver_tax" ||
                                        rule.field === "invoice.sender_tax" ||
                                        rule.field ===
                                          "invoice.receiver_object.vkn_tckn" ||
                                        rule.field ===
                                          "invoice.sender_object.vkn_tckn"
                                          ? "Örnek:1234567893"
                                          : rule.field ===
                                              "invoice.receiver_tax.List" ||
                                            rule.field ===
                                              "invoice.sender_tax.List" ||
                                            rule.field ===
                                              "invoice.receiver_object.vkn_tckn.List" ||
                                            rule.field ===
                                              "invoice.sender_object.vkn_tckn.List" ||
                                            rule.field ===
                                              "invoice.accounting_lines.code.List"
                                          ? "Örnek:9876543121,12345678910"
                                          : "Örnek:abc,123,a1b2"
                                      }
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        let regex;

                                        if (
                                          rule.field ===
                                            "invoice.receiver_tax" ||
                                          rule.field === "invoice.sender_tax" ||
                                          rule.field ===
                                            "invoice.receiver_object.vkn_tckn" ||
                                          rule.field ===
                                            "invoice.sender_object.vkn_tckn"
                                        ) {
                                          // Sadece sayıya izin ver ve 11 karakter sınırlaması
                                          regex = /^[0-9]{0,11}$/;
                                        } else if (
                                          rule.field ===
                                            "invoice.receiver_tax.List" ||
                                          rule.field ===
                                            "invoice.sender_tax.List" ||
                                          rule.field ===
                                            "invoice.receiver_object.vkn_tckn.List" ||
                                          rule.field ===
                                            "invoice.sender_object.vkn_tckn.List" ||
                                          rule.field ===
                                            "invoice.accounting_lines.code.List"
                                        ) {
                                          // Sayı ve virgül kombinasyonuna izin ver
                                          regex = /^([0-9][0-9,]*|)$/;
                                        } else {
                                          // Herhangi bir girişe izin ver
                                          regex = /.*/; // Tüm karakterler serbest
                                        }

                                        if (regex.test(value)) {
                                          handleRuleChange(
                                            rule.index,
                                            "input",
                                            value
                                          );
                                        }
                                      }}
                                      sx={{
                                        display:
                                          rule.field === "line.type" ||
                                          rule.field === "invoice.type_code" ||
                                          rule.field === "invoice.profile_id"
                                            ? "none"
                                            : "",
                                        backgroundColor:
                                          isEmpty &&
                                          (rule.input === "" ||
                                            rule.input === null ||
                                            rule.input === undefined)
                                            ? "#ff000031"
                                            : undefined,
                                      }}
                                    />
                                  )}

                                  {(rule.field === "line.type" ||
                                    rule.field === "invoice.type_code" ||
                                    rule.field === "invoice.profile_id") && (
                                    <Autocomplete
                                      key={rule.input}
                                      fullWidth
                                      disableClearable
                                      clearIcon={null}
                                      size="small"
                                      isOptionEqualToValue={() => true}
                                      value={
                                        (rule.field === "invoice.profile_id"
                                          ? invoiceProfiles
                                          : rule.field === "invoice.type_code"
                                          ? invoiceTypes
                                          : systemLineTypes
                                        ).find(
                                          (option) =>
                                            option.value === rule.input
                                        ) || rule.input
                                      }
                                      onChange={(event, newValue) =>
                                        handleRuleChange(
                                          rule.index,
                                          "input",
                                          newValue?.value
                                        )
                                      }
                                      options={
                                        rule.field === "invoice.profile_id"
                                          ? invoiceProfiles
                                          : rule.field === "invoice.type_code"
                                          ? invoiceTypes
                                          : systemLineTypes
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          size="small"
                                          sx={{
                                            display:
                                              rule.field === "line.type" ||
                                              rule.field ===
                                                "invoice.type_code" ||
                                              rule.field ===
                                                "invoice.profile_id"
                                                ? ""
                                                : "none",
                                            backgroundColor:
                                              isEmpty &&
                                              (rule.field === "" ||
                                                rule.field === null ||
                                                rule.field === undefined)
                                                ? "#ff000031"
                                                : undefined,
                                          }}
                                          {...params}
                                        />
                                      )}
                                    />
                                  )}
                                </Grid>
                                {companyDetailsRef.current.class === 2 && (
                                  <Grid
                                    item
                                    xs={12}
                                    sm={2}
                                    sx={{
                                      display:
                                        companyDetailsRef.current.class === 1
                                          ? "none"
                                          : "",
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      gutterBottom
                                      sx={{
                                        mb: 0,
                                      }}
                                    >
                                      Not
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      size="small"
                                      value={rule.note}
                                      sx={{
                                        backgroundColor:
                                          isEmpty &&
                                          (rule.note === "" ||
                                            rule.note === null ||
                                            rule.note === undefined)
                                            ? "#ff000031"
                                            : undefined,
                                      }}
                                      onChange={(e) =>
                                        handleRuleChange(
                                          rule.index,
                                          "note",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Grid>
                                )}
                                <Grid item xs={12} sm={1.5}>
                                  <Typography
                                    variant="body2"
                                    gutterBottom
                                    sx={{
                                      mb: 0,
                                    }}
                                  >
                                    İşlem Seçiniz
                                  </Typography>
                                  <Autocomplete
                                    disableClearable
                                    clearIcon={null}
                                    fullWidth
                                    size="small"
                                    value={processOptions.find(
                                      (option) => option.value === rule.process
                                    )}
                                    onChange={(event, newValue) =>
                                      handleRuleChange(
                                        rule.index,
                                        "process",
                                        newValue?.value
                                      )
                                    }
                                    options={processOptions.filter((option) => {
                                      if (
                                        companyDetailsRef.current.class === 1
                                      ) {
                                        if (
                                          rule.field ===
                                            "invoice.sender_object.vkn_tckn" ||
                                          rule.field ===
                                            "invoice.receiver_object.vkn_tckn" ||
                                          rule.field === "invoice.profile_id" ||
                                          rule.field === "invoice.type_code" ||
                                          rule.field === "line.code" ||
                                          rule.field === "line.type"
                                        ) {
                                          return (
                                            option.value !== "endsWith" &&
                                            option.value !== "startsWith" &&
                                            option.value !== "notIncludes" &&
                                            option.value !== "includes"
                                          );
                                        } else if (
                                          rule.field.includes(".List") ||
                                          rule.field === "invoice.notes"
                                        ) {
                                          return (
                                            option.value !== "endsWith" &&
                                            option.value !== "startsWith" &&
                                            option.value !== "!=" &&
                                            option.value !== "=="
                                          );
                                        } else {
                                          return option.value;
                                        }
                                      } else {
                                        if (
                                          rule.field ===
                                            "invoice.receiver_tax" ||
                                          rule.field === "invoice.sender_tax"
                                        ) {
                                          return (
                                            option.value !== "endsWith" &&
                                            option.value !== "startsWith" &&
                                            option.value !== "notIncludes" &&
                                            option.value !== "includes" &&
                                            option.value !== ">" &&
                                            option.value !== "<" &&
                                            option.value !== ">=" &&
                                            option.value !== "<="
                                          );
                                        } else if (
                                          rule.field ===
                                            "invoice.receiver_tax.List" ||
                                          rule.field ===
                                            "invoice.sender_tax.List" ||
                                          rule.field === "line?.name.List" ||
                                          rule.field === "line.name.List"
                                        ) {
                                          return (
                                            option.value !== "endsWith" &&
                                            option.value !== "startsWith" &&
                                            option.value !== "!=" &&
                                            option.value !== "==" &&
                                            option.value !== ">" &&
                                            option.value !== "<" &&
                                            option.value !== ">=" &&
                                            option.value !== "<="
                                          );
                                        } else {
                                          return (
                                            option.value !== ">" &&
                                            option.value !== "<" &&
                                            option.value !== ">=" &&
                                            option.value !== "<="
                                          );
                                        }
                                      }
                                    })}
                                    renderInput={(params) => (
                                      <TextField
                                        size="small"
                                        sx={{
                                          backgroundColor:
                                            isEmpty &&
                                            (rule.process === "" ||
                                              rule.process === null ||
                                              rule.process === undefined)
                                              ? "#ff000031"
                                              : undefined,
                                        }}
                                        {...params}
                                      />
                                    )}
                                  />
                                </Grid>
                                {companyDetailsRef.current.class === 1 && (
                                  <Grid item xs={12} sm={1.3}>
                                    <Typography
                                      variant="body2"
                                      gutterBottom
                                      sx={{
                                        mb: 0,
                                      }}
                                    >
                                      Borç Seçiniz
                                    </Typography>
                                    <Autocomplete
                                      fullWidth
                                      disableClearable
                                      clearIcon={null}
                                      size="small"
                                      value={
                                        debtAndReceivableOptions.find(
                                          (option) =>
                                            option.value === rule.return[0].borc
                                        ) || {
                                          label: `"${rule.return[0].borc}"`,
                                          value: `"${rule.return[0].borc}"`,
                                        }
                                      }
                                      onChange={async (event, newValue) => {
                                        if (newValue?.value === "special") {
                                          let specialRule =
                                            await AddSpecialRule();
                                          if (specialRule) {
                                            setDebtAndReceivableOptions(
                                              (prevOptions) => [
                                                ...prevOptions,
                                                {
                                                  label: `${specialRule}`,
                                                  value: `${specialRule}`,
                                                },
                                              ]
                                            );
                                          }
                                          handleRuleChange(
                                            rule.index,
                                            null,
                                            null,
                                            0,
                                            null,
                                            null,
                                            "borc",
                                            specialRule.substring(
                                              1,
                                              specialRule.length - 1
                                            )
                                          );
                                        } else {
                                          handleRuleChange(
                                            rule.index,
                                            null,
                                            null,
                                            0,
                                            null,
                                            null,
                                            "borc",
                                            newValue?.value
                                          );
                                        }
                                      }}
                                      options={
                                        debtAndReceivableOptions.find(
                                          (option) =>
                                            option.value === rule.return[0].borc
                                        )
                                          ? debtAndReceivableOptions
                                          : [
                                              ...debtAndReceivableOptions,
                                              {
                                                label: `"${rule.return[0].borc}"`,
                                                value: `"${rule.return[0].borc}"`,
                                              },
                                            ]
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          size="small"
                                          sx={{
                                            backgroundColor:
                                              isEmpty &&
                                              (rule.return[0].borc === "" ||
                                                rule.return[0].borc === null ||
                                                rule.return[0].borc ===
                                                  undefined)
                                                ? "#ff000031"
                                                : undefined,
                                          }}
                                          {...params}
                                        />
                                      )}
                                    />
                                  </Grid>
                                )}

                                {companyDetailsRef.current.class === 1 && (
                                  <Grid item xs={12} sm={1.3}>
                                    <Typography
                                      variant="body2"
                                      gutterBottom
                                      sx={{
                                        mb: 0,
                                      }}
                                    >
                                      Alacak Seçiniz
                                    </Typography>
                                    <Autocomplete
                                      clearIcon={null}
                                      fullWidth
                                      disableClearable
                                      size="small"
                                      isOptionEqualToValue={() => true}
                                      value={
                                        debtAndReceivableOptions.find(
                                          (option) =>
                                            option.value ===
                                            rule.return[0].alacak
                                        ) || {
                                          label: `"${rule.return[0].alacak}"`,
                                          value: `"${rule.return[0].alacak}"`,
                                        }
                                      }
                                      onChange={async (event, newValue) => {
                                        if (newValue?.value === "special") {
                                          let specialRule =
                                            await AddSpecialRule();
                                          if (specialRule) {
                                            setDebtAndReceivableOptions(
                                              (prevOptions) => [
                                                ...prevOptions,
                                                {
                                                  label: `${specialRule}`,
                                                  value: `${specialRule}`,
                                                },
                                              ]
                                            );
                                          }
                                          handleRuleChange(
                                            rule.index,
                                            null,
                                            null,
                                            0,
                                            null,
                                            null,
                                            "alacak",
                                            specialRule.substring(
                                              1,
                                              specialRule.length - 1
                                            )
                                          );
                                        } else {
                                          handleRuleChange(
                                            rule.index,
                                            null,
                                            null,
                                            0,
                                            null,
                                            null,
                                            "alacak",
                                            newValue?.value
                                          );
                                        }
                                      }}
                                      options={
                                        debtAndReceivableOptions.find(
                                          (option) =>
                                            option.value ===
                                            rule.return[0].alacak
                                        )
                                          ? debtAndReceivableOptions
                                          : [
                                              ...debtAndReceivableOptions,
                                              {
                                                label: `"${rule.return[0].alacak}"`,
                                                value: `"${rule.return[0].alacak}"`,
                                              },
                                            ]
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          size="small"
                                          sx={{
                                            backgroundColor:
                                              isEmpty &&
                                              (rule.return[0].alacak === "" ||
                                                rule.return[0].alacak ===
                                                  null ||
                                                rule.return[0].alacak ===
                                                  undefined)
                                                ? "#ff000031"
                                                : undefined,
                                          }}
                                          {...params}
                                        />
                                      )}
                                    />
                                  </Grid>
                                )}
                                {companyDetailsRef.current.class === 2 && (
                                  <Grid item xs={12} sm={2.4}>
                                    <Typography
                                      variant="body2"
                                      gutterBottom
                                      sx={{
                                        mb: 0,
                                      }}
                                    >
                                      Kayıt Alt Türü Seçiniz
                                    </Typography>
                                    <Autocomplete
                                      disableClearable
                                      clearIcon={null}
                                      fullWidth
                                      size="small"
                                      isOptionEqualToValue={() => true}
                                      value={
                                        rule.direction === "2"
                                          ? incomingOptions.find((option) => {
                                              return (
                                                option.value === rule.return
                                              );
                                            })
                                          : outgoingOptions.find((option) => {
                                              return (
                                                option.value === rule.return
                                              );
                                            })
                                      }
                                      filterOptions={(options, params) => {
                                            const filtered = options.filter((option) => {
                                              let label = `${option.label}`;
                                              return label
                                                .toLocaleLowerCase("tr")
                                                .includes(params?.inputValue?.toLocaleLowerCase("tr"));
                                            });
                                            return filtered
                                          }

                                      }
                                      renderOption={(props, item) => (
                                        <li
                                          {...props}
                                          key={item.key || item.value}
                                        >
                                          <ListItemText>
                                            {item.label}
                                          </ListItemText>
                                        </li>
                                      )}
                                      onChange={(event, newValue) =>
                                        handleRuleChange(
                                          rule.index,
                                          "return",
                                          newValue?.value
                                        )
                                      }
                                      options={
                                        rule.direction === "2"
                                          ? incomingOptions
                                          : outgoingOptions
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          size="small"
                                          sx={{
                                            backgroundColor:
                                              isEmpty &&
                                              (rule.return === "" ||
                                                rule.return === null ||
                                                rule.return === undefined)
                                                ? "#ff000031"
                                                : undefined,
                                          }}
                                          {...params}
                                        />
                                      )}
                                    />
                                  </Grid>
                                )}

                                {companyDetailsRef.current.class === 1 && (
                                  <Grid item xs={12} sm={2}>
                                    <Typography
                                      variant="body2"
                                      gutterBottom
                                      sx={{
                                        mb: 0,
                                      }}
                                    >
                                      Hesap Kodu Seçiniz
                                    </Typography>

                                    <Autocomplete
                                      clearIcon={null}
                                      disableClearable
                                      fullWidth
                                      size="small"
                                      isOptionEqualToValue={() => true}
                                      value={
                                        lucaAccountCodesRef.current.find(
                                          (option) =>
                                            option.hesap_kodu ===
                                            rule.return[0].hesap_kodu
                                        ) || {
                                          hesap_kodu: rule.return[0].hesap_kodu,
                                        }
                                      }
                                      filterOptions={(options, params) => {
                                        const filtered = options.filter(
                                          (option) => {
                                            let label = `${option.hesap_kodu} - ${option.hesap_adi}`;
                                            return label
                                              .toLocaleLowerCase("tr")
                                              .includes(
                                                params?.inputValue?.toLocaleLowerCase("tr")
                                              );
                                          }
                                        );
                                        // Girilen metin, mevcut seçenekler arasında yoksa özel bir seçenek ekle
                                        if (filtered?.length === 0) {
                                          filtered.push({
                                            hesap_adi:
                                              "Yeni Hesap Kodu Eklemek İçin Tıklayın",
                                            hesap_kodu: "add-new", // Özel seçenek için belirleyici bir value
                                          });
                                        }
                                        return filtered;
                                      }}
                                      onChange={async (event, newValue) => {
                                        if (
                                          newValue?.hesap_kodu === "add-new"
                                        ) {
                                          CreateLucaAccountSwal(
                                            lucaAccountCodesRef.current
                                              ?.companyId,
                                            lucaAccountCodesRef.current
                                              ?.periodId
                                          );
                                        } else {
                                          if (
                                            newValue?.hesap_kodu === "special"
                                          ) {
                                            let specialRule =
                                              await AddSpecialRule();
                                            handleRuleChange(
                                              rule.index,
                                              null,
                                              null,
                                              0,
                                              null,
                                              null,
                                              "hesap_kodu",
                                              specialRule.substring(
                                                1,
                                                specialRule.length - 1
                                              )
                                            );
                                          } else {
                                            handleRuleChange(
                                              rule.index,
                                              null,
                                              null,
                                              0,
                                              null,
                                              null,
                                              "hesap_kodu",
                                              newValue?.hesap_kodu
                                            );
                                          }
                                        }
                                      }}
                                      options={options(
                                        rule.return[0].hesap_kodu
                                      )}
                                      getOptionLabel={getOptionLabel}
                                      renderInput={(params) => (
                                        <TextField
                                          size="small"
                                          sx={{
                                            backgroundColor:
                                              isEmpty &&
                                              (rule.return[0].hesap_kodu ===
                                                "" ||
                                                rule.return[0].hesap_kodu ===
                                                  null ||
                                                rule.return[0].hesap_kodu ===
                                                  undefined)
                                                ? "#ff000031"
                                                : undefined,
                                          }}
                                          {...params}
                                        />
                                      )}
                                    />
                                  </Grid>
                                )}

                                {/* Silme ve sürükleme düğmeleri */}
                                <Grid
                                  item
                                  xs={12}
                                  sm={0.3}
                                  sx={{
                                    marginBottom: "5px",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => handleRemoveRule(rule.index)}
                                    color="error"
                                  >
                                    <FaRegTrashAlt size={15} />
                                  </IconButton>
                                </Grid>

                                {/* Alt kurallar için "Ve" düğmesi */}

                                {/* Alt kuralları render et */}
                                {rule.subRules.map((subRule, subIndex) => (
                                  <Grid
                                    container
                                    xs={12}
                                    key={`${rule.index}-${subRule.subIndex}`}
                                    sx={{
                                      pl: 10,
                                      mt: 3,
                                      gap: 2,
                                      alignItems: "flex-end",
                                    }}
                                  >
                                    <Grid
                                      item
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        pb: 1,
                                        height: "100%",
                                      }}
                                    >
                                      <IoReturnDownForwardOutline />
                                    </Grid>
                                    <Grid item xs={12} sm={1.9}>
                                      <Typography
                                        variant="body2"
                                        color={"#1e1f20"}
                                        gutterBottom
                                        sx={{
                                          mb: 0,
                                        }}
                                      >
                                        Alan Seçiniz
                                      </Typography>
                                      <Autocomplete
                                        clearIcon={null}
                                        disableClearable
                                        fullWidth
                                        size="small"
                                        value={(companyDetailsRef.current
                                          .class === 1
                                          ? fieldBalanceOptions
                                          : fieldBusinessOptions
                                        ).find(
                                          (option) =>
                                            option.value === subRule.field
                                        )}
                                        onChange={(event, newValue) => {
                                          if (
                                            companyDetailsRef.current.class ===
                                            1
                                          ) {
                                            handleRuleChange(
                                              rule.index,
                                              null,
                                              null,
                                              subIndex,
                                              {
                                                field: newValue?.value,
                                                input:
                                                  newValue?.value ===
                                                  "line.type"
                                                    ? "payable"
                                                    : newValue?.value ===
                                                      "invoice.type_code"
                                                    ? "SATIS"
                                                    : newValue?.value ===
                                                      "invoice.profile_id"
                                                    ? "TICARIFATURA"
                                                    : "",
                                                process:
                                                  newValue?.label.includes(
                                                    "(Liste)"
                                                  ) ||
                                                  newValue?.value ===
                                                    "invoice.payable_amount" ||
                                                  newValue?.value ===
                                                    "line.amount"
                                                    ? "includes"
                                                    : "==",
                                              }
                                            );
                                          } else {
                                            handleRuleChange(
                                              rule.index,
                                              null,
                                              null,
                                              subIndex,
                                              {
                                                field: newValue?.value,
                                                input: "",
                                                process:
                                                  newValue?.value ===
                                                    "invoice.receiver_tax" ||
                                                  newValue?.value ===
                                                    "invoice.sender_tax"
                                                    ? "=="
                                                    : "includes",
                                              }
                                            );
                                          }
                                        }}
                                        options={
                                          companyDetailsRef.current.class === 1
                                            ? fieldBalanceOptions
                                            : fieldBusinessOptions
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            size="small"
                                            sx={{
                                              backgroundColor:
                                                isEmpty &&
                                                (subRule.field === "" ||
                                                  subRule.field === null ||
                                                  subRule.field === undefined)
                                                  ? "#ff000031"
                                                  : undefined,
                                            }}
                                            {...params}
                                          />
                                        )}
                                      />
                                    </Grid>

                                    <Grid item xs={12} sm={1.9}>
                                      <Typography
                                        variant="body2"
                                        gutterBottom
                                        sx={{
                                          mb: 0,
                                        }}
                                      >
                                        {subRule.field === "line.type" ||
                                        subRule.field === "invoice.type_code" ||
                                        subRule.field === "invoice.profile_id"
                                          ? "Seçiniz"
                                          : "Metin / Değer Giriniz"}
                                      </Typography>
                                      {!(
                                        subRule.field === "line.type" ||
                                        subRule.field === "invoice.type_code" ||
                                        subRule.field === "invoice.profile_id"
                                      ) && (
                                        <TextField
                                          fullWidth
                                          size="small"
                                          value={subRule.input}
                                          sx={{
                                            display:
                                              subRule.field === "line.type" ||
                                              subRule.field ===
                                                "invoice.type_code" ||
                                              subRule.field ===
                                                "invoice.profile_id"
                                                ? "none"
                                                : "",
                                            backgroundColor:
                                              isEmpty &&
                                              (subRule.input === "" ||
                                                subRule.input === null ||
                                                subRule.input === undefined)
                                                ? "#ff000031"
                                                : undefined,
                                          }}
                                          placeholder={
                                            subRule.field ===
                                              "invoice.receiver_tax" ||
                                            subRule.field ===
                                              "invoice.sender_tax"
                                              ? "Örnek:9876543121"
                                              : subRule.field ===
                                                  "invoice.receiver_tax.List" ||
                                                subRule.field ===
                                                  "invoice.sender_tax.List"
                                              ? "Örnek:9876543121,12345678910"
                                              : "Örnek:abc,123,a1b2"
                                          }
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            let regex;

                                            if (
                                              subRule.field ===
                                                "invoice.receiver_tax" ||
                                              subRule.field ===
                                                "invoice.sender_tax" ||
                                              subRule.field ===
                                                "invoice.receiver_object.vkn_tckn" ||
                                              subRule.field ===
                                                "invoice.sender_object.vkn_tckn"
                                            ) {
                                              // Sadece sayıya izin ver ve 11 karakter sınırlaması
                                              regex = /^[0-9]{0,11}$/;
                                            } else if (
                                              subRule.field ===
                                                "invoice.receiver_tax.List" ||
                                              subRule.field ===
                                                "invoice.sender_tax.List" ||
                                              subRule.field ===
                                                "invoice.receiver_object.vkn_tckn.List" ||
                                              subRule.field ===
                                                "invoice.sender_object.vkn_tckn.List" ||
                                              subRule.field ===
                                                "invoice.accounting_lines.code.List"
                                            ) {
                                              // Sayı ve virgül kombinasyonuna izin ver
                                              regex = /^([0-9][0-9,]*|)$/;
                                            } else {
                                              // Herhangi bir girişe izin ver
                                              regex = /.*/; // Tüm karakterler serbest
                                            }

                                            if (regex.test(value)) {
                                              handleRuleChange(
                                                rule.index,
                                                null,
                                                null,
                                                subRule.subIndex,
                                                "input",
                                                value
                                              );
                                            }
                                          }}
                                        />
                                      )}

                                      {(subRule.field === "line.type" ||
                                        subRule.field === "invoice.type_code" ||
                                        subRule.field ===
                                          "invoice.profile_id") && (
                                        <Autocomplete
                                          fullWidth
                                          disableClearable
                                          clearIcon={null}
                                          size="small"
                                          isOptionEqualToValue={() => true}
                                          value={
                                            (subRule.field ===
                                            "invoice.profile_id"
                                              ? invoiceProfiles
                                              : subRule.field ===
                                                "invoice.type_code"
                                              ? invoiceTypes
                                              : systemLineTypes
                                            ).find(
                                              (option) =>
                                                option.value === subRule.input
                                            ) || subRule.input
                                          }
                                          onChange={(event, newValue) =>
                                            handleRuleChange(
                                              rule.index,
                                              null,
                                              null,
                                              subRule.subIndex,
                                              "input",
                                              newValue?.value
                                            )
                                          }
                                          options={
                                            subRule.field ===
                                            "invoice.profile_id"
                                              ? invoiceProfiles
                                              : subRule.field ===
                                                "invoice.type_code"
                                              ? invoiceTypes
                                              : systemLineTypes
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              size="small"
                                              sx={{
                                                display:
                                                  subRule.field ===
                                                    "line.type" ||
                                                  subRule.field ===
                                                    "invoice.type_code" ||
                                                  subRule.field ===
                                                    "invoice.profile_id"
                                                    ? ""
                                                    : "none",
                                                backgroundColor:
                                                  isEmpty &&
                                                  (subRule.field === "" ||
                                                    subRule.field === null ||
                                                    subRule.field === undefined)
                                                    ? "#ff000031"
                                                    : undefined,
                                              }}
                                              {...params}
                                            />
                                          )}
                                        />
                                      )}
                                    </Grid>

                                    <Grid item xs={12} sm={1.4}>
                                      <Typography
                                        variant="body2"
                                        gutterBottom
                                        sx={{
                                          mb: 0,
                                        }}
                                      >
                                        İşlem Seçiniz
                                      </Typography>
                                      <Autocomplete
                                        fullWidth
                                        disableClearable
                                        clearIcon={null}
                                        size="small"
                                        value={processOptions.find(
                                          (option) =>
                                            option.value === subRule.process
                                        )}
                                        onChange={(event, newValue) =>
                                          handleRuleChange(
                                            rule.index,
                                            null,
                                            null,
                                            subRule.subIndex,
                                            "process",
                                            newValue?.value
                                          )
                                        }
                                        options={processOptions.filter(
                                          (option) => {
                                            if (
                                              companyDetailsRef.current
                                                .class === 1
                                            ) {
                                              if (
                                                subRule.field ===
                                                  "invoice.sender_object.vkn_tckn" ||
                                                subRule.field ===
                                                  "invoice.receiver_object.vkn_tckn" ||
                                                subRule.field ===
                                                  "invoice.profile_id" ||
                                                subRule.field ===
                                                  "invoice.type_code" ||
                                                subRule.field === "line.code" ||
                                                subRule.field === "line.type"
                                              ) {
                                                return (
                                                  option.value !== "endsWith" &&
                                                  option.value !==
                                                    "startsWith" &&
                                                  option.value !==
                                                    "notIncludes" &&
                                                  option.value !== "includes"
                                                );
                                              } else if (
                                                subRule.field.includes(
                                                  ".List"
                                                ) ||
                                                subRule.field ===
                                                  "invoice.notes"
                                              ) {
                                                return (
                                                  option.value !== "endsWith" &&
                                                  option.value !==
                                                    "startsWith" &&
                                                  option.value !== "!=" &&
                                                  option.value !== "=="
                                                );
                                              } else {
                                                return option.value;
                                              }
                                            } else {
                                              if (
                                                subRule.field ===
                                                  "invoice.receiver_tax" ||
                                                subRule.field ===
                                                  "invoice.sender_tax"
                                              ) {
                                                return (
                                                  option.value !== "endsWith" &&
                                                  option.value !==
                                                    "startsWith" &&
                                                  option.value !==
                                                    "notIncludes" &&
                                                  option.value !== "includes" &&
                                                  option.value !== ">" &&
                                                  option.value !== "<" &&
                                                  option.value !== ">=" &&
                                                  option.value !== "<="
                                                );
                                              } else if (
                                                subRule.field ===
                                                  "invoice.receiver_tax.List" ||
                                                subRule.field ===
                                                  "invoice.sender_tax.List" ||
                                                subRule.field ===
                                                  "line?.name.List" ||
                                                subRule.field ===
                                                  "line.name.List"
                                              ) {
                                                return (
                                                  option.value !== "endsWith" &&
                                                  option.value !==
                                                    "startsWith" &&
                                                  option.value !== "!=" &&
                                                  option.value !== "==" &&
                                                  option.value !== ">" &&
                                                  option.value !== "<" &&
                                                  option.value !== ">=" &&
                                                  option.value !== "<="
                                                );
                                              } else {
                                                return (
                                                  option.value !== ">" &&
                                                  option.value !== "<" &&
                                                  option.value !== ">=" &&
                                                  option.value !== "<="
                                                );
                                              }
                                            }
                                          }
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            size="small"
                                            sx={{
                                              backgroundColor:
                                                isEmpty &&
                                                (subRule.process === "" ||
                                                  subRule.process === null ||
                                                  subRule.process === undefined)
                                                  ? "#ff000031"
                                                  : undefined,
                                            }}
                                            {...params}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    {companyDetailsRef.current.class === 1 && (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={1.2}
                                        sx={{
                                          display: rule.return.find(
                                            (item) =>
                                              item.subIndex === subRule.subIndex
                                          )?.borc
                                            ? ""
                                            : "none",
                                        }}
                                      >
                                        <Typography
                                          variant="body2"
                                          gutterBottom
                                          sx={{
                                            mb: 0,
                                          }}
                                        >
                                          Borç Seçiniz
                                        </Typography>
                                        <Autocomplete
                                          clearIcon={null}
                                          disableClearable
                                          fullWidth
                                          size="small"
                                          isOptionEqualToValue={() => true}
                                          value={
                                            companyDetailsRef.current.class ===
                                            1
                                              ? (() => {
                                                  const matchingOption =
                                                    debtAndReceivableOptions.find(
                                                      (option) =>
                                                        option.value ===
                                                        rule.return.find(
                                                          (item) =>
                                                            item.subIndex ===
                                                            subRule.subIndex
                                                        )?.borc
                                                    );

                                                  if (matchingOption) {
                                                    return matchingOption;
                                                  } else {
                                                    const debtValue =
                                                      rule.return.find(
                                                        (item) =>
                                                          item.subIndex ===
                                                          subRule.subIndex
                                                      )?.borc;
                                                    return debtValue
                                                      ? {
                                                          label: `"${debtValue}"`,
                                                          value: `"${debtValue}"`,
                                                        }
                                                      : debtAndReceivableOptions[0];
                                                  }
                                                })()
                                              : ""
                                          }
                                          onChange={async (event, newValue) => {
                                            if (newValue?.value === "special") {
                                              let specialRule =
                                                await AddSpecialRule();
                                              if (specialRule) {
                                                setDebtAndReceivableOptions(
                                                  (prevOptions) => [
                                                    ...prevOptions,
                                                    {
                                                      label: `${specialRule}`,
                                                      value: `${specialRule.substring(
                                                        1,
                                                        specialRule.length - 1
                                                      )}`,
                                                    },
                                                  ]
                                                );
                                              }
                                              handleRuleChange(
                                                rule.index,
                                                null,
                                                null,
                                                subRule.subIndex + 1,
                                                null,
                                                null,
                                                "borc",
                                                specialRule.substring(
                                                  1,
                                                  specialRule.length - 1
                                                )
                                              );
                                            } else {
                                              handleRuleChange(
                                                rule.index,
                                                null,
                                                null,
                                                subRule.subIndex + 1,
                                                null,
                                                null,
                                                "borc",
                                                newValue?.value
                                              );
                                            }
                                          }}
                                          options={(() => {
                                            const matchingOption =
                                              debtAndReceivableOptions.find(
                                                (option) =>
                                                  option.value ===
                                                  rule.return.find(
                                                    (item) =>
                                                      item.subIndex ===
                                                      subRule.subIndex
                                                  )?.borc
                                              );

                                            if (matchingOption) {
                                              return debtAndReceivableOptions;
                                            } else {
                                              const debtValue =
                                                rule.return.find(
                                                  (item) =>
                                                    item.subIndex ===
                                                    subRule.subIndex
                                                )?.borc;
                                              return debtValue
                                                ? [
                                                    ...debtAndReceivableOptions,
                                                    {
                                                      label: `"${debtValue}"`,
                                                      value: `"${debtValue}"`,
                                                    },
                                                  ]
                                                : debtAndReceivableOptions;
                                            }
                                          })()}
                                          renderInput={(params) => (
                                            <TextField
                                              size="small"
                                              sx={{
                                                backgroundColor:
                                                  isEmpty &&
                                                  (rule.return.find(
                                                    (item) =>
                                                      item.subIndex ===
                                                      subRule.subIndex
                                                  )?.borc === "" ||
                                                    rule.return.find(
                                                      (item) =>
                                                        item.subIndex ===
                                                        subRule.subIndex
                                                    )?.borc === null ||
                                                    rule.return.find(
                                                      (item) =>
                                                        item.subIndex ===
                                                        subRule.subIndex
                                                    )?.borc === undefined)
                                                    ? "#ff000031"
                                                    : undefined,
                                              }}
                                              {...params}
                                            />
                                          )}
                                        />
                                      </Grid>
                                    )}
                                    {companyDetailsRef.current.class === 1 && (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={1.2}
                                        sx={{
                                          display: rule.return.find(
                                            (item) =>
                                              item.subIndex === subRule.subIndex
                                          )?.alacak
                                            ? ""
                                            : "none",
                                        }}
                                      >
                                        <Typography
                                          variant="body2"
                                          gutterBottom
                                          sx={{
                                            mb: 0,
                                          }}
                                        >
                                          Alacak Seçiniz
                                        </Typography>
                                        <Autocomplete
                                          clearIcon={null}
                                          fullWidth
                                          disableClearable
                                          size="small"
                                          isOptionEqualToValue={() => true}
                                          value={
                                            companyDetailsRef.current.class ===
                                            1
                                              ? (() => {
                                                  const matchingOption =
                                                    debtAndReceivableOptions.find(
                                                      (option) =>
                                                        option.value ===
                                                        rule.return.find(
                                                          (item) =>
                                                            item.subIndex ===
                                                            subRule.subIndex
                                                        )?.alacak
                                                    );

                                                  if (matchingOption) {
                                                    return matchingOption;
                                                  } else {
                                                    const receivableValue =
                                                      rule.return.find(
                                                        (item) =>
                                                          item.subIndex ===
                                                          subRule.subIndex
                                                      )?.alacak;
                                                    return receivableValue
                                                      ? {
                                                          label: `"${receivableValue}"`,
                                                          value: `"${receivableValue}"`,
                                                        }
                                                      : debtAndReceivableOptions[0];
                                                  }
                                                })()
                                              : ""
                                          }
                                          onChange={async (event, newValue) => {
                                            if (newValue?.value === "special") {
                                              let specialRule =
                                                await AddSpecialRule();
                                              if (specialRule) {
                                                setDebtAndReceivableOptions(
                                                  (prevOptions) => [
                                                    ...prevOptions,
                                                    {
                                                      label: `${specialRule}`,
                                                      value: `${specialRule.substring(
                                                        1,
                                                        specialRule.length - 1
                                                      )}`,
                                                    },
                                                  ]
                                                );
                                              }

                                              handleRuleChange(
                                                rule.index,
                                                null,
                                                null,
                                                subRule.subIndex + 1,
                                                null,
                                                null,
                                                "alacak",
                                                specialRule.substring(
                                                  1,
                                                  specialRule.length - 1
                                                )
                                              );
                                            } else {
                                              handleRuleChange(
                                                rule.index,
                                                null,
                                                null,
                                                subRule.subIndex + 1,
                                                null,
                                                null,
                                                "alacak",
                                                newValue?.value
                                              );
                                            }
                                          }}
                                          options={(() => {
                                            const matchingOption =
                                              debtAndReceivableOptions.find(
                                                (option) =>
                                                  option.value ===
                                                  rule.return.find(
                                                    (item) =>
                                                      item.subIndex ===
                                                      subRule.subIndex
                                                  )?.alacak
                                              );

                                            if (matchingOption) {
                                              return debtAndReceivableOptions;
                                            } else {
                                              const receivableValue =
                                                rule.return.find(
                                                  (item) =>
                                                    item.subIndex ===
                                                    subRule.subIndex
                                                )?.alacak;
                                              return receivableValue
                                                ? [
                                                    ...debtAndReceivableOptions,
                                                    {
                                                      label: `"${receivableValue}"`,
                                                      value: `"${receivableValue}"`,
                                                    },
                                                  ]
                                                : debtAndReceivableOptions;
                                            }
                                          })()}
                                          renderInput={(params) => (
                                            <TextField
                                              size="small"
                                              sx={{
                                                backgroundColor:
                                                  isEmpty &&
                                                  (rule.return.find(
                                                    (item) =>
                                                      item.subIndex ===
                                                      subRule.subIndex
                                                  )?.alacak === "" ||
                                                    rule.return.find(
                                                      (item) =>
                                                        item.subIndex ===
                                                        subRule.subIndex
                                                    )?.alacak === null ||
                                                    rule.return.find(
                                                      (item) =>
                                                        item.subIndex ===
                                                        subRule.subIndex
                                                    )?.alacak === undefined)
                                                    ? "#ff000031"
                                                    : undefined,
                                              }}
                                              {...params}
                                            />
                                          )}
                                        />
                                      </Grid>
                                    )}

                                    {companyDetailsRef.current.class === 1 &&
                                      rule.return.find(
                                        (item) =>
                                          item.subIndex === subRule.subIndex
                                      )?.hesap_kodu && (
                                        <Grid item xs={12} sm={1.8}>
                                          <Typography
                                            variant="body2"
                                            gutterBottom
                                            sx={{
                                              mb: 0,
                                            }}
                                          >
                                            Hesap Kodu Seçiniz
                                          </Typography>
                                          <Autocomplete
                                            clearIcon={null}
                                            disableClearable
                                            fullWidth
                                            size="small"
                                            value={
                                              lucaAccountCodesRef.current.find(
                                                (option) =>
                                                  option.hesap_kodu ===
                                                  rule.return.find(
                                                    (item) =>
                                                      item.subIndex ===
                                                      subRule.subIndex
                                                  )?.hesap_kodu
                                              ) || {
                                                hesap_kodu: rule.return.find(
                                                  (item) =>
                                                    item.subIndex ===
                                                    subRule.subIndex
                                                )?.hesap_kodu, // Dize olmadan ekliyoruz
                                                hesap_adi: rule.return.find(
                                                  (item) =>
                                                    item.subIndex ===
                                                    subRule.subIndex
                                                )?.hesap_kodu,
                                              }
                                            }
                                            onChange={async (
                                              event,
                                              newValue
                                            ) => {
                                              if (
                                                newValue?.hesap_kodu ===
                                                "special"
                                              ) {
                                                let specialRule =
                                                  await AddSpecialRule();
                                                handleRuleChange(
                                                  rule.index,
                                                  null,
                                                  null,
                                                  subRule.subIndex + 1,
                                                  null,
                                                  null,
                                                  "hesap_kodu",
                                                  specialRule.substring(
                                                    1,
                                                    specialRule.length - 1
                                                  )
                                                );
                                              } else {
                                                handleRuleChange(
                                                  rule.index,
                                                  null,
                                                  null,
                                                  subRule.subIndex + 1,
                                                  null,
                                                  null,
                                                  "hesap_kodu",
                                                  newValue?.hesap_kodu
                                                );
                                              }
                                            }}
                                            options={options(
                                              rule.return.find(
                                                (item) =>
                                                  item.subIndex ===
                                                  subRule.subIndex
                                              )?.hesap_kodu
                                            )}
                                            getOptionLabel={getOptionLabel}
                                            renderInput={(params) => (
                                              <TextField
                                                size="small"
                                                sx={{
                                                  backgroundColor:
                                                    isEmpty &&
                                                    (rule.return.find(
                                                      (item) =>
                                                        item.subIndex ===
                                                        subRule.subIndex
                                                    )?.hesap_kodu === "" ||
                                                      rule.return.find(
                                                        (item) =>
                                                          item.subIndex ===
                                                          subRule.subIndex
                                                      )?.hesap_kodu === null ||
                                                      rule.return.find(
                                                        (item) =>
                                                          item.subIndex ===
                                                          subRule.subIndex
                                                      )?.hesap_kodu ===
                                                        undefined)
                                                      ? "#ff000031"
                                                      : undefined,
                                                }}
                                                {...params}
                                              />
                                            )}
                                          />
                                        </Grid>
                                      )}
                                    <Grid
                                      item
                                      xs={12}
                                      sm={0.5}
                                      sx={{
                                        marginBottom:
                                          companyDetailsRef.current.class === 1
                                            ? "0px"
                                            : "5px",
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <IconButton
                                        sx={{
                                          display:
                                            companyDetailsRef.current.class ===
                                            2
                                              ? "none"
                                              : rule.return.find(
                                                  (item) =>
                                                    item.subIndex ===
                                                    subRule.subIndex
                                                )
                                              ? "none"
                                              : "",

                                          padding: 0.5,
                                        }}
                                        onClick={() =>
                                          handleAddSubRule(
                                            rule.index,
                                            subRule.subIndex
                                          )
                                        }
                                        color="success"
                                      >
                                        <FaPlus size={13} />
                                      </IconButton>
                                      <IconButton
                                        sx={{
                                          display:
                                            companyDetailsRef.current.class ===
                                            2
                                              ? "none"
                                              : rule.return.find(
                                                  (item) =>
                                                    item.subIndex ===
                                                    subRule.subIndex
                                                )
                                              ? ""
                                              : "none",
                                          padding: 0.5,
                                        }}
                                        onClick={() =>
                                          handleRemoveRule(
                                            rule.index,
                                            null,
                                            subRule.subIndex
                                          )
                                        }
                                        color="error"
                                      >
                                        <FaMinus size={13} />
                                      </IconButton>
                                      <IconButton
                                        sx={{
                                          padding:
                                            companyDetailsRef.current.class ===
                                            2
                                              ? ""
                                              : 0.5,
                                        }}
                                        onClick={() =>
                                          handleRemoveRule(
                                            rule.index,
                                            subRule.subIndex
                                          )
                                        }
                                        color="error"
                                      >
                                        <FaRegTrashAlt
                                          size={
                                            companyDetailsRef.current.class ===
                                            2
                                              ? 15
                                              : 13
                                          }
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                ))}
                                <Grid
                                  item
                                  xs={12}
                                  sm={8}
                                  sx={{
                                    height: "auto",
                                    ml: rule.subRules.length > 0 ? "8%" : "5%",
                                    mt: 1,
                                    mb: 1,
                                  }}
                                >
                                  <Button
                                    variant="outlined"
                                    onClick={() => handleAddSubRule(rule.index)}
                                    size="small"
                                    sx={{
                                      textTransform: "none",
                                      color: "#ffa34c",
                                      borderColor: "#fff0e1",
                                      backgroundColor: "#fff0e1",
                                      "&:hover": {
                                        backgroundColor: "#eedbc7c0",
                                        borderColor: "#fff0e1",
                                      },
                                    }}
                                  >
                                    Ve
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    startIcon={<FaPlus size={14} />}
                    variant="outlined"
                    onClick={() => handleAddRule("end")}
                    sx={{
                      textTransform: "none",
                      color: "#35ca78", // Metin rengi
                      borderColor: "#35ca78", // Kenarlık rengi
                      backgroundColor: loading ? undefined : "#ddf6e8",
                      "&:hover": {
                        backgroundColor: "#a5fcb4a4",
                        borderColor: "#35ca78",
                      },
                    }}
                    disabled={loading}
                  >
                    Kural Ekle
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {modalId === "#copyRuleToCompanySwal" ? (
        <Grid>
          <CopyRuleToCompanySwal
            open={modalOpen}
            handleClose={handleModalClose}
            companyId={companyDetails?.companyId}
            periodId={companyDetails?.periodId}
            companyName={companyDetails?.name}
          ></CopyRuleToCompanySwal>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
}

export default InvoiceSettingsPage;
