import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  Alert,
  Divider,
  FormControl,
  Autocomplete,
} from "@mui/material";
import Swal from "sweetalert2";
import moment from "moment/moment.js";
import { createGibVATListToExclude } from "../../api/company/reports/createGibVATListToExclude/index.js";
import DateRangePicker from "../../components/DateRangePicker/index.js";
const uppercaseAlphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
const dateFormats = [
  { value: "DD.MM.YYYY", label: "GG.AA.YYYY" },
  { value: "DD-MM-YYYY", label: "GG-AA-YYYY" },
  { value: "YYYY-MM-DD", label: "YYYY-AA-GG" },
  { value: "YYYY.MM.DD", label: "YYYY.AA.GG" },
  { value: "MM/DD/YYYY", label: "AA/GG/YYYY" },
  { value: "DD/MM/YYYY", label: "GG/AA/YYYY" },
  { value: "YYYY/MM/DD", label: "YYYY/AA/GG" },
  { value: "MM-DD-YYYY", label: "AA-GG-YYYY" },
  { value: "YYYY-DD-MM", label: "YYYY-GG-AA" },
  { value: "MM.DD.YYYY", label: "AA.GG.YYYY" },
  { value: "YYYY/DD/MM", label: "YYYY/GG/AA" },
];
const separatorOptions = [
  { value: "", label: "Yok" },
  { value: ",", label: ", (virgül)" },
  { value: ".", label: ". (nokta)" },
];
export const CreateGibVATListToExcludeSwal = ({
  open,
  handleClose,
  companyDetails,
  setUpdateRow,
}) => {
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "month").startOf("month")
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(1, "month").endOf("month")
  );

  const [selectedDateFormat, setSelectedDateFormat] = useState("");
  const [selectedDateCol, setSelectedDateCol] = useState("");
  const [selectedNumberCol, setSelectedNumberCol] = useState("");
  const [selectedAmountCol, setSelectedAmountCol] = useState("");
  const [selectedSenderCol, setSelectedSenderCol] = useState("");
  const [selectedThousandSeparate, setSelectedThousandSeparate] = useState("");
  const [selectedFractionSeparate, setSelectedFractionSeparate] = useState("");

  const [files, setFiles] = useState([]);
  const [fileBase64, setFileBase64] = useState("");
  const fileInputRef = useRef(null);
  const isOptionDisabled = (option, currentValue, otherValue) => {
    if (option.value === "") return false;
    if (!otherValue) return false;
    return option.value === otherValue.value;
  };
  useEffect(() => {
    setFiles([]);
  }, [handleClose]);

  const isXlsFile = (file) =>
    file.name.endsWith(".xls") || file.name.endsWith(".xlsx");

  const handleFileSelect = (event) => {
    const file = event.target.files[0]; // Tek bir dosya seçimi
    if (file) {
      if (isXlsFile(file)) {
        // Dosyayı Base64'e dönüştür
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          const base64String = reader.result.split(",")[1]; // Dosyanın Base64 hali
          setFileBase64(base64String);
          setFiles([file]); // Dosyayı dizinin içine alıyoruz
        };

        reader.onerror = (error) => {
          console.error("Dosya okuma hatası: ", error);
        };
      } else {
        alert("Yalnızca .xls veya .xlsx uzantılı dosyalar seçebilirsiniz.");
      }
    } else {
      handleCancel(event);
      return;
    }
  };

  const handleUploadClick = () => {
    if(files?.length>0){ 
 if (!selectedDateCol || selectedDateCol === "") {
      Swal.fire({
        title: "Uyarı!",
        text: "Lütfen bir Tarih sütunu seçin.",
        icon: "warning",
        didOpen: () => {
          document.querySelector(".swal2-container").style.zIndex = "1500";
        },
      });
      return false;
    }
    if (!selectedDateFormat || selectedDateFormat === "") {
      Swal.fire({
        title: "Uyarı!",
        text: "Lütfen bir Tarih Formatı seçin.",
        icon: "warning",
        didOpen: () => {
          document.querySelector(".swal2-container").style.zIndex = "1500";
        },
      });
      return false;
    }

    if (!selectedNumberCol || selectedNumberCol === "") {
      Swal.fire({
        title: "Uyarı!",
        text: "Lütfen bir Fatura No sütunu seçin.",
        icon: "warning",
        didOpen: () => {
          document.querySelector(".swal2-container").style.zIndex = "1500";
        },
      });
      return false;
    }
    if (!selectedAmountCol || selectedAmountCol === "") {
      Swal.fire({
        title: "Uyarı!",
        text: "Lütfen bir Tutar sütunu seçin.",
        icon: "warning",
        didOpen: () => {
          document.querySelector(".swal2-container").style.zIndex = "1500";
        },
      });
      return false;
    }

    if (!selectedSenderCol || selectedSenderCol === "") {
      Swal.fire({
        title: "Uyarı!",
        text: "Lütfen bir Gönderici sütunu seçin.",
        icon: "warning",
        didOpen: () => {
          document.querySelector(".swal2-container").style.zIndex = "1500";
        },
      });
      return false;
    }
    var selectedColumns = [
      selectedDateCol,
      selectedDateFormat,
      selectedAmountCol,
      selectedSenderCol,
      selectedNumberCol,
    ];
    for (var i = 0; i < selectedColumns.length; i++) {
      for (var j = i + 1; j < selectedColumns.length; j++) {
        if (
          selectedColumns[i] &&
          selectedColumns[j] &&
          selectedColumns[i] === selectedColumns[j]
        ) {
          Swal.fire({
            title: "Uyarı!",
            text: "Aynı sütunlar birden fazla kez seçilemez.",
            icon: "warning",
            didOpen: () => {
              document.querySelector(".swal2-container").style.zIndex =
                "1500";
            },
          });
          return false;
        }
      }
    }
    }
    let extractData={}
    if(files.length > 0){
      extractData= {
      amountColumn: selectedAmountCol,
      senderNameColumn: selectedSenderCol,
      assistantExcelBase64: fileBase64,
      dateColumn: selectedDateCol,
      dateFormat: selectedDateFormat?.value?selectedDateFormat.value:"",
      numberColumn: selectedNumberCol,
      decimalSeparator: selectedFractionSeparate.value?selectedFractionSeparate.value:"",
      thousandSeparator: selectedThousandSeparate.value?selectedThousandSeparate.value:"",
    };  
    }
    

    Swal.fire({
      title: "Rapor Oluşturuluyor...",
      text: "Lütfen bekleyiniz",
      icon: "info",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        popup: "swal2-content-centered",
      },
      willOpen: () => {
        Swal.showLoading();
      },
      didOpen: () => {
        document.querySelector(".swal2-container").style.zIndex = "9999";
      },
    });

    createGibVATListToExclude(
      companyDetails?.companyId,
      companyDetails?.periodId,
      startDate,
      endDate,
      extractData,
    )
      .then((resp) => {
        setUpdateRow(true);
        Swal.fire({
          title: "Başarılı!",
          text: resp.data.message,
          icon: "success",
          confirmButtonText: "Tamam",
          didOpen: () => {
            document.querySelector(".swal2-container").style.zIndex = "9999";
          },
        });
        handleClose();
      })
      .catch((err) => {
        Swal.fire({
          title: "Bir hata oluştu!",
          text: err.response.data.message,
          icon: "error",
          confirmButtonText: "Tamam",
          didOpen: () => {
            document.querySelector(".swal2-container").style.zIndex = "9999";
          },
        });
        handleClose();
      });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleCancel = (event) => {
    event.stopPropagation();
    setFiles([]);
    setFileBase64("")
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Modal open={open} onClose={handleClose} >
      <Box
        sx={{
          position: "absolute",
          top: "5%",
          left: "50%",
          transform: "translate(-50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: "30%",
          maxHeight:"80%",
          border: "2px solid transparent",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <Button
          onClick={handleClose}
          sx={{
            borderRadius: 2,
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: "whitesmoke",
            color: "gray",
            boxShadow: 4,
            minWidth: "0px",
            width: "30px",
            height: "30px",
            border: "2px solid transparent",
            "&:hover": {
              backgroundColor: "#e7eaeb",
            },
          }}
        >
          X
        </Button>
        <Typography variant="h6" component="h2" align="center" gutterBottom>
          Excel Dosyasını Seçiniz
        </Typography>
        <Grid>
          <div
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            className="drop-container"
            onClick={handleButtonClick}
          >
            {files.length > 0 ? (
              <span
                className="drop-title"
                sx={{ display: "flex", alignItems: "center" }}
              >
                Yüklenen EXCEL Dosyası:
                <Typography sx={{ ml: 1 }}>
                  {files.length > 0 ? files[0].name : "Hiçbir dosya seçilmedi"}
                </Typography>
              </span>
            ) : (
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyItems: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <span className="drop-title">Dosyayı buraya sürükleyin</span>
                <Typography>veya</Typography>
                <span className="drop-title">Tıklayarak Seçin</span>
              </span>
            )}
          </div>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            style={{ display: "none" }}
            accept=".xls,.xlsx"
          />
        </Grid>
        <Grid container sx={{ gap: 1, mt: 3 }}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DateRangePicker
              setStartDate={setStartDate}
              startDate={startDate}
              endDate={endDate}
              setEndDate={setEndDate}
              isLimitedRangeForMonth={true}
            />
          </Grid>
          {files.length !== 0 && (
            <Grid
              container
              xs={12}
              md={12}
              sx={{
                justifyContent: "space-around",
                mt: 2,
                overflowY:"auto",
                gap: 3,
              }}
            >
              <Grid item xs={12}>
                <Divider
                  variant="middle"
                  sx={{
                    "&::before, &::after": {
                      borderColor: "#e08434",
                      border: "1px solid #e08434",
                    },
                  }}
                >
                  <Typography
                    variant="overline"
                    display="block"
                    sx={{ textTransform: "none", margin: 0 }}
                  >
                    Sütun Seçimi
                  </Typography>
                </Divider>
              </Grid>
              <Grid item xs={5.7} sx={{display:"flex",justifyContent:"space-between"}}>
                  <Grid item xs={4.4}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={uppercaseAlphabet}
                    value={selectedDateCol}
                    onChange={(event, newValue) => {
                      setSelectedDateCol(newValue);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                        const highlightedOption = event.target.querySelector(
                          'li[data-focus="true"]'
                        );
                        if (highlightedOption) {
                          highlightedOption.click();
                        }
                      }
                    }}
                    autoHighlight
                    selectOnFocus
                    blurOnSelect
                    openOnFocus
                    disablePortal
                    ListboxProps={{ style: { maxHeight: "150px" } }}
                
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tarih Sütununu Seçiniz"
                        variant="outlined"
                        size="small"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderTopRightRadius: "0px",
                            borderBottomRightRadius: "0px",
                          },
                        }}
                      />
                    )}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={7.6}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={dateFormats}
                    value={selectedDateFormat}
                    onChange={(event, newValue) => {
                      setSelectedDateFormat(newValue);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                        const highlightedOption = event.target.querySelector(
                          'li[data-focus="true"]'
                        );
                        if (highlightedOption) {
                          highlightedOption.click();
                        }
                      }
                    }}
                    autoHighlight
                    selectOnFocus
                    blurOnSelect
                    openOnFocus
                    disablePortal
                    ListboxProps={{ style: { maxHeight: "150px" } }}
                
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tarih Formatını Seçiniz"
                        variant="outlined"
                        size="small"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px",
                          },
                        }}
                      />
                    )}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </Grid>

              </Grid>
              
              <Grid item xs={5.7}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={uppercaseAlphabet}
                    value={selectedNumberCol}
                    onChange={(event, newValue) => {
                      setSelectedNumberCol(newValue);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                        const highlightedOption = event.target.querySelector(
                          'li[data-focus="true"]'
                        );
                        if (highlightedOption) {
                          highlightedOption.click();
                        }
                      }
                    }}
                    autoHighlight
                    selectOnFocus
                    blurOnSelect
                    openOnFocus
                    disablePortal
                    ListboxProps={{ style: { maxHeight: "150px" } }}
                
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Fatura No Sütununu Seçiniz"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5.7}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={uppercaseAlphabet}
                    value={selectedSenderCol}
                    onChange={(event, newValue) => {
                      setSelectedSenderCol(newValue);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                        const highlightedOption = event.target.querySelector(
                          'li[data-focus="true"]'
                        );
                        if (highlightedOption) {
                          highlightedOption.click();
                        }
                      }
                    }}
                    autoHighlight
                    selectOnFocus
                    blurOnSelect
                    openOnFocus
                    disablePortal
                    ListboxProps={{ style: { maxHeight: "100px" } }}
                
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Gönderici Sütununu Seçiniz"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={5.7}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={uppercaseAlphabet}
                    value={selectedAmountCol}
                    onChange={(event, newValue) => {
                      setSelectedAmountCol(newValue);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                        const highlightedOption = event.target.querySelector(
                          'li[data-focus="true"]'
                        );
                        if (highlightedOption) {
                          highlightedOption.click();
                        }
                      }
                    }}
                    autoHighlight
                    selectOnFocus
                    blurOnSelect
                    openOnFocus
                    disablePortal
                    ListboxProps={{ style: { maxHeight: "100px" } }}
                
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tutar Sütununu Seçiniz"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </Grid>
              <Grid container xs={12} sx={{ justifyContent: "space-between" }}>
                <Grid item xs={5.7} sx={{ mb: 3 }}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={separatorOptions}
                      value={selectedThousandSeparate}
                      onChange={(event, newValue) => {
                        setSelectedThousandSeparate(newValue);
                      }}
                      getOptionDisabled={(option) =>
                        isOptionDisabled(
                          option,
                          selectedThousandSeparate,
                          selectedFractionSeparate
                        )
                      }
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                          const highlightedOption = event.target.querySelector(
                            'li[data-focus="true"]'
                          );
                          if (highlightedOption) {
                            highlightedOption.click();
                          }
                        }
                      }}
                      autoHighlight
                      selectOnFocus
                      blurOnSelect
                      openOnFocus
                      disablePortal
                      ListboxProps={{ style: { maxHeight: "100px" } }}
                  
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Binlik Ayıracını Seçiniz"
                          variant="outlined"
                          size="small"
                        />
                      )}
                      style={{ width: "100%" }}
                    />
                    <Typography
                      variant="overline"
                      display="block"
                      sx={{ textTransform: "none", margin: 0 }}
                    >
                      (opsiyonel)
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={5.7}>
                  <FormControl fullWidth>
                    <Autocomplete
                      options={separatorOptions}
                      value={selectedFractionSeparate}
                      onChange={(event, newValue) => {
                        setSelectedFractionSeparate(newValue);
                      }}
                      getOptionDisabled={(option) =>
                        isOptionDisabled(
                          option,
                          selectedFractionSeparate,
                          selectedThousandSeparate
                        )
                      }
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
                          const highlightedOption = event.target.querySelector(
                            'li[data-focus="true"]'
                          );
                          if (highlightedOption) {
                            highlightedOption.click();
                          }
                        }
                      }}
                      autoHighlight
                      selectOnFocus
                      blurOnSelect
                      openOnFocus
                      disablePortal
                      ListboxProps={{ style: { maxHeight: "100px" } }}
                      
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Küsurat Ayıracını Seçiniz"
                          variant="outlined"
                          size="small"
                        />
                      )}
                      style={{ width: "100%" }}
                    />
                    <Typography
                      variant="overline"
                      display="block"
                      sx={{ textTransform: "none", margin: 0 }}
                    >
                      (opsiyonel)
                    </Typography>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              className={"custom-button"}
              onClick={() => {
                handleUploadClick();
              }}
              sx={{ mr: 2, width: "auto", textTransform: "none" }}
              
              disabled={false}
            >
              Yükle
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              İptal
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
