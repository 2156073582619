/* eslint-disable react-hooks/exhaustive-deps */
//todo MODAL AÇILMASI GEREKTİREN İŞLEMLER SONU MODAL İLE BİTEN DOSYALARDA(fatura seçilerek yapılan işlemlerde),
//todo DİREKT İSTEKLE HALLEDİLEBİLİR İŞLEMLER 'ProcessesAndProcessMenu' DOSYASINDADIR.

import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";

// Grids---------
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import gridSideBar from "../../../components/GridSideBar/gridSideBar.js";

import { createColumnDefs } from "./gridOptionsAndcolumnDefs.js";
import localeTextTr from "../../../locale.tr.js";

//Components
import DateRangePicker from "../../../components/DateRangePicker/index.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import localStorage from "local-storage";

//Modals and Menus
import ProcessMenu from "./processesAndProcessMenu.js";


//Icons
import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
//Icons

//Apis
import { getAdvisorCompanyList } from "../../../api/advisor/getAdvisorCompanyList/index.js";
import { CopyRuleToSelectedCompaniesSwal} from "./copyRuleToSelectedCompaniesSwal.js";
import { GetSelectedCompaniesEArchiveInvoices } from "./getSelectedCompaniesEArchiveInvoices.js";
import { createTurkishFilterParams } from "../../../utils/agGridTextFilterTr/agGridFilterUtils.js";

//Apis

function TaxPayerPage() {
  const [isOpen, setIsOpen] = useState(false);

  //modals açılma durumlar
  const [modalId, setModalId] = useState("1");
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  //--------

  const [loading, setLoading] = useState(true);
  const [firstLoading, setFirstLoading] = useState(true); //SAYFA İLK AÇTIĞINDA getListInvoices ve companies'e İSTEK ATMAMASI İÇİN FLAG

  //Modallarda Yapılan işlemlerde sonra tablonun güncellemesi için
  const [updateRow, setUpdateRow] = useState(false);

  useEffect(() => {
    if (updateRow === true) {
      setLoading(true);
      getAdvisorCompanyList(
      )
        .then((resp) => {
          const companies = resp.data.companies;
        const updatedCompanies = companies.map(company => ({
          ...company,  // Diğer tüm verileri aynı bırak
          class: company.class === 1 ? 'İşletme' : company.class === 2 ? 'Bilanço' : company.class 
        }));
        setRowData(updatedCompanies);
          
          setFilteredCount(resp.data.companies.length);
          setUpdateRow(false);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setUpdateRow(false);
        });
    }
  }, [updateRow]);
  //Modallarda Yapılan işlemlerde sonra tablonun güncellemesi için

  const location = useLocation();

  const [rowData, setRowData] = useState([]);
  const gridRef = useRef(null);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedCompaniesDetails, setSelectedCompaniesDetails] = useState([]);
  const [filteredCount, setFilteredCount] = useState(0);
  
  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    setSelectedCompaniesDetails(selectedRows)
    const selectedData = selectedRows.map((detail) => detail.id);
    setSelectedCompanies(selectedData);
  };

  const [startDate, setStartDate] = useState(
    moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD HH:mm:ss")
  );

  const handlePreviousMonth = () => { 
    setStartDate(moment(startDate).subtract(1, "month").startOf("month"));
    setEndDate(moment(endDate).subtract(1, "month").endOf("month"));
  };

  const handleNextMonth = () => {
    setStartDate(moment(startDate).add(1, "month").startOf("month"));
    setEndDate(moment(endDate).add(1, "month").endOf("month"));
  };

  useEffect(() => {
    const sideBarOpen = localStorage.get("sidebar");
    if (sideBarOpen === "false") {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }

    getAdvisorCompanyList(
    )
      .then((resp) => {
        
        const companies = resp.data.companies;
        const updatedCompanies = companies.map(company => ({
          ...company,  // Diğer tüm verileri aynı bırak
          class: company.class === 1 ? 'İşletme' : company.class === 2 ? 'Bilanço' : company.class 
        }));
        setRowData(updatedCompanies);
        setFilteredCount(resp.data.companies.length);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {


    setStartDate(
      moment()
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD HH:mm:ss")
    );
    setEndDate(
      moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD HH:mm:ss")
    );


    if (loading === true && firstLoading === false) {
      getAdvisorCompanyList()
        .then((resp) => {
          setRowData(resp.data.companies);
          setFilteredCount(resp.data.companies.length)
          setLoading(false);
          setFirstLoading(true);
        })
        .catch((err) => {
          setLoading(false);
          setFirstLoading(true);
        });
    }
  }, [location.pathname]);

 
  const onFilterChanged = useCallback((event) => {
    const filteredRows = event.api.getModel().getRowCount(); // Filtrelenmiş veri sayısını alıyoruz
    setFilteredCount(filteredRows);
  }, []);
  
  const onRowDataUpdated = useCallback((event) => {
    const filteredRows = event.api.getDisplayedRowCount(); // Filtrelenmiş veri sayısını alıyoruz
    setFilteredCount(filteredRows);
  }, []);

  const defaultColDef = useMemo(
    () => ({
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filterParams:createTurkishFilterParams(),
    }),
    []
  );

  const columnDefs = useMemo(
    () => createColumnDefs(),
  );


  const handleSearch = () => {

    setLoading(true);
    getAdvisorCompanyList()
      .then((resp) => {
        const companies = resp.data.companies;
        const updatedCompanies = companies.map(company => ({
          ...company,  // Diğer tüm verileri aynı bırak
          class: company.class === 1 ? 'İşletme' : company.class === 2 ? 'Bilanço' : company.class 
        }));
        setRowData(updatedCompanies);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getMainMenuItems = (params) => {
    const allMenuItems = params.defaultItems;
    const itemsToRemove = ["toolPanelButton"]; // 'choose columns' seçeneği

    return allMenuItems.filter((item) => !itemsToRemove.includes(item));
  };

  return (
    <Grid container>
        <Grid item md={12} sx={{ display: "flex", flexDirection: "column" }}>
          <Grid
            className="grid-area"
            sx={{
              padding: 1,
              mb: 1,
              alignItems: "center",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid sx={{ display: "flex", alignItems: "center" }}>
                <LocalizationProvider
                  dateAdapter={AdapterMoment}
                  adapterLocale="tr"
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      pl: 1,
                      pt: 0.5,
                    }}
                  >
                    <IconButton
                      onClick={handlePreviousMonth}
                      className="date-button"
                    >
                      <KeyboardDoubleArrowLeftOutlinedIcon fontSize="small"></KeyboardDoubleArrowLeftOutlinedIcon>
                    </IconButton>
                    <DateRangePicker
                      setStartDate={setStartDate}
                      startDate={startDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                      isLimitedRangeForMonth={true}
                    />
                    <IconButton
                      onClick={handleNextMonth}
                      className="date-button"
                    >
                      <KeyboardDoubleArrowRightOutlinedIcon fontSize="small"></KeyboardDoubleArrowRightOutlinedIcon>
                    </IconButton>
                    <Button
                      variant="contained"
                      className="custom-button"
                      startIcon={<SearchIcon />}
                      onClick={() => {
                        handleSearch();
                      }}
                      sx={{
                        width: "5rem",
                        ml: 2,
                      }}
                    >
                      Ara
                    </Button>
                  </Grid>
                </LocalizationProvider>
              </Grid>
              <Grid sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <ProcessMenu
                  startDate={startDate}
                  endDate={endDate}
                  setModalId={setModalId}
                  handleModalOpen={handleModalOpen}
                  setUpdateRow={setUpdateRow}
                ></ProcessMenu>
                {modalId === "#copyRuleToSelectedCompanies" ? (
                  <CopyRuleToSelectedCompaniesSwal
                  open={modalOpen}
                  handleClose={handleModalClose}
                  selectedCompanies={selectedCompanies}
                  companiesList={rowData}
                  setUpdateRow={setUpdateRow}
                  />
                ) : modalId === "#getSelectedCompaniesEArchiveInvoices" ? (
                  <Grid>
                    <GetSelectedCompaniesEArchiveInvoices
                      open={modalOpen}
                      handleClose={handleModalClose}
                      selectedCompaniesDetails={selectedCompaniesDetails}
                      startDate={startDate}
                      endDate={endDate}
                      setUpdateRow={setUpdateRow}
                    ></GetSelectedCompaniesEArchiveInvoices>
                  </Grid>
                ) : (
                  ""
                )}
                
              </Grid>
            </Grid>
          </Grid>

          <Box
            className="grid-area"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 0,
              backgroundColor: "#ffffff",
              p: 2,mb:2
            }}
          >
            <Grid
              className="ag-theme-quartz"
              style={{
                minHeight:rowData.length>6?"85vh":"60vh",
                width: "100%",
                borderRadius: "10%",
              }}
            >
              <AgGridReact
                ref={gridRef}
                onSelectionChanged={onSelectionChanged}
                onFilterChanged={onFilterChanged}
                onRowDataUpdated={onRowDataUpdated}
                localeText={localeTextTr}
                rowSelection="multiple"
                loading={loading}
                animateRows={true}
                rowDragManaged={true}
                rowMultiSelectWithClick={true}
                readOnlyEdit={true}
                rowData={rowData}
                columnDefs={columnDefs}
                enableRangeSelection={true}
                sideBar={gridSideBar.gridSideBar}
                copyHeadersToClipboard={false}
                defaultColDef={defaultColDef}
                getMainMenuItems={getMainMenuItems}
              />
            </Grid>
            <Typography
              sx={{
                backgroundColor: "white",
                fontWeight: 400,
                color: "#605f66",
                ml: 1,
                mt: 1,
                mb: 1,
              }}
            >
              Ekrandaki Mükellef Sayısı: <b>{filteredCount}</b>
            </Typography>
          </Box>
        </Grid>
      
    </Grid>
  );
}

export default TaxPayerPage;
