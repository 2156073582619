import Swal from "sweetalert2";
import { createLucaAccount } from "../../api/company/invoices/createLucaAccount";
import { showLoadingSwal } from "../loadingSwal";
// Yükleniyor Swal bileşeni
export const CreateLucaAccountSwal = (companyId,periodId) => {
  
  Swal.fire({
    title: "Luca Hesap Ekle",
    html: `
<div id="swal-content" style="width: 90%;">
<div >
<label for="swal-input1" style="display: block;">Hesap Adı</label>
<input id="swal-input1" class="swal2-input" placeholder="Hesap Adı" style="margin-bottom: 10px; width: 100%; height: 40px; font-size: 0.875rem;"/>
</div>
<div style="margin-bottom: 10px;">
<label for="swal-input2" style="display: block; margin-bottom: 5px;">Hesap Kodu</label>
<input id="swal-input2" class="swal2-input" placeholder="Hesap Kodu" style="margin-bottom: 10px; width: 100%; height: 40px; font-size: 0.875rem;"/>
</div>
</div>
`,
    showCancelButton: true,
    confirmButtonText: "Ekle",
    cancelButtonText: "İptal",
    preConfirm: () => {
      const input1 = document.getElementById("swal-input1").value;
      const input2 = document.getElementById("swal-input2").value;

      if (!input1 || !input2) {
        Swal.showValidationMessage(
          "Hesap kodu ve açıklama gerekli!"
        );
      } else {
        return { input1, input2 }; // İki girdi de döndürülüyor
      }
    },
    didOpen: () => {
      const swalPopup =
        document.querySelector(".swal2-container");
      if (swalPopup) {
        swalPopup.style.zIndex = "9999";
      }
    },

  }).then(async (result) => {
    if (result.isConfirmed) {
      showLoadingSwal()
      createLucaAccount(companyId,periodId,result.value.input2,result.value.input1)
        .then((resp) => {
          Swal.fire({
            title: "Başarılı",
            text: resp.data.message,
            icon: "success",
            confirmButtonText: "Tamam",
            didOpen: () => {
              const swalPopup =
                document.querySelector(".swal2-container");
              if (swalPopup) {
                swalPopup.style.zIndex = "9998";
              }
            },
          });
        })
        .catch((err) => {
          Swal.fire({
            title: "Bir hata oluştu!",
            text: err.response.data.message
              ? err.response.data.message
              : "Hata Oluştu",
            icon: "error",
            confirmButtonText: "Tamam",
            didOpen: () => {
              const swalPopup = document.querySelector(".swal2-container");
              if (swalPopup) {
                swalPopup.style.zIndex = "9999";
              }
            },
          });
        });
    }
  });

};
