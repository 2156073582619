import { useEffect,} from "react";
import Swal from "sweetalert2";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Autocomplete, TextField } from "@mui/material";
import { createExcelForInvoicesLines } from "../../api/company/reports/createExcelForInvoicesLines";
// Yükleniyor Swal bileşeni
const MySwal = withReactContent(Swal);
// Yükleniyor Swal bileşeni
export const ExportInvoicestoExcelRowBasedSwal = ({
  open,
  handleClose,
  companyDetails,
  setUpdateRow
}) => {
  useEffect(() => {
    if (open) {
      let startDate = moment()
        .subtract(1, "month")
        .startOf("month")
      let endDate = moment()
        .subtract(1, "month")
        .endOf("month")
      let direction = "";

      MySwal.fire({
        title: "Tarih Aralığı Seçin",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Devam",
        cancelButtonText: "İptal",
        focusConfirm: false,
        html: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="tr"
              >
                <DatePicker
                  label="Başlangıç Tarihi"
                  value={moment(startDate)}
                  onChange={(newValue) => {
                    startDate = moment(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input-field"
                      size="small"
                      {...params}
                    />
                  )}
                />
                <DatePicker
                  label="Bitiş Tarihi"
                  value={moment(endDate)}
                  onChange={(newValue) => {
                    endDate = moment(newValue);
                  }}
                  sx={{ marginLeft: 2 }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      className="input-field"
                      sx={{ color: "black" }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>

            <Autocomplete
              disablePortal
              options={[
                { value: "incoming", label: "Gelen" },
                { value: "outgoing", label: "Giden" },
              ]}
              sx={{ width: "40%", mt: 2 }}
              autoHighlight
              selectOnFocus
              blurOnSelect
              disableClearable
              onChange={(event, newValue) => {
                direction = newValue.value;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Fatura Yönü Seçiniz..."
                  className="input-field"
                />
              )}
            />
          </div>
        ),
        preConfirm: () => {
          const direct = direction;
          if (!direct||!startDate||!endDate) {
            MySwal.showValidationMessage("Lütfen tüm alanları doldurun");
            return false; // Bu, Swal penceresini açık tutar ve form onayını engeller
          }
          return { startDate, endDate, direct };
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const { startDate, endDate, direct } = result.value;
          Swal.fire({
            title: "Rapor Oluşturuluyor...",
            text: "Lütfen bekleyiniz",
            icon: "info",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
              popup: "swal2-content-centered",
            },
            willOpen: () => {
              Swal.showLoading();
            },
          });
          createExcelForInvoicesLines(
            companyDetails?.companyId,
          companyDetails?.periodId,
            startDate,
            endDate,
            direct
          )
            .then((resp) => {
              setUpdateRow(true)

              Swal.fire({
                title: "Başarılı!",
                text: resp.data.message,
                icon: "success",
                confirmButtonText: "Tamam",
              });
            })
            .catch((err) => {
              Swal.fire({
                title: "Bir hata oluştu!",
                text: err.response.data.message,
                icon: "error",
                confirmButtonText: "Tamam",
              });
            });
        }
      });

      handleClose();
    }
  }, [open, handleClose]);

  return null;
};
