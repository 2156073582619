//TODO  BU SAYFADA MASTER GRİD(SUBDETAILS) AYARLARI VE COLDEFS AYARLARI BULUNUR.
//TODO  ANA GRİDİN column ayarları ulunur

import moment from "moment";
import sideBar from "../../../components/GridSideBar/gridSideBar.js";
import localeTextTr from "../../../locale.tr.js";
import { TbX } from "react-icons/tb";
import { FaRegCircleCheck } from "react-icons/fa6";
import { Typography } from "@mui/material";
import {createTurkishFilterParams} from "../../../utils/agGridTextFilterTr/agGridFilterUtils.js";


const StatusRendererisActive = (props) => {
  const { isActive } = props;
  if (isActive) {
    return (
      <span
        title="Aktif"
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor:"#28c76f",
          gap:2,
          
          borderRadius: "7px",
          padding: "0px 12px",
          
          width: "45px",
          height: "25px",
          fontSize:"11px",
          color:"#ffffff"
        }}
      ><Typography fontSize={12}marginRight={0.5}>Aktif</Typography> 
        <FaRegCircleCheck style={{ color: "#ffffff" }} size={13} />
      </span>
    );
  }  else {
    return(
        <span
        title="Pasif"
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor:"red",
          gap:4,
          borderRadius: "7px",
          padding: "0px 12px",
          width: "45px",
          height: "25px",
          fontSize:"11px",
          color:"#ffffff"
        }}
      ><Typography fontSize={12} marginRight={0.5}>Pasif</Typography> 
        <TbX
          style={{
            color: "white",
          }}
         size={16}
        />
      </span>
    );
  } 
  
};
const StatusRendererAutoInvoice = (props) => {
  const { isAutoInvoiceFetchingEnabled } = props;
  if (isAutoInvoiceFetchingEnabled) {
    return (
      <span
        title="Açık"
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor:"#28c76f",
          gap:2,
          
          borderRadius: "7px",
          padding: "0px 12px",
          
          width: "45px",
          height: "25px",
          fontSize:"11px",
          color:"#ffffff"
        }}
      ><Typography fontSize={12}marginRight={0.5}>Açık</Typography> 
        <FaRegCircleCheck style={{ color: "#ffffff" }} size={13} />
      </span>
    );
  }  else {
    return(
        <span
        title="Kapalı"
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor:"red",
          gap:4,
          borderRadius: "7px",
          padding: "0px 12px",
          width: "55px",
          height: "25px",
          fontSize:"11px",
          color:"#ffffff"
        }}
      ><Typography fontSize={12} marginRight={0.5}>Kapalı</Typography> 
        <TbX
          style={{
            color: "white",
          }}
         size={16}
        />
      </span>
    );
  }
  
};

export const createColumnDefs = (ButtonRenderer) => [ 
  {
    headerName: '',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerClass: 'checkbox',
    pinned: 'left',
    width: 80,
    field: 'checkboxBtn',
    resizable: false,
    lockPosition: 'left',
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    suppressMenu: true,
    editable: false,
    cellRenderer: ButtonRenderer,
  },

  {
    field: "master",
    cellRenderer: "agGroupCellRenderer",
    editable: false,
    filter: false,
    width: 48,
  },
  {
    field: 'id',
    headerName: 'Müşavir ID',
    width: 120,
    hide: true,
  },
  {
    field: 'createdAt',
    headerName: 'Kayıt Tarihi',
    width: 130,
    valueGetter: (params) => {
      return moment.utc(params.data.createdAt).format('DD/MM/YYYY');
    },
    filterable: true, 
  },
  {
    field: 'isActive',
    headerName: 'Durum',
    filter: 'agSetColumnFilter',
    cellDataType: 'text',
    filterParams: {
      valueFormatter: function (params) {
        if (params.value) {
          return 'Aktif';
        } else {
          return 'Pasif';
        }
      },
    },
    cellStyle: {display:"flex", alignItems: "center" },
    cellRenderer: StatusRendererisActive,
    cellRendererParams: (params) => ({
      isActive: params.value // direction verisini gönder
    }),
    flex: 1,
    editable: false,
  },
  {
    field: 'name',
    headerName: 'Müşavir Unvan',
    filter: 'agTextColumnFilter',
    cellDataType: 'text',
    flex: 2,
    editable: false,
  },
  {
    field: 'vkn',
    headerName: 'Müşavir VKN',
    filter: 'agTextColumnFilter',
    cellDataType: 'text',
    width: 150,
    editable: false,
  },

  {
    field: '_count.Companies',
    headerName: 'Mükellef Adedi',
    filter: 'agNumberColumnFilter',
    cellDataType: 'number',
    width: 120,
  },
  {
    field: 'email',
    headerName: 'E-posta Adresi',
    filter: 'agTextColumnFilter',
    flex: 1,
  },
  {
    field: 'customerCode',
    headerName: 'Luca Kullanıcı Kodu',
    filter: 'agTextColumnFilter',
    width: 130,
  },
  {
    field: 'username',
    headerName: 'Luca Kullanıcı Adı',
    filter: 'agTextColumnFilter',
    flex: 1,
  },
  {
    field: 'dvdPassword',
    headerName: 'Luca Şifresi',
    filter: false,
    width: 150,
  },
  {
    field: 'dvdPassword',
    headerName: 'DVD Şifresi',
    filter: false,
    width: 150,
  },
  {
    field: 'preferences.fetchAutomaticallyInvoices',
    headerName: 'Otomatik Fatura Çekme',
    filter: 'agSetColumnFilter',
    width: 150,
    filterParams: {
      valueFormatter: function (params) {
        if (params.value) {
          return 'Açık';
        } else {
          return 'Kapalı';
        }
      },
    },
    cellStyle: {display:"flex", alignItems: "center" },
    cellRenderer: StatusRendererAutoInvoice,
    cellRendererParams: (params) => ({
      isAutoInvoiceFetchingEnabled: params.value // direction verisini gönder
    }),
    flex: 2,
    editable: false,
  },
];

//////////////////DETAILS AYARLARI/////////////////

const createDetailsColumnDefs = [
  {
    headerName: '',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerClass: 'checkbox',
    pinned: 'left',
    width: 48,
    field: 'checkboxBtn',
    resizable: false,
    lockPosition: 'left',
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    suppressMenu: true,
    editable: false,
  },
  {
    field: 'name',
    headerName: 'Mükellef Adı',
    filter: 'agTextColumnFilter',
    width: 400,
  },
  {
    field: 'year',
    headerName: 'Dönem',
    width: 100,
    filter: 'agSetColumnFilter',
  },
  {
    field: 'class',
    headerName: 'Sınıf',
    width: 100,
    filter: 'agSetColumnFilter',
    filterParams: {
      valueFormatter: (params) => {
        return params.value === 1 ? 'Bilanço' : params.value === 2 ? 'İşletme' : undefined;
      },
    },

    cellRenderer: (params) => {
      return params.value === 1 ? 'Bilanço' : params.value === 2 ? 'İşletme' : '';
    },
  },

  {
    field: 'companyId',
    headerName: 'Luca Şirket ID',
    width: 400,
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'periodId',
    headerName: 'Luca Period Id',
    width: 400,
    filter: 'agNumberColumnFilter',
  },
];

export const detailsGridOptions = {
  columnDefs: createDetailsColumnDefs,
  defaultColDef: {
    flex: 1,
    resizable: true,
    width: 120,
    floatingFilter: true,
    filterParams:createTurkishFilterParams(),
    filter:false
  },
  sideBar: sideBar.detailsSideBar,
  localeText: localeTextTr,
  rowDragManaged: true,
  animateRows: true,
  rowSelection: "multiple",
  singleClickEdit: true,
  rowMultiSelectWithClick: true,
};
