import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/tr";
import { TextField } from "@mui/material";
import { updateBankExtracts } from "../../api/company/bankExtracts/updateBankExtract";
const MySwal = withReactContent(Swal);
// Yükleniyor Swal bileşeni
export const ChangeDateSelectedExtractsSwal = ({
  open,
  handleClose,
  companyId,
  periodId,
  selectedExtractIds,
  setUpdateRow,
}) => {
  const selectedDateRef = useRef(moment());

  useEffect(() => {
    if (open) {
      if (selectedExtractIds.length === 0) {
        Swal.fire({
          title: "Uyarı",
          text: "Lütfen en az 1 ekstre seçin.",
          icon: "error",
          confirmButtonText: "Tamam",
        });
        handleClose();
        return;
      }
      MySwal.fire({
        title: "Tarih Seçin",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Devam",
        cancelButtonText: "İptal",
        focusConfirm: false,
        html: (
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="tr">
            <DatePicker
              value={selectedDateRef.current}
              onChange={(newValue) => {
                selectedDateRef.current = newValue;
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        ),
        preConfirm: () => {
          return selectedDateRef.current;
        },
      }).then((result) => {
        // Kullanıcı tarih seçimi yapıp 'Devam' düğmesine tıkladığında
        if (result.isConfirmed) {
          const updateDate = result.value;
          Swal.fire({
            html: `<b>${
              selectedExtractIds.length
            }</b> adet ekstre tarihi <b>${moment(updateDate).format(
              "DD/MM/YYYY"
            )}</b> olarak güncellenecek.<br/> Emin misiniz?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Evet, güncelle!",
            cancelButtonText: "Hayır",
            focusCancel: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: "Ekstreler Güncelleniyor...",
                text: "Lütfen bekleyiniz",
                icon: "info",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                customClass: {
                  popup: "swal2-content-centered",
                },
                willOpen: () => {
                  Swal.showLoading();
                },
                didOpen: () => {
                  const swalPopup = document.querySelector(".swal2-container");
                  if (swalPopup) {
                    swalPopup.style.zIndex = "9999";
                  }
                },
              });
              const date = new Date(updateDate);
              date.setUTCHours(0, 0, 0, 0);
              const isoString = date.toISOString();
              let extractData= {
                transactionDate: isoString,
              }
              updateBankExtracts(
                companyId,
                periodId,
                selectedExtractIds,
                extractData
              )
                .then((resp) => {
                  Swal.close();
                  setUpdateRow(true);
                  Swal.fire({
                    title: "Ekstreler güncellendi!",
                    icon: "success",
                    confirmButtonText: "Tamam",
                  });
                })
                .catch((err) => {
                  Swal.close();
                  Swal.fire({
                    html: `Tarih güncellenirken bir hata oluştu !<br>Lütfen tekrar deneyiniz.<br>`,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                });
            }
          });
        }
      });

      handleClose();
    }
  }, [open, handleClose, selectedExtractIds]);

  return null;
};
