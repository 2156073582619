//TODO  ANA GRİDİN column ayarları ulunur

import { useState } from "react";
import { FaXmark } from "react-icons/fa6";

import { TbCheck } from "react-icons/tb";

const StatusRenderer = (props) => {
  const { value, data } = props;

  if (value === true || value === "true") {
    return (
      <span
        title="Çekilsin"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          width: "80px",
          height: "60%",
          backgroundColor: "#28c76f",
          borderRadius: 5,
          color: "white",
          fontSize: "12px",
        }}
      >
        <TbCheck
          style={{
            color: "#ffffff",
          }}
          size={15}
        ></TbCheck>
        Çekilsin
      </span>
    );
  } else {
    return (
      <span
        title="Çekilmesin"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          height: "60%",
          width: "80px",
          backgroundColor: "#ea5455",
          borderRadius: 5,
          color: "white",
          fontSize: "12px",
        }}
      >
        <FaXmark
          style={{
            color: "#ffffff",
          }}
          size={15}
        ></FaXmark>
        Çekilmesin
      </span>
    );
  }
};


export const createColumnDefs = [
  {
    headerName: "",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerClass: "checkbox",
    pinned: "left",
    width: 48,
    field: "checkboxBtn",
    resizable: false,
    lockPosition: "left",
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    suppressMenu: true,
    editable: false,
    filter:false
  },
  {
    field: "id",
    hide: true,
    suppressColumnsToolPanel: true,
  },
  {
    field: "integrator",
    headerName: "Entegratör",
    width: 120,
    filter: false,
    editable:true,
    cellRenderer: (params) => {
      if (!params.value) {
        return ""; // veya boş string veya varsayılan bir değer döndürebilirsiniz
      }
      const integrator = params.value.toLowerCase();
      switch (integrator) {
        case "parasut":
          return "Paraşüt";
        case "uyumsoft":
          return "Uyumsoft";
        case "elogo":
          return "E-Logo";
        case "mimsoft":
          return "Mimsoft";
        case "edm":
          return "EDM";
        case "veriban":
          return "Veriban";
        case "sovos":
          return "Sovos";
        case "nesbilgi":
          return "Nesbilgi";
        case "birfatura":
          return "Birfatura";
        case "turmob":
          return "Luca Türmob";
        case "kolaysoft":
          return "KolaySoft - Eczacı Kart";
        case "izibiz":
          return "İZİBİZ";
        case "trendyolefaturam":
          return "Trendyol E-Faturam";
        case "odeal":
          return "Ödeal";
        case "digitalplanet":
          return "Digital Planet";
        default:
          return params.value.toUpperCase();
      }
    },
    cellEditor: "agRichSelectCellEditor",
    cellEditorParams: {
      values: [
        "mimsoft",
        "parasut",
        "uyumsoft",
        "elogo",
        "edm",
        "veriban",
        "sovos",
        "nesbilgi",
        "birfatura",
        "turmob",
        "kolaysoft",
        "izibiz",
        "trendyolefaturam",
        "odeal",
        "digitalplanet",
      ],
      cellHeight: 30,
      popupHeight: 200,
      valueListMaxHeight: 200,
      valueListMaxWidth: 130,
      suppressScrollWhileEditing: false,
      cellRenderer: (params) => {
        if (!params.value) return ""; // Boş değerler için kontrol
        const integrator = params.value.toLowerCase();
        switch (integrator) {
          case "parasut":
            return "Paraşüt";
          case "uyumsoft":
            return "Uyumsoft";
          case "elogo":
            return "E-Logo";
          case "mimsoft":
            return "Mimsoft";
          case "edm":
            return "EDM";
          case "veriban":
            return "Veriban";
          case "sovos":
            return "Sovos";
          case "nesbilgi":
            return "Nesbilgi";
          case "birfatura":
            return "Birfatura";
          case "turmob":
            return "Luca Türmob";
          case "kolaysoft":
            return "KolaySoft - Eczacı Kart";
          case "izibiz":
            return "İZİBİZ";
          case "trendyolefaturam":
            return "Trendyol E-Faturam";
          case "odeal":
            return "Ödeal";
          case "digitalplanet":
            return "Digital Planet";
          default:
            return params.value.toUpperCase();
        }
      },
    },
  }
  ,
  {
    field: "username",
    headerName: "Kullanıcı Adı",
    width: 200,
  },
  {
    field: "password",
    headerName: "Şifre",
    cellRenderer: (params) => {
      return params.value ? "******" : "";
    },
    width: 100,
  },
  {
    field: "corporateCode",
    headerName: "Firma Kısa Kod",
    width: 150,
  },
  {
    field: "isWillFetchOutbox",
    headerName: "Giden Belge",
    cellRenderer: StatusRenderer,
    cellEditor: "agRichSelectCellEditor",
    cellStyle: {
      display: "flex",
      alignItems: "center",
    },
    cellEditorParams: {
      values: [true, false],
      cellHeight: 30,
      cellRenderer: StatusRenderer
    },
  },
  {
    field: "isWillFetchInbox",
    headerName: "Gelen Belge",
    cellRenderer: StatusRenderer,
    cellStyle: {
      display: "flex",
      alignItems: "center",
    },
    cellEditor: "agRichSelectCellEditor",
    cellEditorParams: {
      values: [true, false],
      cellHeight: 30,
      cellRenderer: StatusRenderer
    },
  },
];
