//TODO  BU SAYFADA MASTER GRİD(SUBDETAILS) AYARLARI VE COLDEFS AYARLARI BULUNUR.
//TODO  ANA GRİDİN column ayarları ulunur

import moment from "moment";
import sideBar from "../../components/GridSideBar/gridSideBar.js";
import localeTextTr from "../../locale.tr.js";

import { FaCircleCheck } from "react-icons/fa6";
import { FaCircleXmark } from "react-icons/fa6";
import { FaRegCircle } from "react-icons/fa";
import { FaHourglass } from "react-icons/fa6";
import { createTurkishFilterParams } from "../../utils/agGridTextFilterTr/agGridFilterUtils.js";
const StatusRenderer = (props) => {
  const { value, data } = props;

  if (value === 100) {
    return null;
  } else if (value === 101) {
    return (
      <span
        title="Cari Oluşturulacak"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <FaRegCircle
          style={{
            color: "#ffffff",
            backgroundColor: "#ffa551",
            padding: 5,
            borderRadius: "50%",
          }}
          size={14}
        ></FaRegCircle>
      </span>
    );
  } else if (value === 102) {
    return (
      <span
        title="Dengesiz"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <FaCircleXmark style={{ color: "#ea5455" }} size={24} />
      </span>
    );
  } else if (value === 103 && data.class !== 2) {
    return (
      <span
        title="Parçalanmamış"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <FaHourglass
          style={{
            color: "white",
            backgroundColor: "#333333",
            padding: 5,
            borderRadius: "50%",
          }}
          size={14}
        />
      </span>
    );
  } else if (value === 104 || data.class === 2) {
    return (
      <span
        title="Aktarılabilir"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <FaCircleCheck style={{ color: "#28c76f" }} size={24} />
      </span>
    );
  } else {
    return value;
  }
};

export const createColumnDefs = (ButtonRenderer, companyClass) => [ 
  {
    headerCheckboxSelection: true,
    checkboxSelection: true,
    maxWidth: 80,
    minWidth: 50,
    pinned: "left",
    filter: false,
    headerName: "",
    headerCheckboxSelectionFilteredOnly: true,
    headerClass: "checkbox",
    field: "checkboxBtn",
    resizable: false,
    lockPosition: "left",
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    suppressMenu: true,
    editable: false,
    cellRenderer: ButtonRenderer,
  },
  {
    field: "master",
    headerName: "",
    cellRenderer: "agGroupCellRenderer",
    editable: false,
    filter: false,
    suppressMenu: true,
    width: 48,
    hide: companyClass === 2,
  },
  {
    field: "status",
    headerName: "Aktarım Durumu",
    filter: "agSetColumnFilter",
    cellStyle: { textAlign: "center" },
    width: 65,
    filterParams: {
      valueFormatter: function (params) {
        if (params.value === 100 || params.class === 2) {
          return "Oluşturuldu";
        } else if (params.value === 101) {
          return "Cari Oluşturulacak";
        } else if (params.value === 102) {
          return "Dengesiz";
        } else if (params.value === 103) {
          return "Parçalanmamış";
        } else if (params.value === 104) {
          return "Aktarılabilir";
        } else {
          return params.value;
        }
      },
    },
    editable: false,
    cellRenderer: StatusRenderer,
  },
  {
    field: "uuid",
    headerName: "UUID",
    filter: "agTextColumnFilter",
    hide: true,
    width: 100,
  },
  {
    field: "number",
    headerName: "Fatura No",
    filter: "agTextColumnFilter",
    suppressMenu: false,
    width: 170,
  },
  {
    field: "direction",
    headerName: "Yön",
    width: 80,
    filter: "agSetColumnFilter", // Seçenek filtresi olarak ayarla
    
    valueGetter: (params) => {
      if (params.data.direction === 1) {
        return "Giden";
      } else if (params.data.direction === 2) {
        return "Gelen";
      }
      return params.data.direction;
    },
    valueFormatter: (params) => {
      if (params.value === 1) {
        return "Giden";
      } else if (params.value === 2) {
        return "Gelen";
      }
      return params.value;
    },
  },
  {
    field: "profile_id",
    headerName: "Fatura Profili",
    filter: "agSetColumnFilter", // Seçenek filtresi olarak ayarla
    width: 150,
    
  },
  {
    field: "type_code",
    headerName: "Tip",
    filter: "agSetColumnFilter",
    width: 100,
  },
  {
    field: "issue_datetime",
    headerName: "Tarih",
    width: 120,
    filter: false,
    valueFormatter: (params) => {
      moment.locale('tr'); 
      return moment.utc(params.value).format("DD/MM/YYYY");
    },
  },
  {
    field: "sender_tax",
    headerName: "Gönderici VKN/TCKN",
    filter: "agTextColumnFilter",
    width: 130,
  },
  {
    field: "sender_name",
    headerName: "Gönderici Ünvan",
    filter: "agTextColumnFilter",
    width: 200,
  },
  {
    field: "receiver_tax",
    headerName: "Alıcı VKN/TCKN",
    filter: "agTextColumnFilter",
    width: 130,
  },
  {
    field: "receiver_name",
    headerName: "Alıcı Ünvan",
    filter: "agTextColumnFilter",
    width: 160,
  },
  {
    field: "luca_current_code",
    headerName: "Luca Hesap Kodu",
    filter: "agTextColumnFilter",
    width: 130,
    hide: companyClass === 2,
    cellStyle: (params) =>
      !!params.data.luca_current_code
        ? { backgroundColor: "transparent" }
        : params.data.status !== 103 && companyClass !== 2
        ? { backgroundColor: "#ea5455" }
        : { backgroundColor: "transparent" },
  },
  {
    field: "luca_current_name",
    headerName: "Luca Hesap Adı",
    filter: "agTextColumnFilter",
    width: 200,
    hide: companyClass === 2,
    cellStyle: (params) =>
      !!params.data.luca_current_name
        ? { backgroundColor: "transparent" }
        : params.data.status !== 103 && companyClass !== 2
        ? { backgroundColor: "#ea5455" }
        : { backgroundColor: "transparent" },
  },
  {
    field: "payable_amount",
    headerName: "Tutar",
    filter: "agNumberColumnFilter",
    width: 150,
    valueFormatter: (params) => {
      return new Intl.NumberFormat("tr-TR", {
        style: "currency",
        currency: params.data.currency_code,
      }).format(params.value);
    },
  },
  {
    field: "diff",
    headerName: "Fark",
    filter: "agNumberColumnFilter",
    width: 150,

    valueFormatter: (params) => {
      return new Intl.NumberFormat("tr-TR", {
        style: "currency",
        currency: params.data.currency_code,
      }).format(params.value);
    },
  },
  {
    field: "source",
    headerName: "Kaynak",
    filter: "agSetColumnFilter",
    width: 180,
    hide: true,
  },
];

//////////////////DETAILS AYARLARI/////////////////

const createDetailsColumnDefs = [
  {
    headerName: "",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerClass: "checkbox",
    pinned: "left",
    width: 48,
    field: "checkboxBtn",
    resizable: false,
    lockPosition: "left",
    suppressAutoSize: true,
    suppressColumnsToolPanel: true,
    suppressMenu: true,
    editable: false,
  },
  {
    field: "hesap_kodu",
    headerName: "Hesap Kodu",
    filter: "agTextColumnFilter",
    width: 400,
  },
  {
    field: "borc",
    headerName: "Borç",
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => {
      return new Intl.NumberFormat("tr-TR", {
        style: "currency",
        currency: "TRY",
      }).format(params.value);
    },
  },
  {
    field: "alacak",
    headerName: "Alacak",
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => {
      return new Intl.NumberFormat("tr-TR", {
        style: "currency",
        currency: "TRY",
      }).format(params.value);
    },
  },
];

export const detailsGridOptions = {
  columnDefs: createDetailsColumnDefs,
  defaultColDef: {
    flex: 1,
    resizable: true,
    width: 120,
    floatingFilter: true,
    filter:false,
    filterParams:createTurkishFilterParams(),
  },
  sideBar: sideBar.detailsSideBar,
  localeText: localeTextTr,
  rowDragManaged: true,
  animateRows: true,
  rowSelection: "multiple",
  singleClickEdit: true,
  rowMultiSelectWithClick: true,
};
