/** @format */

import axiosInstance from "../../../../utils/maintenanceController/axios";

export const selectedInvoicesDetails = async (
    companyId,
    periodId,
    invoiceIds
) => {
    return axiosInstance.post(
        `/company/${companyId}/${periodId}/selected-invoices-details`,
        { invoiceIds }
    );
};

export const fetchInvoice = async (companyId, periodId, id, source) => {
    const responseType =
        source === "birfatura" || source === "turkcell" ? "PDF" : "HTML";

    return axiosInstance.post(
        `/company/${companyId}/${periodId}/fetch-invoice`,
        { id, responseType }
    );
};
