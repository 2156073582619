/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

//todo MODAL AÇILMASI GEREKTİREN İŞLEMLER SONU MODAL İLE BİTEN DOSYALARDA(fatura seçilerek yapılan işlemlerde),
//todo DİREKT İSTEKLE HALLEDİLEBİLİR İŞLEMLER 'ProcessesAndProcessMenu' DOSYASINDADIR.
import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// Grids---------
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import gridSideBar from "../../../components/GridSideBar/gridSideBar.js";
import { createTurkishFilterParams } from "../../../utils/agGridTextFilterTr/agGridFilterUtils.js";

import { createColumnDefs } from "./gridOptionsAndcolumnDefs.js";
import localeTextTr from "../../../locale.tr.js";

//Components
import Sidebar from "../../../components/sideBar/sideBar.js";
import Navbar from "../../../components/navbar/navbar.js";
import localStorage from "local-storage";
import { getJsonItem } from "../../../utils/localStorageProcess/index.js";
import { setJsonItem } from "../../../utils/localStorageProcess/index.js";
//Icons
import {
  Grid,
  Typography,
  Button,
  Box,
  TextField,
  Autocomplete,
  Divider,
} from "@mui/material";
//Icons

//Apis
import { getCompanyDetails } from "../../../api/advisor/getCompanyDetails/index.js";
import { FaRegSave } from "react-icons/fa";

import { getLucaAccounts } from "../../../api/company/invoices/getLucaAccounts/index.js";
import { showLoadingSwal } from "../../../components/loadingSwal/index.js";
import { setTitle } from "../../../utils/setTitle/index.js";
import { CreateLucaAccountSwal } from "../../../components/createLucaAccountSwal/index.js";
import { getIncomeExpenseFichesRuleSettings } from "../../../api/company/IncomeExpenseFiches/getIncomeExpenseFichesRuleSettings/index.js";
import { updateFicheReportsSettings } from "../../../api/company/IncomeExpenseFiches/updateFicheReportsSettings/index.js";
//Apis

export function IncomeExpenseFicheSettings() {
  const [isOpen, setIsOpen] = useState(false);

  //modals açılma durumlar
  const [modalId, setModalId] = useState("1");
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  //--------

  const [companyDetails, setCompanyDetails] = useState();
  const companyDetailsRef = useRef(companyDetails);

  const [loading, setLoading] = useState(true);
  const [firstLoading, setFirstLoading] = useState(true); //SAYFA İLK AÇTIĞINDA getListInvoices ve companies'e İSTEK ATMAMASI İÇİN FLAG

  const location = useLocation();
  const navigate = useNavigate();

  const lucaAccountCodesRef = useRef();
  const [rowData, setRowData] = useState([]);
  const ruleTableDataGridRef = useRef(null);

  useEffect(() => {
    const sideBarOpen = localStorage.get("sidebar");
    if (sideBarOpen === "false") {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
    let locationSplit = location.pathname.split("/");
    let companyDetailsControl = getJsonItem(
      `#${locationSplit[2]}/${locationSplit[3]}`
    );
    setCompanyDetails(companyDetailsControl);

    companyDetailsRef.current = companyDetailsControl;

    if (!companyDetailsControl?.companyId) {
      getCompanyDetails(locationSplit[2], locationSplit[3])
        .then(async (resp) => {
          if (resp.data.companyId) {
            await setJsonItem(
              `#${locationSplit[2]}/${locationSplit[3]}`,
              resp.data
            );
            window.location.reload();
          } else {
            navigate("/dashboard", { replace: true });
          }
        })
        .catch((err) => {
          navigate("/dashboard", { replace: true });
        });
    } else {
      setTitle(companyDetailsControl?.name, locationSplit[4]);
      Promise.all([
        getIncomeExpenseFichesRuleSettings(locationSplit[2], locationSplit[3]),
        getLucaAccounts(locationSplit[2], locationSplit[3]),
      ])
        .then(([rulesResp, lucaAccountResp]) => {
          const updateData = lucaAccountResp.data.filter(
            //aynı id'li data geldiğinde problem autocomplete problem çıkartıyor. aynı id'li olanları 1 tane bırakıyoruz
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id)
          );
          updateData.push({
            hesap_adi: "Özel Kural Ekle",
            hesap_kodu: "special",
          });
          lucaAccountCodesRef.current = updateData;

          let tableRules = rulesResp?.data?.settings?.ficheReportsRules.map((rule) => {
            let ruleCondition=rule?.condition.replace(/\s/g, '')
            return {
              id: rule.id,
              note: rule.note,
              return:[{
                borc:rule.return[0].borc||"",
                alacak:rule.return[0].alacak||"",
                hesap_kodu: rule.return[0].hesap_kodu||""
              }],
              hesap_kodu: rule.return[0].hesap_kodu?rule.return[0].hesap_kodu.substring(
                1,
                rule.return[0].hesap_kodu.length - 1
              ):"",
              isDefaultRule: rule.isDefaultRule,
              condition: rule.condition,
              direction:ruleCondition?.includes("ficheReport.direction==")?ruleCondition.split('ficheReport.direction=="')[1].split('"')[0]:"",
            };
          });
          setRowData(tableRules.sort((a, b) => b.id - a.id));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, []);

  const getRowId = useCallback((params) => {
    return params.data.id;
  }, []);

  const ItemSelectorCellEditor = (props) => {
    let groupedOptions = [
      {
        group: "Luca Hesapları",
        options: lucaAccountCodesRef.current,
      },
    ];

    const options = groupedOptions
      .flatMap((group) => group.options)
      .filter((item) => item);

    const updateData = useCallback(
      async (newDataAccountCode) => {
        let id = await props.data?.id;
        if (id) {
          const rowNode = props.api.getRowNode(id);
          if (rowNode) {
            await rowNode.setDataValue("hesap_kodu", newDataAccountCode || "");
          }
        }
      },
      [props.api, props.data]
    );

    const handleChange = (event, newValue) => {
      if (newValue?.hesap_kodu === "add-new") {
        CreateLucaAccountSwal(
          companyDetails?.companyId,
          companyDetails?.periodId
        );
        return;
      }
      updateData(newValue?.hesap_kodu);
      props.stopEditing();
    };
    const [open, setOpen] = useState(true);

    return (
      <Autocomplete
        style={{ height: "20px" }}
        value={
          options.find((option) => option?.hesap_kodu === props?.value) || null
        }
        onChange={handleChange}
        options={options}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        noOptionsText={"Seçenek bulunamadı"}
        groupBy={(option) => {
          const group = groupedOptions.find((g) => g.options.includes(option));
          return group ? group.group : "";
        }}
        autoHighlight
        selectOnFocus
        blurOnSelect
        openOnFocus
        filterOptions={(options, params) => {
          const filtered = options.filter((option) => {
            let label = `${option.hesap_kodu} ${option.hesap_adi}`;
            return label
              .toLocaleLowerCase("tr")
              .includes(params?.inputValue?.toLocaleLowerCase("tr"));
          });
          // Girilen metin, mevcut seçenekler arasında yoksa özel bir seçenek ekle
          if (filtered?.length === 0) {
            filtered.push({
              hesap_adi: "Yeni Hesap Kodu Eklemek İçin Tıklayın",
              hesap_kodu: "add-new", // Özel seçenek için belirleyici bir value
            });
          }

          return filtered;
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            // Eğer liste açıksa ve bir seçenek vurgulanmışsa, onu seç
            const highlightedOption = event.target.querySelector(
              'li[data-focus="true"]'
            );
            if (highlightedOption) {
              highlightedOption.click();
            }
          }
        }}
        getOptionLabel={(option) => {
          if (option?.hesap_kodu === "add-new") {
            return `${option.adi || ""}`;
          }
          return option
            ? `${option.hesap_kodu || ""} - ${
                option.hesap_adi || ""
              } - Bakiye: ${option.bakiye || ""}`
            : "";
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            variant="outlined"
            autoFocus={true}
          />
        )}
        renderGroup={(params) => (
          <Box
            key={params.key}
            sx={{
              height: "200px",
              display: "flex",
            }}
          >
            <li key={params.key} sx={{ display: "inline" }}>
              <Typography
                variant="body1"
                style={{
                  fontWeight: "bold",
                  backgroundColor: "#f0f0f0",
                  padding: "5px",
                }}
              >
                {params.group}
              </Typography>
              <ul
                style={{
                  padding: 0,
                  margin: 0,
                  listStyle: "none",
                  whiteSpace: "nowrap",
                  flexWrap: "nowrap",
                }}
              >
                {params.children}
              </ul>
            </li>
          </Box>
        )}
        renderOption={(props, option) => (
          <Box
            component="li"
            {...props}
            key={option?.hesap_kodu || ""}
            style={{ paddingLeft: "7px", maxWidth: "100%" }}
          >
            <Typography>
              {option.hesap_kodu === "add-new" ? (
                <>{option.hesap_adi}</>
              ) : (
                <>
                  <strong>{option.hesap_kodu}</strong> - {option.hesap_adi} -
                  Bakiye: {option.bakiye}
                </>
              )}
            </Typography>
          </Box>
        )}
      />
    );
  };

  const handleSaveButtonClick = async () => {
    showLoadingSwal("Ayarlar kaydediliyor...");
    let updatedFicheReportsSettings = [];
    if (companyDetailsRef?.current?.class === 1) {
      ruleTableDataGridRef.current.api.forEachNode((node) =>{
        updatedFicheReportsSettings.push({
          id: node.data.id,
          note: node.data.note,
          return:[{
            borc:node.data.return[0].borc?node.data.return[0].borc:"",
            alacak:node.data.return[0].alacak?node.data.return[0].alacak:"",
            hesap_kodu: node.data?.hesap_kodu?`"${node.data?.hesap_kodu}"`:""
          }],
          isDefaultRule: node.data.isDefaultRule,
          condition: node.data.condition,
        })}
      );
    
    }


    updateFicheReportsSettings(
      companyDetails?.companyId,
      companyDetails?.periodId,
      updatedFicheReportsSettings
    )
      .then((resp) => {
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Başarılı!",
          text:resp.data.message?resp.data.message:"",
          confirmButtonText: "Tamam",
        });
        getCompanyDetails(
          companyDetails?.companyId,
          companyDetails?.periodId
        ).then(async (resp) => {
          await setJsonItem(
            `#${companyDetails?.companyId}/${companyDetails?.periodId}`,
            resp.data
          );
        });
      })
      .catch((err) => {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Ayarlar kaydedilemedi!",
          text: err.response.data.message ? err.response.data.message : "",
        });
      });
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const defaultColDef = useMemo(
    () => ({
      filter: true,
      sortable: true,
      resizable: true,
      floatingFilter: false,
      editable: false,
      enableCellChangeFlash: true,
      filterParams:createTurkishFilterParams(),
    }),
    []
  );

  const columnDefs = useMemo(
    () => createColumnDefs(ItemSelectorCellEditor),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyDetails?.class]
  );

  const getMainMenuItems = (params) => {
    const allMenuItems = params.defaultItems;
    const itemsToRemove = ["toolPanelButton"]; // 'choose columns' seçeneği

    return allMenuItems.filter((item) => !itemsToRemove.includes(item));
  };

  return (
    <Grid container>
      <Grid
        item
        sx={{
          flexBasis: isOpen ? "275px" : "95px",
          flexShrink: 0,
          transition: "flex-basis 0.3s ease",
        }}
        zIndex={1}
      >
        <Sidebar
          status={isOpen}
          toggleSidebar={toggleSidebar}
          companyClass={companyDetails?.class}
        />
      </Grid>
      <Grid
        item
        zIndex={0}
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          gap: 1,
          pr: "12px",
        }}
      >
        <Grid item xs={12}>
          <Navbar
            setLoading={setLoading}
            setFirstLoading={setFirstLoading}
            firstLoading={firstLoading}
          />{" "}
        </Grid>
        <Grid container sx={12} className="grid-area">
          <Grid
            item
            sx={{
              mb: 5,
              display: "flex",
              justifyContent: "space-between",
            }}
            xs={12}
          >
            <Grid item xs={3} md={3}>
              <Typography variant="h6" color={"#030e22b2"} fontWeight={"550"}>
                Gelir - Gider Fiş Ayarları
              </Typography>
            </Grid>
            <Grid item xs={2} md={1}>
              {" "}
              <Button
                onClick={() => {
                  handleSaveButtonClick();
                }}
                fullWidth
                sx={{ width: "100%",textTransform: "none" }}
                variant="contained"
                color="primary"
                className={loading ? "unframed-button" : "custom-button"}
                disabled={loading}
                startIcon={<FaRegSave size={15} color="white" />}
              >
                Kaydet
              </Button>
            </Grid>
          </Grid>

          <Grid container xs={12} sx={{ justifyContent: "space-between" }}>
            {companyDetailsRef?.current?.class === 1 && (
              <Grid
                item
                xs={12}
                className="ag-theme-quartz"
                style={{
                  height: "75vh",
                  width: "100%",
                  borderRadius: "10%",
                  marginBottom: "50px",
                }}
              >
                <Divider />
                <Grid
                  item
                  sx={{
                    display: companyDetails?.class === 1 ? "flex" : "none",
                    mt: 1,
                    mb: 1,
                    pl: 2,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  xs={12}
                >
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      justifyContent: "start",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={"#030e22b2"}
                      fontWeight={"450"}
                    >
                      Kurallar
                    </Typography>
                  </Grid>
                </Grid>
                <AgGridReact
                  ref={ruleTableDataGridRef}
                  getRowId={getRowId}
                  localeText={localeTextTr}
                  rowSelection="multiple"
                  loading={loading}
                  animateRows={true}
                  rowDragManaged={true}
                  rowMultiSelectWithClick={false}
                  singleClickEdit={true}
                  stopEditingWhenCellsLoseFocus={true}
                  suppressRowClickSelection={true}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  enableRangeSelection={true}
                  sideBar={gridSideBar.gridSideBar}
                  copyHeadersToClipboard={false}
                  defaultColDef={defaultColDef}
                  getMainMenuItems={getMainMenuItems}
                />{" "}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* {modalId === "#copyRuleToCompanySwal" ? (
        <Grid>
          <CopyRuleToCompanySwal
            open={modalOpen}
            handleClose={handleModalClose}
            companyId={companyDetails?.companyId}
            periodId={companyDetails?.periodId}
            companyName={companyDetails?.name}
          ></CopyRuleToCompanySwal>
        </Grid>
      ) : (
        ""
      )} */}
    </Grid>
  );
}
