/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

//todo MODAL AÇILMASI GEREKTİREN İŞLEMLER SONU MODAL İLE BİTEN DOSYALARDA(fatura seçilerek yapılan işlemlerde),
//todo DİREKT İSTEKLE HALLEDİLEBİLİR İŞLEMLER 'ProcessesAndProcessMenu' DOSYASINDADIR.

import React, {
    useState,
    useMemo,
    useEffect,
    useRef,
    useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// Grids---------
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import gridSideBar from "../../components/GridSideBar/gridSideBar.js";

import { createColumnDefs } from "./gridOptionsAndcolumnDefs.js";

import localeTextTr from "../../locale.tr.js";

//Components
import Sidebar from "../../components/sideBar/sideBar.js";
import Navbar from "../../components/navbar/navbar.js";
import DateRangePicker from "../../components/DateRangePicker/index.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import localStorage from "local-storage";
import { getJsonItem } from "../../utils/localStorageProcess/index.js";
import { setJsonItem } from "../../utils/localStorageProcess/index.js";

//Modals and Menus
import ProcessMenu from "./processesAndProcessMenu.js";

//Icons
import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import { TbFileDownload } from "react-icons/tb";
import {
    Grid,
    Alert,
    Typography,
    Button,
    IconButton,
    Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
//Icons

//Apis
import { getCompanyDetails } from "../../api/advisor/getCompanyDetails/index.js";
import { ExportInvoicestoExcelRowBasedSwal } from "./exportInvoicestoExcelRowBasedSwal.js";
import { getListReports } from "../../api/company/reports/getListReports/index.js";
import { CreateGibVATListToExcludeSwal } from "./createGibVATListToExcludeSwal.js";
import { CreateGibSalesInvoiceListSwal } from "./createGibSalesInvoiceListSwal.js";
import { CreateInvoiceControlReportSwal } from "./createInvoiceControlReportSwal.js";
import { setTitle } from "../../utils/setTitle/index.js";
import { createTurkishFilterParams } from "../../utils/agGridTextFilterTr/agGridFilterUtils.js";
import AnimatedAlerts from "../../components/animatedAlerts/index.js";

//Apis

function Reports() {
    const [isOpen, setIsOpen] = useState(false);

    //modals açılma durumlar
    const [modalId, setModalId] = useState("1");
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);
    //--------
    //grid fatura görüntüleme modalı için açılır kapanır state'si
    //grid fatura görüntüleme modalı için açılır kapanır state'si

    const [companyDetails, setCompanyDetails] = useState();

    const [loading, setLoading] = useState(true);
    const [firstLoading, setFirstLoading] = useState(true); //SAYFA İLK AÇTIĞINDA getListInvoices ve companies'e İSTEK ATMAMASI İÇİN FLAG

    //Modallarda Yapılan işlemlerde sonra tablonun güncellemesi için
    const [updateRow, setUpdateRow] = useState(false);

    useEffect(() => {
        if (updateRow === true) {
            setLoading(true);
            search(companyDetails?.companyId, companyDetails?.periodId);
            setUpdateRow(false);
        }
    }, [updateRow]);
    //Modallarda Yapılan işlemlerde sonra tablonun güncellemesi için

    const location = useLocation();
    const navigate = useNavigate();

    const [rowData, setRowData] = useState([]);
    const gridRef = useRef(null);
    const [filteredCount, setFilteredCount] = useState(0);

    const [startDate, setStartDate] = useState(
        moment().startOf("month").format("YYYY-MM-DD HH:mm:ss")
    );
    const [endDate, setEndDate] = useState(
        moment().endOf("month").format("YYYY-MM-DD HH:mm:ss")
    );

    const handlePreviousMonth = () => {
        setStartDate(moment(startDate).subtract(1, "month").startOf("month"));
        setEndDate(moment(endDate).subtract(1, "month").endOf("month"));
    };

    const handleNextMonth = () => {
        setStartDate(moment(startDate).add(1, "month").startOf("month"));
        setEndDate(moment(endDate).add(1, "month").endOf("month"));
    };

    useEffect(() => {
        const sideBarOpen = localStorage.get("sidebar");
        if (sideBarOpen === "false") {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
        let locationSplit = location.pathname.split("/");

        let companyDetailsControl = getJsonItem(
            `#${locationSplit[2]}/${locationSplit[3]}`
        );
        setCompanyDetails(companyDetailsControl);
        if (!companyDetailsControl?.companyId) {
            getCompanyDetails(locationSplit[2], locationSplit[3])
                .then(async (resp) => {
                    if (resp.data.companyId) {
                        await setJsonItem(
                            `#${locationSplit[2]}/${locationSplit[3]}`,
                            resp.data
                        );
                        window.location.reload();
                    } else {
                        navigate("/dashboard", { replace: true });
                    }
                })
                .catch((err) => {
                    navigate("/dashboard", { replace: true });
                });
        } else {
            setTitle(companyDetailsControl?.name, locationSplit[4]);
            search(locationSplit[2], locationSplit[3]);
        }
    }, []);

    const search = (companyId, periodId) => {
        getListReports(companyId, periodId, startDate, endDate)
            .then((resp) => {
                setRowData(resp.data.reports);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleSearch = () => {
        let locationSplit = location.pathname.split("/");
        const details = getJsonItem(`#${locationSplit[2]}/${locationSplit[3]}`);
        setLoading(true);
        search(details?.companyId, details?.periodId);
    };
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const onFilterChanged = useCallback((event) => {
        const filteredRows = event.api.getModel().getRowCount(); // Filtrelenmiş veri sayısını alıyoruz
        setFilteredCount(filteredRows);
    }, []);
    const onRowDataUpdated = useCallback((event) => {
        const filteredRows = event.api.getDisplayedRowCount(); // Filtrelenmiş veri sayısını alıyoruz
        setFilteredCount(filteredRows);
    }, []);

    const defaultColDef = useMemo(
        () => ({
            filter: true,
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filterParams: createTurkishFilterParams(),
        }),
        []
    );

    const ButtonRenderer = (props) => {
        const { data } = props;
        const handleClick = () => {
            let mimeType = "";
            const base64PDF = data.file;
            if (data.fileType === "pdf") {
                mimeType = "application/pdf";
            } else if (data.fileType === "xlsx") {
                mimeType =
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            } else {
                console.error("Unsupported file type:", data.fileType);
                return;
            }
            const byteCharacters = atob(base64PDF);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: mimeType });

            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.download = data.fileName;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Revoke the object URL after the download
            URL.revokeObjectURL(url);
        };

        return (
            <div>
                <Button
                    variant="contained"
                    startIcon={<TbFileDownload size={15} />}
                    onClick={handleClick}
                    sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        backgroundColor: "#fff1e3",
                        borderRadius: "5px",
                        padding: "0px 12px",
                        minWidth: "100px",
                        color: "#ffa44c",
                        textTransform: "none",
                        "&:hover": {
                            backgroundColor: "#f7e9dc",
                        },
                    }}
                >
                    Raporu İndir
                </Button>
            </div>
        );
    };

    const columnDefs = useMemo(
        () => createColumnDefs(ButtonRenderer, companyDetails?.class),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [companyDetails?.class]
    );

    const getMainMenuItems = (params) => {
        const allMenuItems = params.defaultItems;
        const itemsToRemove = ["toolPanelButton"]; // 'choose columns' seçeneği

        return allMenuItems.filter((item) => !itemsToRemove.includes(item));
    };

    return (
        <Grid container>
            <Grid
                item
                sx={{
                    flexBasis: isOpen ? "275px" : "95px",
                    flexShrink: 0,
                    transition: "flex-basis 0.3s ease",
                }}
                zIndex={1}
            >
                <Sidebar
                    status={isOpen}
                    toggleSidebar={toggleSidebar}
                    companyClass={companyDetails?.class}
                />
            </Grid>
            <Grid
                item
                zIndex={0}
                sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                    gap: 1,
                    pr: "12px",
                }}
            >
                <Grid item xs={12}>
                    <Navbar
                        setLoading={setLoading}
                        setFirstLoading={setFirstLoading}
                        firstLoading={firstLoading}
                    />{" "}
                </Grid>
                <Grid
                    item
                    md={12}
                    sx={{ display: "flex", flexDirection: "column" }}
                >
                    <Grid item md={12} sx={{ mt: 1 }}>
                        <AnimatedAlerts
                            infoText={`  Bu sayfada, seçtiğiniz tarih aralığındaki
              <b style={{ letterSpacing: "0.05em" }}>mükellef özelinde</b> oluşturulmuş
              raporları  görüntüleyebilir ve rapor oluşturabilirsiniz.`}
                        />
                    </Grid>
                    <Grid
                        className="grid-area"
                        sx={{
                            padding: 1,
                            mb: 1,
                            alignItems: "center",
                        }}
                    >
                        <Grid
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Grid
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                    adapterLocale="tr"
                                >
                                    <Grid
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                            pl: 1,
                                            pt: 0.5,
                                        }}
                                    >
                                        <IconButton
                                            onClick={handlePreviousMonth}
                                            className="date-button"
                                        >
                                            <KeyboardDoubleArrowLeftOutlinedIcon fontSize="small"></KeyboardDoubleArrowLeftOutlinedIcon>
                                        </IconButton>
                                        <DateRangePicker
                                            setStartDate={setStartDate}
                                            startDate={startDate}
                                            endDate={endDate}
                                            setEndDate={setEndDate}
                                            isLimitedRangeForMonth={true}
                                        />
                                        <IconButton
                                            onClick={handleNextMonth}
                                            className="date-button"
                                        >
                                            <KeyboardDoubleArrowRightOutlinedIcon fontSize="small"></KeyboardDoubleArrowRightOutlinedIcon>
                                        </IconButton>
                                        <Button
                                            variant="contained"
                                            className="custom-button"
                                            startIcon={<SearchIcon />}
                                            onClick={() => {
                                                handleSearch();
                                            }}
                                            sx={{
                                                width: "5rem",
                                                ml: 2,
                                            }}
                                        >
                                            Ara
                                        </Button>
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                sx={{
                                    display: "flex",
                                    gap: 2,
                                    alignItems: "center",
                                }}
                            >
                                <ProcessMenu
                                    startDate={startDate}
                                    endDate={endDate}
                                    setModalId={setModalId}
                                    handleModalOpen={handleModalOpen}
                                    setUpdateRow={setUpdateRow}
                                ></ProcessMenu>
                                {modalId ===
                                "#exportInvoicestoExcelRowBased" ? (
                                    <Grid>
                                        <ExportInvoicestoExcelRowBasedSwal
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyDetails={companyDetails}
                                            setUpdateRow={setUpdateRow}
                                        ></ExportInvoicestoExcelRowBasedSwal>
                                    </Grid>
                                ) : modalId === "#createGibVATListToExclude" ? (
                                    <Grid>
                                        <CreateGibVATListToExcludeSwal
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyDetails={companyDetails}
                                            setUpdateRow={setUpdateRow}
                                        ></CreateGibVATListToExcludeSwal>
                                    </Grid>
                                ) : modalId === "#createGibSalesInvoiceList" ? (
                                    <Grid>
                                        <CreateGibSalesInvoiceListSwal
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyDetails={companyDetails}
                                            setUpdateRow={setUpdateRow}
                                        ></CreateGibSalesInvoiceListSwal>
                                    </Grid>
                                ) : modalId ===
                                  "#createInvoiceControlReport" ? (
                                    <Grid>
                                        <CreateInvoiceControlReportSwal
                                            open={modalOpen}
                                            handleClose={handleModalClose}
                                            companyDetails={companyDetails}
                                            setUpdateRow={setUpdateRow}
                                        ></CreateInvoiceControlReportSwal>
                                    </Grid>
                                ) : (
                                    ""
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Box
                        className="grid-area"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0,
                            backgroundColor: "#ffffff",
                            p: 2,
                            mb: 2,
                        }}
                    >
                        <Grid
                            className="ag-theme-quartz"
                            style={{
                                minHeight: rowData.length > 6 ? "85vh" : "60vh",
                                width: "100%",
                                borderRadius: "10%",
                            }}
                        >
                            <AgGridReact
                                ref={gridRef}
                                onFilterChanged={onFilterChanged}
                                onRowDataUpdated={onRowDataUpdated}
                                localeText={localeTextTr}
                                rowSelection="multiple"
                                loading={loading}
                                animateRows={true}
                                rowDragManaged={true}
                                rowMultiSelectWithClick={true}
                                readOnlyEdit={true}
                                singleClickEdit={true}
                                stopEditingWhenCellsLoseFocus={true}
                                rowData={rowData}
                                columnDefs={columnDefs}
                                enableRangeSelection={true}
                                sideBar={gridSideBar.gridSideBar}
                                copyHeadersToClipboard={false}
                                defaultColDef={defaultColDef}
                                getMainMenuItems={getMainMenuItems}
                            />
                        </Grid>
                        <Typography
                            sx={{
                                backgroundColor: "white",
                                fontWeight: 400,
                                color: "#605f66",
                                ml: 1,
                                mt: 1,
                                mb: 1,
                            }}
                        >
                            Ekrandaki Rapor Sayısı: <b>{filteredCount}</b>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Reports;
