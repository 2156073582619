import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  Checkbox,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { createGibSalesInvoiceList } from "../../api/company/reports/createGibSalesInvoiceList";
// Yükleniyor Swal bileşeni
const MySwal = withReactContent(Swal);
// Yükleniyor Swal bileşeni
export const CreateGibSalesInvoiceListSwal = ({
  open,
  handleClose,
  companyDetails,
  setUpdateRow
}) => {
  useEffect(() => {
    if (open) {
      let startDate = moment().subtract(1, "month").startOf("month");
      let endDate = moment().subtract(1, "month").endOf("month");
      let includeExportInvoices = false;
      let includeExceptionalInvoices = false;
      let includeExportRegisteredInvoices = false;

      MySwal.fire({
        title: "Tarih Aralığı Seçin",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Devam",
        cancelButtonText: "İptal",
        focusConfirm: false,
        html: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="tr"
              >
                <DatePicker
                  label="Başlangıç Tarihi"
                  value={moment(startDate)}
                  onChange={(newValue) => {
                    startDate = moment(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input-field"
                      size="small"
                      {...params}
                    />
                  )}
                />
                <DatePicker
                  label="Bitiş Tarihi"
                  value={moment(endDate)}
                  onChange={(newValue) => {
                    endDate = moment(newValue);
                  }}
                  sx={{ marginLeft: 2 }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      className="input-field"
                      sx={{ color: "black" }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <Grid container sx={{mt:1}}>
              <Grid xs={12} md={12} sx={{ display: "flex" }}>
                <Checkbox
                  className="default-checked"
                  onChange={(e) => (includeExportInvoices = e.target.checked)}
                />
                <Typography>İhracat Faturalarını Dahil Et</Typography>
              </Grid>
              <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                <Checkbox
                  className="default-checked"
                  onChange={(e) =>
                    (includeExceptionalInvoices = e.target.checked)
                  }
                />
                <Typography>İstisna Faturalarını Dahil Et</Typography>
              </Grid>
              <Grid item xs={12} md={12} sx={{ display: "flex" }}>
                <Checkbox
                  className="default-checked"
                  onChange={(e) =>
                    (includeExportRegisteredInvoices = e.target.checked)
                  }
                />
                <Typography>İhraç Kayıtlı Faturaları Dahil Et</Typography>
              </Grid>
            </Grid>
          </div>
        ),
        preConfirm: () => {
          if (!startDate || !endDate) {
            MySwal.showValidationMessage("Lütfen tarih seçiniz!");
            return false; // Bu, Swal penceresini açık tutar ve form onayını engeller
          }else if ((!includeExportInvoices&&!includeExceptionalInvoices&&!includeExportRegisteredInvoices)) {
            MySwal.showValidationMessage("Lütfen en az bir adet seçim yapınız!");
          }
          return { startDate, endDate,includeExportInvoices,includeExceptionalInvoices,includeExportRegisteredInvoices};
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const { startDate, endDate,includeExportInvoices,includeExceptionalInvoices,includeExportRegisteredInvoices } = result.value;
          Swal.fire({
            title: "Rapor Oluşturuluyor...",
            text: "Lütfen bekleyiniz",
            icon: "info",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
              popup: "swal2-content-centered",
            },
            willOpen: () => {
              Swal.showLoading();
            },
          });
          let invoiceProfileAndTypes={
            includeExportInvoices,includeExceptionalInvoices,includeExportRegisteredInvoices
          }
          createGibSalesInvoiceList(
            companyDetails?.companyId,
            companyDetails?.periodId,
            startDate,
            endDate,
            invoiceProfileAndTypes
          )
            .then((resp) => {
              setUpdateRow(true)
              Swal.fire({
                title: "Başarılı!",
                text: resp.data.message,
                icon: "success",
                confirmButtonText: "Tamam",
              });
            })
            .catch((err) => {
              Swal.fire({
                title: "Bir hata oluştu!",
                text: err.response.data.message,
                icon: "error",
                confirmButtonText: "Tamam",
              });
            });
        }
      });

      handleClose();
    }
  }, [open, handleClose]);

  return null;
};
